import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import MetisMenu from 'metismenujs';
import { NavLink, Link, withRouter } from 'react-router-dom';
import logo from 'images/therapy_logo.png';

const SidebarContent = ({ location, isCondensed }) => {
  useEffect(() => {
    initMenu();
  }, [initMenu, isCondensed]);

  const activateParentDropdown = item => {
    item.classList.add('active');
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show');

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); // a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add('mm-active');
          }
        }
      }
      return false;
    }
    return false;
  };

  const initMenu = useCallback(() => {
    new MetisMenu('#side-menu'); // eslint-disable-line

    let matchingMenuItem = null;
    const ul = document.getElementById('side-menu');
    const items = ul.getElementsByTagName('a');
    for (let i = 0; i < items.length; i += 1) {
      if (location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [location.pathname]);

  return (
    <>
      <nav id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          <li className="menu-logo">
            <Link to="/" style={{ padding: 12 }}>
              <img src={logo} alt="logo" />
            </Link>
          </li>
          <li>
            <NavLink className="waves-effect" to="/my-wineclub">
              <i className="ri-mail-send-line" />
              <span className="ml-1">My Wine Club</span>
            </NavLink>
          </li>
          <li>
            <NavLink className="waves-effect" to="/wine-selection">
              <i className="ri-goblet-line" />
              <span className="ml-1">Wine Selection</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/purchase_history" className="waves-effect">
              <i className="ri-shopping-bag-line" />
              <span className="ml-1">Purchase history</span>
            </NavLink>
          </li>
        </ul>
      </nav>
    </>
  );
};

SidebarContent.propTypes = {
  isCondensed: PropTypes.bool,
  location: PropTypes.object,
};

export default withRouter(SidebarContent);
