import { createSlice } from 'redux-starter-kit';
import actionTypes from 'utils/actionTypes';

const ordersSlice = createSlice({
  name: 'orders',
  initialState: {
    isPending: false,
    isOrderRefundPending: false,
    orders: [],
    refundItems: [],
    ordersPagination: null,
    order: {},
    productsInOrder: {},
    orderSuccessfullyCreated: false,
    error: null,
  },
  reducers: {
    fetchOrders: state => {
      state.isPending = true;
    },
    fetchOrdersSuccess(state, { payload }) {
      const { results, pagination } = payload.data;
      state.orders = results;
      state.ordersPagination = pagination;
      state.isPending = false;
    },
    fetchOrdersFailure: state => {
      state.isPending = false;
    },
    fetchOrder: state => {
      state.isPending = true;
    },
    fetchOrderSuccess: (state, { payload }) => {
      state.order = payload.data;
      state.isPending = false;
    },
    fetchOrderFailure: state => {
      state.isPending = false;
    },
    updateOrder: state => {
      state.isPending = true;
    },
    updateOrderSuccess(state, { payload }) {
      state.order = payload.data;
      state.orders.map(item => {
        if (item.id !== payload.inResponseTo.id) {
          return item;
        }
        return {
          // ...payload.data,
        };
      });
      state.isPending = false;
      state.error = null;
    },
    // TODO: add success logic
    updateOrderFailure: (state, { payload }) => {
      state.isPending = false;
      state.error = payload?.response;
    },
    approveOrder: state => {
      state.isPending = true;
    },
    approveOrderSuccess(state) {
      state.isPending = false;
    },
    approveOrderFailure: state => {
      state.isPending = false;
    },
    skipOrder: state => {
      state.isPending = true;
    },
    skipOrderSuccess(state, { payload }) {
      state.isPending = false;
      const orderId = payload.inResponseTo;
      state.orders = state.orders.map(order =>
        order.id === orderId ? { ...order, status: 'SKIPPED' } : order
      );
    },
    skipOrderFailure: state => {
      state.isPending = false;
    },
    changeDueDateOrder: state => {
      state.isPending = true;
    },
    changeDueDateOrderSuccess(state) {
      state.isPending = false;
    },
    changeDueDateOrderFailure: state => {
      state.isPending = false;
    },

    createRetailOrder: state => {
      state.isPending = true;
    },
    clearOrder: state => {
      state.order = null;
    },
    createRetailOrderSuccess(state, { payload }) {
      state.order = payload.data;
      state.order.is_retail = true;
      state.isPending = false;
    },
    createRetailOrderFailure: state => {
      state.isPending = false;
    },
    orderRefund: state => {
      state.isOrderRefundPending = true;
    },
    orderRefundSuccess: (state, { payload }) => {
      state.isOrderRefundPending = false;
      state.refundItems = payload.data.results;
    },
    orderRefundFailure: state => {
      state.isOrderRefundPending = false;
    },

    setProductsInOrder(state, { payload }) {
      const { id } = payload;
      state.productsInOrder[id] = { ...state.productsInOrder[id], ...payload };
    },
    deleteProductsInOrder(state, { payload }) {
      delete state.productsInOrder[payload];
    },
    resetProductsInOrder(state) {
      state.productsInOrder = {};
    },
  },
});

export const actions = actionTypes(ordersSlice.actions);

export default ordersSlice.reducer;
