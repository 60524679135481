import { createSelector } from 'reselect';

export const rootSelector = state => state.payments;

export const isPendingSelector = createSelector(
  rootSelector,
  ({ isPending }) => isPending
);

export const intentSelector = createSelector(
  rootSelector,
  ({ intent }) => intent
);

export const paymentMethodsSelector = createSelector(
  rootSelector,
  ({ paymentMethods }) => paymentMethods
);
