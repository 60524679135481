import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { actions as ordersActions } from 'models/orders/slice';
import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import PageWrapper from 'components/PageWrapper';

import { orderSelector } from 'models/orders/selectors';
import OrderLayout from 'components/OrderLayout/OrderLayout';

const CreateWineSelection = ({ title }) => {
  const clearOrder = useAction(ordersActions.clearOrder);

  const createRetailOrder = useAction(ordersActions.createRetailOrder);
  const order = useSelector(orderSelector);

  useEffect(() => {
    clearOrder();
  }, [clearOrder]);

  const onCheckoutClick = (
    productsArray,
    address,
    saveAs,
    coupon,
    withApproveRedirect = true
  ) => {
    const orderProducts = productsArray.map(selectedItem => {
      const orderItem = order?.order_items?.find(
        item => selectedItem.id === item?.product.id
      );

      return {
        number: selectedItem.number,
        product_id: selectedItem.id,
        id: orderItem ? orderItem.id : null,
      };
    });

    const payload = {
      order_items: orderProducts,
      ...address,
      discount_code: coupon,
      save_profile_address: saveAs.saveProfileAddress,
      save_billing_data: saveAs.saveBillingAddress,
      withApproveRedirect,
    };

    createRetailOrder(payload);
  };

  return (
    <PageWrapper title={title}>
      <OrderLayout
        title={title}
        // order={order}
        onCheckoutClick={onCheckoutClick}
      />
    </PageWrapper>
  );
};

CreateWineSelection.propTypes = {
  title: PropTypes.string,
};

export default CreateWineSelection;
