import React from 'react';
import PropTypes from 'prop-types';

import styles from './Title.scss';
import classNames from 'classnames';

const Title = ({ className, title, subtitle }) => {
  return (
    <div className={classNames(className, styles.root)}>
      {title && <h1 className={styles.title}>{title}</h1>}
      {subtitle && <h4 className={styles.subtitle}>{subtitle}</h4>}
    </div>
  );
};

Title.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default Title;
