import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import PageWrapper from 'components/PageWrapper';
import { actions as ordersActions } from 'models/orders/slice';
import useAction from 'hooks/useAction';

import useSelector from 'hooks/useSelector';
import OrderLayout from 'components/OrderLayout/OrderLayout';
import { orderSelector } from 'models/orders/selectors';
import { useParams } from 'react-router-dom';

const EditWineSelection = ({ title }) => {
  const { orderID } = useParams();
  const updateOrder = useAction(ordersActions.updateOrder);
  const order = useSelector(orderSelector);
  const fetchOrder = useAction(ordersActions.fetchOrder);

  useEffect(() => {
    fetchOrder(orderID);
  }, [fetchOrder, orderID]);

  const onCheckoutClick = (
    productsArray,
    address,
    saveAs,
    coupon,
    withRedirect = true
  ) => {
    const isRetail = order?.resourcetype === 'RetailOrder';

    const orderItems = productsArray.map(selectedItem => {
      const orderItem = order?.order_items?.find(
        item => selectedItem.id === item?.product.id
      );
      return {
        number: selectedItem.number,
        product_id: selectedItem.id,
        id: orderItem ? orderItem.id : null,
      };
    });

    const payload = {
      id: orderID,
      order_items: orderItems,
      is_retail: isRetail,
      ...address,
      discount_code: coupon,
      save_profile_address: saveAs.saveProfileAddress,
      save_billing_data: saveAs.saveBillingAddress,
    };

    if (withRedirect) {
      payload.redirectUrl = isRetail
        ? `/wine-selection/${orderID}/approve`
        : `/wine-selection/approve`;
    }

    updateOrder(payload);
  };

  return (
    <PageWrapper title={title}>
      <OrderLayout
        title={title}
        order={order}
        onCheckoutClick={onCheckoutClick}
      />
    </PageWrapper>
  );
};

EditWineSelection.propTypes = {
  title: PropTypes.string,
};

export default EditWineSelection;
