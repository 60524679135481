import React from 'react';
import { Link } from 'react-router-dom';

import Icon from 'components/Icon';

import s from './NotFound.scss';

const NotFound = () => {
  return (
    <div className={s.root}>
      <div>
        <div className={s.title}>
          <span>404 </span>
          <Icon className="ml-4" color="black" name="Icon404" width="100px" />
        </div>
        <p className={s.subtitle}>Page not found.</p>
        <Link className={s.backHomeLink} to="/my-wineclub">
          <span> Back Home </span>
          <Icon
            className="ml-3"
            color="black"
            name="DiagonalUpArrow"
            width="100px"
          />
        </Link>{' '}
      </div>
    </div>
  );
};

export default NotFound;
