import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styles from './CustomerInfo.scss';
import { NavLink } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import InfoCard from 'components/InfoCard';
import { getPreparedAddress } from '../../../utils/getPreparedAddress';

const CustomerInfo = ({ customer }) => {
  return (
    <div className="mb-4">
      <Row>
        <Col>
          <NavLink to="/address/edit" className={styles.link}>
            Edit my information
          </NavLink>
        </Col>
      </Row>
      <Row xl="4" lg="3" md="2" sm="1" xs="1" className="mt-2">
        <Col className="mb-4">
          <InfoCard
            title="Name"
            value={`${customer?.first_name} ${customer?.last_name}`}
            className={styles.info}
            type="big"
          />
        </Col>
        <Col className="mb-4">
          <InfoCard
            title="Email"
            value={customer?.email}
            className={styles.info}
            type="big"
          />
        </Col>
        <Col className="mb-4">
          <InfoCard
            title="Billing Address"
            value={getPreparedAddress(customer?.billing_data)}
            className={styles.info}
            type="big"
          />
        </Col>
        <Col className="mb-4">
          <InfoCard
            title="Billing Phone"
            value={customer?.billing_data?.phone}
            className={styles.info}
            type="big"
          />
        </Col>
        <Col className="mb-4">
          <InfoCard
            title="Shipping Address"
            value={getPreparedAddress(customer?.address)}
            className={styles.info}
            type="big"
          />
        </Col>
        <Col className="mb-4">
          <InfoCard
            title="Shipping Phone"
            value={customer?.phone}
            className={styles.info}
            type="big"
          />
        </Col>
      </Row>
    </div>
  );
};

CustomerInfo.propTypes = {
  customer: PropTypes.object,
};

export default memo(CustomerInfo);
