import { createSelector } from 'reselect';

export const rootSelector = state => state.orders;

export const errorSelector = createSelector(rootSelector, ({ error }) => error);

export const ordersSelector = createSelector(
  rootSelector,
  ({ orders }) => orders
);

export const ordersPaginationSelector = createSelector(
  rootSelector,
  ({ ordersPagination }) => ordersPagination
);

export const orderSelector = createSelector(rootSelector, ({ order }) => order);

export const orderSuccessfullyCreatedSelector = createSelector(
  rootSelector,
  ({ orderSuccessfullyCreated }) => orderSuccessfullyCreated
);

export const orderRefundItemsSelector = createSelector(
  rootSelector,
  ({ refundItems }) => refundItems
);

export const productsInOrderSelector = createSelector(
  rootSelector,
  ({ productsInOrder }) => productsInOrder
);

export const isPendingSelector = createSelector(
  rootSelector,
  ({ isPending }) => isPending
);

export const isPendingRefundOrderSelector = createSelector(
  rootSelector,
  ({ isOrderRefundPending }) => isOrderRefundPending
);

export const paginationSelector = createSelector(
  rootSelector,
  ({ pagination }) => pagination
);
