import { createSlice } from 'redux-starter-kit';
import actionTypes from 'utils/actionTypes';

const commonSlice = createSlice({
  name: 'common',
  initialState: {
    isOpenConfirmationModal: false,
  },
  reducers: {
    setIsOpenConfirmationModal: state => {
      state.isOpenConfirmationModal = !state.isOpenConfirmationModal;
    },
  },
});

export const actions = actionTypes(commonSlice.actions);

export default commonSlice.reducer;
