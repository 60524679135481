import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { useParams } from 'react-router-dom';

import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import Preloader from 'components/Preloader';

import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';

import { actions as ordersActions } from 'models/orders/slice';
import { actions as obligationsActions } from 'models/obligations/slice';
import {
  orderSelector,
  isPendingSelector as ordersIsPendingSelector,
} from 'models/orders/selectors';

import * as userSelectors from 'models/user/selectors';

import Main from './Main';
import Header from './Header';
// import Sidebar from './Sidebar';

const OrdersShow = ({ title }) => {
  const { orderID, action } = useParams();
  const fetchOrder = useAction(ordersActions.fetchOrder);
  const skipOrder = useAction(ordersActions.skipOrder);
  const approveOrder = useAction(ordersActions.approveOrder);
  const changeDueDateOrder = useAction(ordersActions.changeDueDateOrder);
  const cancelObligation = useAction(obligationsActions.cancelObligation);
  const orderIsPending = useSelector(ordersIsPendingSelector);
  const order = useSelector(orderSelector);
  const currentUser = useSelector(userSelectors.currentUserSelector);

  useEffect(() => {
    if (Object.keys(order).length !== 0 && currentUser.wineclub_id) {
      if (action === 'approve') {
        approveOrder(parseInt(orderID, 10));
      }
      if (action === 'skip') {
        skipOrder(parseInt(orderID, 10));
      }
      if (action === 'cancel-obligation') {
        cancelObligation(parseInt(orderID, 10));
      }
      if (action === 'change-due-date') {
        changeDueDateOrder(parseInt(orderID, 10));
      }
    }
  }, [
    approveOrder,
    skipOrder,
    cancelObligation,
    changeDueDateOrder,
    order,
    currentUser,
    action,
    orderID,
  ]);

  useEffect(() => {
    fetchOrder(orderID);
  }, [fetchOrder, orderID]);

  if (orderIsPending) return <Preloader />;

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        link={`/orders/${order?.id}/actions/edit`}
        title={`${title} #${order?.id}`}
        breadcrumbItems={[{ title: 'Back to orders', link: '/orders' }]}
      />
      <Row>
        <Col lg="12">
          <Header order={order} />
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Main orderItems={order?.order_items} />
        </Col>
      </Row>
    </PageWrapper>
  );
};

OrdersShow.propTypes = {
  title: PropTypes.string,
};

export default OrdersShow;
