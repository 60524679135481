import React from 'react';
import { Button, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const WideButton = ({ children, onClick, loading, className, ...props }) => {
  return (
    <Button
      className={classNames(
        className,
        'btn-dark w-md waves-effect waves-light'
      )}
      onClick={onClick}
      {...props}
      disabled={loading}
    >
      {loading ? <Spinner size="sm" /> : children}
    </Button>
  );
};

WideButton.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  className: PropTypes.string,
};
export default WideButton;
