import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { HUMAN_ORDER_STATUSES, ORDER_TYPES } from 'constants';
import { formattedDate } from '../../../utils/formattedDate';
import formatPrice from '../../../utils/formatPrice';
import MobileItemBlock from '../../../components/MobileItemBlock';
import getOrderActionsPermissions from 'utils/getOrderActionsPermissions';
import { Button, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Pagination from 'components/Pagination';
import styles from './MobileBlocks.scss';

const MobileBlocks = ({
  orders,
  handleSkipOrder,
  pagination,
  onPageChange,
}) => {
  return (
    <div>
      {orders.map(order => {
        const {
          viewable,
          editable,
          skippable,
          approvable,
        } = getOrderActionsPermissions(order);
        const isActions = viewable || editable || skippable || approvable;

        return (
          // TODO: fix the semantics and check how it works
          <Card>
            <CardBody>
              <h4>
                {ORDER_TYPES[order.resourcetype]} #{order.id}
              </h4>
              <MobileItemBlock
                title="Status"
                value={
                  order.status === 'DRAFT' || order.status === 'FULFILLED'
                    ? 'Processed by Manager'
                    : HUMAN_ORDER_STATUSES[order.status]
                }
              />
              <MobileItemBlock
                title="Created at"
                value={formattedDate(order.created_at, 'yyyy-MM-dd')}
              />
              <MobileItemBlock
                title="Price"
                value={`$${formatPrice(order.total_price)}`}
              />
              {isActions && (
                <MobileItemBlock
                  title="Actions"
                  value={
                    <>
                      {(order?.resourcetype !== 'SubscriptionOrder' ||
                        viewable) && (
                        <Link to={`/order/${order.id}`} className="d-block">
                          Details
                        </Link>
                      )}
                      {order.resourcetype === 'RetailOrder' &&
                        !order.is_created_by_manager &&
                        editable && (
                          <Link
                            to={`/wine-selection/${order.id}/edit`}
                            className="ml-2"
                          >
                            Edit
                          </Link>
                        )}
                      {order?.resourcetype === 'SubscriptionOrder' && editable && (
                        <Link
                          to={`/orders/${order.id}/actions/edit`}
                          className="d-block"
                        >
                          Edit
                        </Link>
                      )}
                      {order?.resourcetype === 'SubscriptionOrder' &&
                        skippable && (
                          <Button
                            color="link"
                            className={classNames(styles.link, 'd-block')}
                            onClick={() => handleSkipOrder(order.id)}
                          >
                            Skip
                          </Button>
                        )}
                      {order?.resourcetype === 'SubscriptionOrder' &&
                        approvable && (
                          <Link
                            to={`/orders/${order.id}/actions/checkout`}
                            className="d-block"
                          >
                            Approve
                          </Link>
                        )}
                    </>
                  }
                />
              )}
            </CardBody>
          </Card>
        );
      })}
      {pagination && pagination.total_pages > 1 && (
        <Pagination pagination={pagination} onClick={onPageChange} />
      )}
    </div>
  );
};

MobileBlocks.propTypes = {
  orders: PropTypes.array,
  handleSkipOrder: PropTypes.func,
  pagination: PropTypes.object,
  onPageChange: PropTypes.func,
};

export default memo(MobileBlocks);
