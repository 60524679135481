import React from 'react';
import TextField from './TextField';
import { ControlledFieldProps } from './ControlledFieldProps.d';

const EmailInvalidMessage = 'Enter correct email';

const EmailField = ({ ...props }) => {
  return (
    <TextField
      name="email"
      label="Email"
      placeholder="Email"
      rules={{
        pattern: {
          value: /^([A-Za-z0-9_+\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/,
          errorMessage: EmailInvalidMessage,
        },
      }}
      required
      {...props}
    />
  );
};

EmailField.propTypes = ControlledFieldProps;
export default EmailField;
