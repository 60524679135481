import React from 'react';
import styles from './ProductCard.scss';
import PropTypes from 'prop-types';
import formatPrice from '../../utils/formatPrice';
import CircleButton from '../CircleButton';
import classNames from 'classnames';

const ProductCard = ({
  title,
  img,
  volume_value,
  volume_unit,
  warehouse_inventory_count,
  alcohol,
  price,
  count,
  onCountChange,
  className,
  discounted_product_price,
}) => {
  return (
    <div className={classNames(styles.container, className)}>
      <img src={img} alt={title} className={styles.image} />
      <h3 className={styles.title}>{title}</h3>
      <p className="mb-0">
        {volume_value} {volume_unit} | {alcohol}%
      </p>
      <p className={styles.blockPrice}>
        {price !== discounted_product_price ? (
          <>
            <del>${formatPrice(price)}</del>
            <span className={styles.price}>
              ${formatPrice(discounted_product_price)}
            </span>
          </>
        ) : (
          <span className={styles.price}>${formatPrice(price)}</span>
        )}
      </p>
      <div className={styles.action}>
        <CircleButton
          onClick={() => onCountChange(count - 1)}
          className={styles.button}
          disabled={count === 0}
        >
          -
        </CircleButton>
        <CircleButton className={styles.button} dark>
          {count}
        </CircleButton>
        <CircleButton
          onClick={() => onCountChange(count + 1)}
          className={styles.button}
          disabled={count === warehouse_inventory_count}
        >
          +
        </CircleButton>
      </div>
    </div>
  );
};

ProductCard.propTypes = {
  title: PropTypes.string.isRequired,
  volume_value: PropTypes.number,
  volume_unit: PropTypes.string,
  warehouse_inventory_count: PropTypes.number,
  alcohol: PropTypes.number,
  price: PropTypes.number,
  count: PropTypes.number,
  img: PropTypes.string,
  onCountChange: PropTypes.func,
  className: PropTypes.string,
  discounted_product_price: PropTypes.number,
};

export default ProductCard;
