import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Row, Col, Button, Label, FormGroup, CustomInput } from 'reactstrap';
import { AvForm, AvField, AvInput } from 'availity-reactstrap-validation';

import useAction from 'hooks/useAction';
import { actions as userActions } from 'models/user/slice';

import s from '../authentication.scss';
import { NavLink } from 'react-router-dom';
import AuthBlock from '../AuthBlock';
import useSelector from '../../../hooks/useSelector';
import { currentWineclubSelector } from '../../../models/wineclubs/selectors';

const Login = ({ title }) => {
  const login = useAction(userActions.login);
  const currentWineclub = useSelector(currentWineclubSelector);
  const [email, setEmail] = useState('');

  const handleSubmit = useCallback(
    (event, values) => {
      login({ ...values });
    },
    [login]
  );

  return (
    <AuthBlock title={title}>
      <div className={cx(`${s.content} d-flex align-items-center`)}>
        <div className="w-100">
          <Row className="justify-content-center no-gutters">
            <Col lg={12}>
              <div>
                <div className="text-left">
                  <h4 className="font-size-18 mt-4">
                    Log in{' '}
                    {currentWineclub?.brand_title
                      ? `to ${currentWineclub?.brand_title}`
                      : ''}
                  </h4>
                  <p className="text-muted mb-0">
                    Sign in to continue to your {currentWineclub?.title} account
                    or
                  </p>
                  <NavLink to="/join-wineclub">Join the Wine Club</NavLink>
                </div>

                <div className="mt-5">
                  <AvForm
                    className="form-horizontal"
                    onValidSubmit={handleSubmit}
                  >
                    <FormGroup className="auth-form-group-custom mb-4">
                      <i
                        className={cx(
                          `${s.blackColor} ri-mail-line auto-custom-input-icon`
                        )}
                      />
                      <Label htmlFor="email">Email</Label>
                      <AvField
                        name="email"
                        type="text"
                        className="form-control"
                        id="email"
                        validate={{ email: true, required: true }}
                        placeholder="Enter email"
                        value={email}
                        onChange={event => setEmail(event.target.value)}
                      />
                    </FormGroup>

                    <FormGroup className="auth-form-group-custom mb-4">
                      <i
                        className={cx(
                          `${s.blackColor} ri-lock-2-line auto-custom-input-icon`
                        )}
                      />
                      <Label htmlFor="userpassword">Password</Label>
                      <AvField
                        name="password"
                        type="password"
                        className="form-control"
                        id="userpassword"
                        placeholder="Enter password"
                        validate={{ required: true }}
                      />
                    </FormGroup>

                    <div className="pl-0 custom-control custom-checkbox mb-4">
                      <AvInput
                        tag={CustomInput}
                        type="checkbox"
                        value
                        label="Remember me"
                        id="remember_me"
                        name="remember_me"
                      />
                    </div>

                    <div className="mt-4 text-center">
                      <Button
                        className="btn-dark w-md waves-effect waves-light"
                        type="submit"
                      >
                        Log In
                      </Button>
                    </div>
                  </AvForm>

                  <div className="mt-4 text-center">
                    <NavLink
                      to={`/forgot-password/?email=${encodeURIComponent(
                        email
                      )}`}
                    >
                      Forgot password?
                    </NavLink>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </AuthBlock>
  );
};

Login.propTypes = {
  title: PropTypes.string,
};

export default Login;
