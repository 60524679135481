import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, CardBody } from 'reactstrap';

import s from './Header.scss';

import { ORDER_TYPES } from 'constants';

const Header = ({ order }) => {
  return (
    <Row>
      <Col lg="4">
        <Card className={s.card}>
          <CardBody>
            <h4 className="card-title">Total Price</h4>
            <div className="mt-4">
              {order?.total_price} {order?.total_price_currency}
            </div>
          </CardBody>
        </Card>
      </Col>
      {order?.tips_amount_currency && (
        <Col lg="4">
          <Card className={s.card}>
            <CardBody>
              <h4 className="card-title">Tips amount</h4>
              <div className="mt-4">
                {order?.tips_amount} {order?.tips_amount_currency}
              </div>
            </CardBody>
          </Card>
        </Col>
      )}
      <Col lg="4">
        <Card className={s.card}>
          <CardBody>
            <h4 className="card-title">Type</h4>
            <div className="mt-4">{ORDER_TYPES[order?.resourcetype]}</div>
          </CardBody>
        </Card>
      </Col>

      {order?.resourcetype === 'SubscriptionOrder' && (
        <>
          <Col lg="4">
            <Card className={s.card}>
              <CardBody>
                <h4 className="card-title">Subscription Tier</h4>
                <div className="d-flex flex-column">
                  <div className="mt-2">{`- ${order.obligation.tier?.title}`}</div>
                  <div className="mt-2">{`- ${order.obligation.tier?.period_in_months}`}</div>
                  <div className="mt-2">{`- ${order.obligation.tier?.capacity} bottles`}</div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4">
            <Card className={s.card}>
              <CardBody>
                <h4 className="card-title">Subscription Status</h4>
                <div className="mt-4 capitalize">
                  {order.obligation.status.toLowerCase()}
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4">
            <Card className={s.card}>
              <CardBody>
                <h4 className="card-title">Shipping Info</h4>
                <div className="mt-4">
                  {`${order?.shipping_info?.country}, ${order?.shipping_info?.state}, ${order?.shipping_info?.city},
                  ${order?.shipping_info?.postal_code}`}
                </div>
              </CardBody>
            </Card>
          </Col>
        </>
      )}
    </Row>
  );
};

Header.propTypes = {
  order: PropTypes.object,
};

export default Header;
