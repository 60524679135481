import { createSlice } from 'redux-starter-kit';
import actionTypes from 'utils/actionTypes';

const obligationsSlice = createSlice({
  name: 'obligations',
  initialState: {
    isPending: false,
    obligations: [],
    obligation: {},
    obligationPopup: false,
  },
  reducers: {
    setObligationPopup: (state, { payload }) => {
      state.obligationPopup = payload;
    },
    fetchObligations: state => {
      state.isPending = true;
    },
    fetchObligationsSuccess(state, { payload }) {
      state.isPending = false;
      state.obligations = payload.data.results;
    },
    fetchObligationsFailure: state => {
      state.isPending = false;
    },
    fetchObligation: state => {
      state.isPending = true;
    },
    fetchObligationSuccess(state, { payload }) {
      state.isPending = false;
      state.obligation = payload.data;
    },
    fetchObligationFailure: state => {
      state.isPending = false;
    },
    createObligation: state => {
      state.isPending = true;
      state.obligationPopup = true;
    },
    createObligationSuccess(state, { payload }) {
      state.obligations = state.obligations.concat(payload.data);
      state.isPending = false;
      state.obligationPopup = false;
    },
    createObligationFailure: state => {
      state.isPending = false;
    },
    confirmObligation: state => {
      state.isPending = true;
    },
    confirmObligationSuccess(state, { payload }) {
      const { data } = payload;
      state.obligations = state.obligations.map(obligation =>
        obligation.id === data.id ? data : obligation
      );
      state.isPending = false;
    },
    confirmObligationFailure: state => {
      state.isPending = false;
    },
    cancelObligation: state => {
      state.isPending = true;
    },
    cancelObligationSuccess(state, { payload }) {
      state.obligations = state.obligations.map(item => {
        if (item.id !== payload.inResponseTo) {
          return item;
        }
        return {
          ...payload.data,
        };
      });
      state.isPending = false;
    },
    cancelObligationFailure: state => {
      state.isPending = false;
    },
    resetObligation: state => {
      state.obligation = {};
    },
  },
});

export const actions = actionTypes(obligationsSlice.actions);

export default obligationsSlice.reducer;
