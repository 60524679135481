import React, { useState } from 'react';
import { Button, Row, Col } from 'reactstrap';

import { AvForm } from 'availity-reactstrap-validation';
import TextField from '../../../components/Fields/TextField';
import PhoneField from '../../../components/Fields/PhoneField/PhoneField';
import LocationSearchInputWithAddressBook from 'components/LocationSearchInputWithAddressBook';
import PropTypes from 'prop-types';

const Form = ({ onSubmit, defaultValues }) => {
  const [billingCountry, setBillingCountry] = useState(
    defaultValues?.billing_data?.country || ''
  );
  const [billingState, setBillingState] = useState(
    defaultValues?.billing_data?.state || ''
  );
  const [billingPostalCode, setBillingPostalCode] = useState(
    defaultValues?.billing_data?.postal_code || ''
  );
  const [billingCity, setBillingCity] = useState(
    defaultValues?.billing_data?.city || ''
  );
  const [billingLine1, setBillingLine1] = useState(
    defaultValues?.billing_data?.line1 || ''
  );
  const [billingLine2, setBillingLine2] = useState(
    defaultValues?.billing_data?.line2 || ''
  );
  const [billingPhone, setBillingPhone] = useState(
    defaultValues?.billing_data?.phone || ''
  );

  const submitHandler = (event, values) => {
    const data = {
      ...values,
      billing_country: billingCountry,
      billing_state: billingState,
      billing_city: billingCity,
      billing_line1: billingLine1,
      billing_line2: billingLine2,
      billing_postal_code: billingPostalCode,
      billing_phone: billingPhone,
    };
    onSubmit?.(data);
  };

  return (
    <AvForm
      className="mt-4"
      onValidSubmit={submitHandler}
      model={{
        ...defaultValues,
        billing_phone: defaultValues?.billing_data?.phone,
      }}
    >
      <Row>
        <Col md={4}>
          <TextField
            label="First Name"
            name="first_name"
            placeholder="First Name"
            isRequired
            errorMessage="Enter First Name"
          />
        </Col>
        <Col md={4}>
          <TextField
            label="Last Name"
            name="last_name"
            placeholder="Last Name"
            isRequired
            errorMessage="Enter Last Name"
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={8}>
          <LocationSearchInputWithAddressBook
            label="Billing Address"
            setCountry={setBillingCountry}
            setState={setBillingState}
            setPostalCode={setBillingPostalCode}
            setCity={setBillingCity}
            setLine1={setBillingLine1}
            setLine2={setBillingLine2}
            setPhone={setBillingPhone}
            country={billingCountry}
            state={billingState}
            postalCode={billingPostalCode}
            city={billingCity}
            line1={billingLine1}
            line2={billingLine2}
          />
        </Col>
        <Col md={4}>
          <PhoneField
            label="Billing Phone"
            name="billing_phone_custom"
            value={billingPhone}
            onChange={setBillingPhone}
            placeholder="Phone"
            errorMessage="Phone number should be 11 digit number"
          />
        </Col>
      </Row>
      <Button className="btn-rounded mt-4" type="submit">
        Save
      </Button>
    </AvForm>
  );
};

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  defaultValues: PropTypes.object,
};
export default Form;
