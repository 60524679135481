import React from 'react';
import PropTypes from 'prop-types';
import styles from './OrderItem.scss';
import CircleButton from '../CircleButton';

const OrderItem = ({ onDelete, title, number }) => {
  return (
    <div className={styles.item}>
      {!!onDelete && (
        <button onClick={onDelete} className={styles.button}>
          <i className="ri-close-line" />
        </button>
      )}
      <CircleButton notClickable>{number}</CircleButton>
      <p className={styles.title}>{title}</p>
    </div>
  );
};

OrderItem.propTypes = {
  onDelete: PropTypes.func,
  title: PropTypes.string,
  number: PropTypes.number,
};

export default OrderItem;
