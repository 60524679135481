import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { useSelector } from 'react-redux';
import {
  warehouseProductOptionsSelector,
  warehouseProductsPaginationSelector,
} from 'models/products/selectors';
import useAction from 'hooks/useAction';
import { actions as productsActions } from 'models/products/slice';
import {
  isPendingSelector as isPendingSelectorOrder,
  errorSelector,
} from 'models/orders/selectors';
import Preloader from 'components/Preloader';

import Title from 'components/Title';
import OrderCheckoutCard from '../OrderCheckoutCard';
import ProductCard from '../ProductCard';
import ProductPagination from '../ProductPagination';
import { getOrderSubtotal } from 'utils/getOrderSubtotal';
import { getTotalTax } from 'utils/getTotalTax';
import { getPreparedOrderItems } from 'utils/getPreparedOrderItems';

import styles from './OrderLayout.scss';
import RetailOrderAddressPopup from './RetailOrderAddressPopup';
import { currentUserSelector } from '../../models/user/selectors';

const getSelectedItemsCount = selectedItems => {
  return selectedItems.reduce(
    (previous, current) => previous + current?.number ?? 0,
    0
  );
};

const OrderLayout = ({ order, onCheckoutClick }) => {
  const [page, setPage] = useState(1);
  const [isAddressPopupVisible, setIsAddressPopupVisible] = useState(false);

  const [selectedProducts, setSelectedProducts] = useState({});
  const fetchProducts = useAction(productsActions.fetchProductsWithWarehouse);
  const isPending = useSelector(isPendingSelectorOrder);
  const error = useSelector(errorSelector);

  const selectedProductsArray = Object.values(selectedProducts);
  const selectedItems = getSelectedItemsCount(selectedProductsArray);
  const productsPagination = useSelector(warehouseProductsPaginationSelector);
  const products = useSelector(warehouseProductOptionsSelector);
  const currentUser = useSelector(currentUserSelector);

  const getOrderDiscount = () => {
    if (order?.percentage_discount) return order.percentage_discount ?? 0;
    if (
      currentUser?.current_obligation &&
      currentUser?.current_obligation?.status !== 'CANCELLED'
    ) {
      return currentUser?.current_obligation?.tier.percentage_discount ?? 0;
    }
    if (
      currentUser?.last_selected_tier &&
      !(currentUser?.subscripted_tier_ids ?? []).length
    ) {
      return currentUser?.last_selected_tier?.percentage_discount ?? 0;
    }
    return 0;
  };

  useEffect(() => {
    if (order?.warehouse_id != null) {
      fetchProducts({ warehouse_id: order.warehouse_id, page });
    } else {
      fetchProducts({ page });
    }
  }, [order, fetchProducts, page]);

  const hideAddressPopup = useCallback(() => {
    setIsAddressPopupVisible(false);
  }, []);

  const showAddressPopup = useCallback(() => {
    setIsAddressPopupVisible(true);
  }, []);

  const subtotal =
    getOrderSubtotal(selectedProductsArray, getOrderDiscount()) ?? 0;

  const totalTax =
    getTotalTax(
      selectedProductsArray,
      getOrderDiscount(),
      order?.shipping_info?.state
    ) ?? 0;

  const totalPrice = subtotal + totalTax + (order?.shipment_price ?? 0);

  useEffect(() => {
    if (order?.order_items) {
      setSelectedProducts(getPreparedOrderItems(order?.order_items));
    }
  }, [order]);

  const removeFromSelectedProducts = id => {
    const mutatedProducts = { ...selectedProducts };
    delete mutatedProducts[id];
    setSelectedProducts(mutatedProducts);
  };

  const updateNumberOfProduct = (product, number) => {
    setSelectedProducts({
      ...selectedProducts,
      [product.id]: {
        ...selectedProducts[product.id],
        ...product,
        number,
      },
    });
  };

  const onCountChange = (product, number) => {
    if (number === 0) {
      removeFromSelectedProducts(product.id);
      return;
    }
    updateNumberOfProduct(product, number);
  };

  if (isPending) return <Preloader />;

  return (
    <>
      {isAddressPopupVisible && (
        <RetailOrderAddressPopup
          onClose={hideAddressPopup}
          defaultAddress={order?.shipping_info || currentUser?.address}
          withSaving={order?.resourcetype !== 'SubscriptionOrder'}
          onSubmit={(address, saveAs) =>
            onCheckoutClick(selectedProductsArray, address, saveAs)
          }
        />
      )}
      <Title title="Wine Selection" />
      <Row className="mt-5">
        <Col md={4}>
          <OrderCheckoutCard
            order={{ ...order, percentage_discount: getOrderDiscount() }}
            orderItems={Object.values(selectedProducts)}
            total={totalPrice}
            fee={totalTax}
            subtotal={subtotal}
            selectedItems={selectedItems}
            onDelete={removeFromSelectedProducts}
            onCheckoutClick={showAddressPopup}
            onApplyClick={onCheckoutClick}
            isPending={isPending}
            error={error}
            isRetailOrder
          />
        </Col>
        <Col md={8}>
          <h3 className={styles.productsTitle}>
            Select any wines from our fine collection
          </h3>
          <div className={styles.productWrapper}>
            {products.map(product => (
              <ProductCard
                className={styles.productCard}
                key={product.id.toString()}
                discount={getOrderDiscount()}
                {...product}
                img={product?.main_image_url}
                count={selectedProducts[product.id]?.number ?? 0}
                onCountChange={count => onCountChange(product, count)}
              />
            ))}
          </div>
          <Row className="justify-content-center mt-5">
            {productsPagination && (
              <ProductPagination
                page={page}
                totalPages={productsPagination.total_pages}
                onPageChange={setPage}
              />
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
};

OrderLayout.propTypes = {
  order: PropTypes.object,
  onCheckoutClick: PropTypes.func,
  orderId: PropTypes.number,
};

export default OrderLayout;
