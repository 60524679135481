import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import PageWrapper from 'components/PageWrapper';
import styles from './OptionsWineSelection.scss';
import { Button, Col, Row } from 'reactstrap';
import Section from './Section/Section';
import Title from '../../components/Title/Title';
import ConfirmationModal from 'components/ConfirmationModal';

import useSelector from '../../hooks/useSelector';
import { currentUserSelector } from '../../models/user/selectors';
import { actions as ordersActions } from 'models/orders/slice';
import { actions as commonActions } from 'models/common/slice';
import useAction from '../../hooks/useAction';

const OptionsWineSelection = ({ title }) => {
  const fetchOrder = useAction(ordersActions.fetchOrder);
  const user = useSelector(currentUserSelector);
  const obligation = user?.current_obligation;
  const updateOrder = useAction(ordersActions.updateOrder);
  const skipOrder = useAction(ordersActions.skipOrder);
  const setIsOpenConfirmationModal = useAction(
    commonActions.setIsOpenConfirmationModal
  );

  const orderID = obligation?.last_unpaid_order_id;

  const checkoutOrder = items => {
    const payload = (items || []).map(item => ({
      product_id: item.product_id,
      number: item.quantity,
    }));

    updateOrder({
      id: orderID,
      order_items: payload,
      redirectUrl: `/wine-selection/${orderID}/edit`,
    });
  };

  const handleSkipOrder = useCallback(() => {
    skipOrder(orderID);
  }, [orderID, skipOrder]);

  useEffect(() => {
    if (orderID) {
      fetchOrder(orderID);
    }
  }, [fetchOrder, orderID]);

  return (
    <PageWrapper title={title}>
      <Title title={title} />
      <h3 className={styles.subtitle}>Make your selections</h3>
      <Row>
        {(obligation?.tier?.tier_prefills || []).map(prefill => (
          <Col md={4} className="mb-5">
            <Section
              title={prefill?.title}
              onClick={() => checkoutOrder(prefill?.products)}
              items={prefill?.products || []}
            />
          </Col>
        ))}
        <Col md={4} className={styles.block}>
          <Button
            onClick={() => checkoutOrder([])}
            className={styles.button}
            color="success"
          >
            Make your own selection
          </Button>
          <h5 className={styles.title}>
            SELECT YOUR OWN
            <br />
            {obligation?.tier?.capacity} BOTTLES
          </h5>
        </Col>
        <Col md={4} className={styles.block}>
          <Button
            onClick={setIsOpenConfirmationModal}
            className={styles.button}
            color="danger"
          >
            Skip this month
          </Button>
        </Col>
      </Row>
      <ConfirmationModal
        title="Are you sure you want to skip this  order?"
        submitButtonTitle="Skip"
        onSaveClick={handleSkipOrder}
      />
    </PageWrapper>
  );
};

OptionsWineSelection.propTypes = {
  title: PropTypes.string,
};

export default OptionsWineSelection;
