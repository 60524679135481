import { createSelector } from 'reselect';

export const rootSelector = state => state.products;

export const productsSelector = createSelector(
  rootSelector,
  ({ products }) => products
);

export const productsPaginationSelector = createSelector(
  rootSelector,
  ({ productsPagination }) => productsPagination
);

export const isPendingSelector = createSelector(
  rootSelector,
  ({ isPending }) => isPending
);

export const warehouseProductOptionsSelector = createSelector(
  rootSelector,
  ({ warehouseProductsOptions }) => warehouseProductsOptions
);

export const warehouseProductsPaginationSelector = createSelector(
  rootSelector,
  ({ warehouseProductsPagination }) => warehouseProductsPagination
);
