import { all } from 'redux-saga/effects';
import { connectRouter } from 'connected-react-router';

import userReducer from './user/slice';
import tiersReducer from './tiers/slice';
import ordersReducer from './orders/slice';
import commonReducer from './common/slice';
import paymentsReducer from './payments/slice';
import productsReducer from './products/slice';
import wineclubsReducer from './wineclubs/slice';
import obligationsReducer from './obligations/slice';
import orderShipmentRatesReducer from './orderShipmentRates/slice';

import userSagas from './user/sagas';
import ordersSagas from './orders/sagas';
import tiersSagas from './tiers/sagas';
import productsSagas from './products/sagas';
import paymentsSagas from './payments/sagas';
import wineclubsSagas from './wineclubs/sagas';
import obligationsSagas from './obligations/sagas';
import orderShipmentRatesSagas from './orderShipmentRates/sagas';

export const createRootReducer = history => ({
  router: connectRouter(history),
  user: userReducer,
  tiers: tiersReducer,
  common: commonReducer,
  orders: ordersReducer,
  payments: paymentsReducer,
  products: productsReducer,
  wineclubs: wineclubsReducer,
  obligations: obligationsReducer,
  orderShipmentRates: orderShipmentRatesReducer,
});

export const rootSaga = function*() {
  yield all([
    userSagas(),
    tiersSagas(),
    ordersSagas(),
    paymentsSagas(),
    productsSagas(),
    wineclubsSagas(),
    obligationsSagas(),
    orderShipmentRatesSagas(),
  ]);
};
