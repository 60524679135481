import React from 'react';
import styles from './JoinWineClub.scss';
import AuthBlock from '../Auth/AuthBlock';
import PropTypes from 'prop-types';
import { AvForm } from 'availity-reactstrap-validation';
import EmailField from '../../components/Fields/EmailField';
import useSelector from '../../hooks/useSelector';
import {
  currentWineclubSelector,
  isPendingSelector,
} from '../../models/wineclubs/selectors';
import { NavLink } from 'react-router-dom';
import WideButton from '../../components/WideButton';
import useAction from '../../hooks/useAction';
import { actions } from '../../models/wineclubs/slice';

const JoinWineClub = ({ title }) => {
  const joinWineClub = useAction(actions.joinWineClub);
  const currentWineClub = useSelector(currentWineclubSelector);
  const isPending = useSelector(isPendingSelector);

  const onSubmitHandler = (event, values) => {
    joinWineClub(values);
  };

  return (
    <AuthBlock title={title}>
      <div className={styles.container}>
        <h4 className={styles.title}>Join {currentWineClub?.title}</h4>
        <p className={styles.subtitle}>
          Enter your email to join the Wine Club
        </p>
        <AvForm className={styles.form} onValidSubmit={onSubmitHandler}>
          <EmailField name="email" placeholder="Email" />
          <WideButton
            loading={isPending}
            type="submit"
            className={styles.button}
          >
            Join
          </WideButton>
          <p>
            Back to{' '}
            <NavLink to="/login" className="font-weight-medium text-primary">
              Login page
            </NavLink>
          </p>
        </AvForm>
      </div>
    </AuthBlock>
  );
};

JoinWineClub.propTypes = {
  title: PropTypes.string.isRequired,
};
export default JoinWineClub;
