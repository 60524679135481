import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

import s from './Sidebar.scss';

const Sidebar = ({ tier, disabled, handleSubmit }) => {
  return (
    <div className={s.root}>
      {tier && (
        <>
          <div className={s.title}>
            <span>Current Subscription</span>
          </div>

          <div className={s.items}>
            <h2 className={s.tierTitle}>{tier.title}</h2>
            <div className={s.tierSubtitle}>
              <span className={s.capacity}> {tier.capacity}</span>
              {tier.period_in_months}
            </div>
            <div className={s.description}>
              {tier.description.split('\n').map(descItem => (
                <div key={descItem} className={s.descItem}>
                  {descItem}{' '}
                </div>
              ))}
            </div>
          </div>
        </>
      )}

      <div className={s.total}>
        <Button
          color="dark"
          disabled={disabled}
          onClick={handleSubmit}
          className={cx('mt-3', s.totalBtn)}
        >
          Confirm new Subscription
        </Button>
      </div>
    </div>
  );
};

Sidebar.propTypes = {
  tier: PropTypes.object,
  disabled: PropTypes.bool,
  handleSubmit: PropTypes.func,
};

export default Sidebar;
