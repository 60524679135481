import { createSlice } from 'redux-starter-kit';
import actionTypes from 'utils/actionTypes';

const wineclubsSlice = createSlice({
  name: 'wineclubs',
  initialState: {
    isPending: false,
    wineclubs: [],
    wineclub: {},
    paymentInfo: {},
    currentWineclub: null,
    currentWineclubPending: false,
  },
  reducers: {
    fetchWineclubs: state => {
      state.isPending = true;
    },
    fetchWineclubsSuccess(state, { payload }) {
      state.wineclubs = payload.data.results;
      state.isPending = false;
    },
    fetchWineclubsFailure: state => {
      state.isPending = false;
    },
    fetchWineclub: state => {
      state.isPending = true;
    },
    fetchWineclubSuccess(state, { payload }) {
      state.isPending = false;
      state.wineclub = payload.data;
    },
    fetchWineclubFailure: state => {
      state.isPending = false;
    },
    fetchPaymentInfo: state => {
      state.isPending = true;
    },
    fetchPaymentInfoSuccess(state, { payload }) {
      state.isPending = false;
      state.paymentInfo = payload.data;
    },
    fetchPaymentInfoFailure: state => {
      state.isPending = false;
    },
    fetchCurrentWineclub: state => {
      state.currentWineclubPending = true;
    },
    fetchCurrentWineclubSuccess: (state, { payload }) => {
      state.currentWineclub = payload.data;
      state.currentWineclubPending = false;
    },
    fetchCurrentWineclubFailure: state => {
      state.currentWineclubPending = false;
    },
    joinWineClub: state => {
      state.isPending = true;
    },
    joinWineClubSuccess: state => {
      state.isPending = false;
    },
    joinWineClubFailure: state => {
      state.isPending = false;
    },
  },
});

export const actions = actionTypes(wineclubsSlice.actions);

export default wineclubsSlice.reducer;
