import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './SubscriptionCreate.scss';
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledTooltip,
} from 'reactstrap';

import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import * as wineclubsSelectors from 'models/wineclubs/selectors';
import * as userSelectors from 'models/user/selectors';
import { selectedTierIdSelector } from 'models/tiers/selectors';
import { actions as obligationsActions } from 'models/obligations/slice';
import { actions as wineclubsActions } from 'models/wineclubs/slice';

import PaymentInformation from './PaymentInformation';
import TiersNew from './Tiers';
import { isPendingSelector } from '../../models/payments/selectors';
import ShippingInfo from './ShippingInfo/ShippingInfo';
import classNames from 'classnames';
import GiftInfo from './GiftInfo/GiftInfo';

const SubscriptionCreate = () => {
  const fetchPaymentInfo = useAction(wineclubsActions.fetchPaymentInfo);
  const fetchObligations = useAction(obligationsActions.fetchObligations);
  const createObligation = useAction(obligationsActions.createObligation);
  const resetObligation = useAction(obligationsActions.resetObligation);
  const paymentInfo = useSelector(wineclubsSelectors.paymentInfoSelector);
  const selectedTierId = useSelector(selectedTierIdSelector);
  const currentUser = useSelector(userSelectors.currentUserSelector);
  const paymentMethodPending = useSelector(isPendingSelector);

  const [country, setCountry] = useState(currentUser?.address?.country || '');
  const [state, setState] = useState(currentUser?.address?.state || '');
  const [postalCode, setPostalCode] = useState(
    currentUser?.address?.postal_code || ''
  );
  const [city, setCity] = useState(currentUser?.address?.city || '');
  const [line1, setLine1] = useState(currentUser?.address?.line1 || '');
  const [line2, setLine2] = useState(currentUser?.address?.line2 || '');
  const [phone, setPhone] = useState(currentUser?.phone || '');
  const [isPhoneTouched, setIsPhoneTouched] = useState(false);
  const [isGift, setIsGift] = useState(false);
  const [message, setMessage] = useState(null);
  const [saveProfileAddress, setSaveProfileAddress] = useState(true);
  const [saveBillingAddress, setSaveBillingAddress] = useState(false);

  const [activeTab, setActiveTab] = useState(1);
  const [lastStep, setLastStep] = useState(1);

  const isNextDisabled = useMemo(() => {
    return (
      paymentMethodPending ||
      (activeTab === 2 && !currentUser?.active_payment_method)
    );
  }, [currentUser, activeTab, paymentMethodPending]);

  const buttonText = useMemo(
    () => (activeTab === 1 ? 'Activate my subscription' : 'Next'),
    [activeTab]
  );

  const toggleTab = tab => {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 3) {
        setActiveTab(tab);
      }
    }
  };

  useEffect(() => {
    fetchObligations();
    fetchPaymentInfo();
  }, [fetchObligations, fetchPaymentInfo]);

  useEffect(() => resetObligation, [resetObligation]);

  const handleSubmit = useCallback(() => {
    createObligation({
      tier_id: selectedTierId,
      postal_code: postalCode,
      country,
      state,
      city,
      line1,
      line2,
      phone: phone?.length === 11 ? phone : `1${phone}`,
      is_gift: isGift,
      gift_note: message,
      save_profile_address: saveProfileAddress,
      save_billing_data: saveBillingAddress,
    });
  }, [
    createObligation,
    selectedTierId,
    country,
    state,
    postalCode,
    city,
    line1,
    line2,
    phone,
    isGift,
    message,
    saveProfileAddress,
    saveBillingAddress,
  ]);

  const handleNextClick = useCallback(() => {
    const step = activeTab + 1;
    setActiveTab(step);
    if (lastStep < step) {
      setLastStep(step);
    }
  }, [activeTab, lastStep]);

  const isPhoneValid = useMemo(
    () => phone?.length >= 10 && !!phone?.match(/^[0-9]+$/),
    [phone]
  );

  const disabledSubmit =
    selectedTierId === null ||
    country === '' ||
    state === '' ||
    postalCode === '' ||
    city === '' ||
    line1 === '' ||
    !isPhoneValid;

  const getErrorMessage = () => {
    const fields = [];
    if (selectedTierId === null) {
      fields.push('tier');
    }
    if (
      country === '' ||
      state === '' ||
      postalCode === '' ||
      city === '' ||
      line1 === ''
    ) {
      fields.push('address');
    }
    if (!isPhoneValid) {
      fields.push('phone');
    }

    return `Please fill in fields: ${fields.join(', ')}`;
  };

  const handleChangeGift = e => {
    setMessage(null);
    setIsGift(e.target.checked);
  };

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <div id="checkout-nav-pills-wizard" className="twitter-bs-wizard">
              <Nav pills justified className="twitter-bs-wizard-nav">
                <NavItem>
                  <NavLink
                    onClick={() => {
                      toggleTab(1);
                    }}
                    active={activeTab === 1}
                  >
                    <span className="step-number">01</span>
                    <span className="step-title">Tiers</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    onClick={() => {
                      toggleTab(2);
                    }}
                    active={activeTab === 2}
                    className={lastStep < 2 && styles.disabledStep}
                    disabled={lastStep < 2}
                  >
                    <span className="step-number">02</span>
                    <span className="step-title">Payment Info</span>
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    active={activeTab === 3}
                    disabled={lastStep < 3}
                    className={lastStep < 3 && styles.disabledStep}
                    onClick={() => {
                      toggleTab(3);
                    }}
                  >
                    <span className="step-number">03</span>
                    <span className="step-title">Shipping Info</span>
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId={1}>
                  <TiersNew />
                </TabPane>
                <TabPane tabId={2}>
                  {Object.keys(paymentInfo).length !== 0 && (
                    <PaymentInformation
                      currentUser={currentUser}
                      paymentInfo={paymentInfo}
                    />
                  )}
                </TabPane>

                <TabPane tabId={3}>
                  <ShippingInfo
                    setCountry={setCountry}
                    setState={setState}
                    setPostalCode={setPostalCode}
                    setCity={setCity}
                    setLine1={setLine1}
                    setLine2={setLine2}
                    country={country}
                    state={state}
                    postalCode={postalCode}
                    city={city}
                    line1={line1}
                    line2={line2}
                    phone={phone}
                    setPhone={setPhone}
                    isPhoneInvalid={isPhoneTouched && !isPhoneValid}
                    onPhoneFocus={() => setIsPhoneTouched(true)}
                  />
                  <Label className={styles.label}>
                    <Input
                      checked={saveProfileAddress}
                      className={styles.input}
                      onChange={e => setSaveProfileAddress(e.target.checked)}
                      type="checkbox"
                    />
                    Save as shipping address
                  </Label>
                  <Label className={styles.label}>
                    <Input
                      checked={saveBillingAddress}
                      className={styles.input}
                      onChange={e => setSaveBillingAddress(e.target.checked)}
                      type="checkbox"
                    />
                    Save as billing address
                  </Label>
                  <GiftInfo
                    isGift={isGift}
                    setMessage={setMessage}
                    changeGift={handleChangeGift}
                  />
                </TabPane>
              </TabContent>

              <div
                className={classNames(
                  styles.buttons,
                  activeTab === 1 && styles.firstTab,
                  activeTab === 3 && styles.thirdTab
                )}
              >
                {activeTab !== 1 && (
                  <Button
                    onClick={() => {
                      toggleTab(activeTab - 1);
                    }}
                    className="btn btn-dark mb-1"
                  >
                    Previous
                  </Button>
                )}
                {activeTab !== 3 && (
                  <Button
                    className="btn btn-dark"
                    disabled={isNextDisabled}
                    onClick={handleNextClick}
                  >
                    {buttonText}
                  </Button>
                )}
                {activeTab === 3 && (
                  <div className={styles.complete}>
                    <Button
                      disabled={disabledSubmit}
                      onClick={handleSubmit}
                      className="btn btn-dark"
                    >
                      Complete subscription creation
                    </Button>
                    {disabledSubmit && (
                      <>
                        <i
                          id="sumbitSubscription"
                          className="ml-1 ri-question-line auto-custom-input-icon"
                        />
                        <UncontrolledTooltip
                          placement="top"
                          target="sumbitSubscription"
                        >
                          {getErrorMessage()}
                        </UncontrolledTooltip>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default SubscriptionCreate;
