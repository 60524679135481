const FEE_UNITS = Object.freeze({
  PERCENT: 'Percents',
  MONEY: 'Money',
});

export const getTotalTax = (items, discount, state) => {
  return items.reduce(
    (previous, current) =>
      previous + calculateProductTax(current, discount, state),
    0
  );
};

const calculateProductTax = (product, discount, state) => {
  const { number } = product;
  const productPrice = product?.product_price ?? product?.price ?? 0;
  return product.fees.reduce((previous, current) => {
    if (current.title === 'PST' && (state === 'Alberta' || state === 'AB')) {
      return previous;
    }
    if (current.units === FEE_UNITS.PERCENT) {
      return (
        previous +
        parseFloat(
          (
            productPrice *
            (1 - discount / 100) *
            number *
            (current.amount / 100)
          ).toFixed(2)
        )
      );
    }
    return previous + parseFloat((number * current.amount).toFixed(2));
  }, 0);
};
