export const localStorageToken = 'auth-token';

export const perPagePagination = [10, 30, 100];

export const MONTHS = [
  {
    apiValue: '',
    userValue: 'Select a month',
  },
  {
    apiValue: 'JANUARY',
    userValue: 'January',
  },
  {
    apiValue: 'FEBRUARY',
    userValue: 'February',
  },
  {
    apiValue: 'MARCH',
    userValue: 'March',
  },
  {
    apiValue: 'APRIL',
    userValue: 'April',
  },
  {
    apiValue: 'MAY',
    userValue: 'May',
  },
  {
    apiValue: 'JUNE',
    userValue: 'June',
  },
  {
    apiValue: 'JULE',
    userValue: 'Jule',
  },
  {
    apiValue: 'AUGUST',
    userValue: 'August',
  },
  {
    apiValue: 'SEPTEMBER',
    userValue: 'September',
  },
  {
    apiValue: 'OCTOBER',
    userValue: 'October',
  },
  {
    apiValue: 'NOVEMBER',
    userValue: 'November',
  },
  {
    apiValue: 'DECEMBER',
    userValue: 'December',
  },
];

export const HUMAN_ORDER_STATUSES = {
  DRAFT: 'Order Created',
  FULFILLED: 'Order Created',
  WAITING_FOR_APPROVAL: 'Waiting For Approval',
  CUSTOMER_APPROVED: 'Customer Approved',
  MANAGER_APPROVED: 'Manager Approved',
  PAYMENT_ERROR: 'Payment Error',
  PAID_BY_CARD: 'Paid by Card',
  PAID_IN_CASH: 'Paid in Cash',
  PAID_BY_CHEQUE: 'Paid by Cheque',
  PAID_BY_MONEY_TRANSFER: 'Paid by Money Transfer',
  PAID: 'Paid',
  FINISHED: 'Finished',
  SHIPPED: 'Paid',
  SKIPPED: 'Skipped',
  PARTIALLY_RETURNED: 'Partially Returned',
  RETURNED: 'Returned',
};

export const HUMAN_PAYMENT_TYPES = {
  PAID_IN_CASH: 'Cash',
  PAID_BY_CARD: 'Card',
  CARD_ALTERNATIVE: 'Card (via alternative payment processor)',
  PAID_BY_CHEQUE: 'Cheque',
  PAID_BY_MONEY_TRANSFER: 'Money transfer',
};

export const ORDER_TYPES = Object.freeze({
  TastingRoomOrder: 'Tasting Room Order',
  SubscriptionOrder: 'Subscription Order',
  RetailOrder: 'Dashboard order',
  EcommerceOrder: 'Ecommerce Order',
  FoodOrderItem: 'Food Order Item',
  CommercialOrder: 'Commercial Order',
  TastingMenuOrderItem: 'Tasting Menu Order Item',
  ProductOrderItem: 'Product Order Item',
  ProductSampleOrderItem: 'Product Sample Order Item',
});
