import { createSelector } from 'reselect';

export const rootSelector = state => state.user;

export const currentUserSelector = createSelector(
  rootSelector,
  ({ currentUser }) => currentUser
);

export const confirmationUserSelector = createSelector(
  rootSelector,
  ({ confirmationUser }) => confirmationUser
);

export const emailSelector = createSelector(
  rootSelector,
  ({ currentUser }) => currentUser?.email
);

export const isAuthSelector = createSelector(
  rootSelector,
  ({ isAuthenticated }) => isAuthenticated
);

export const isPendingSelector = createSelector(
  rootSelector,
  ({ isPending }) => isPending
);

export const isValidConfirmationTokenSelector = createSelector(
  rootSelector,
  ({ isValidConfirmationToken }) => isValidConfirmationToken
);

export const confirmationTokenSelector = createSelector(
  rootSelector,
  ({ confirmationToken }) => confirmationToken
);

export const tokenSelector = createSelector(rootSelector, ({ token }) => token);

export const recoverPasswordModalSelector = createSelector(
  rootSelector,
  ({ recoverPasswordModal }) => recoverPasswordModal
);

export const addressesSelector = createSelector(
  rootSelector,
  ({ addresses }) => addresses
);

export const addressesPaginationSelector = createSelector(
  rootSelector,
  ({ addressesPagination }) => addressesPagination
);

export const isAddressesPendingSelector = createSelector(
  rootSelector,
  ({ addressesPending }) => addressesPending
);

export const isAddressAddingSelector = createSelector(
  rootSelector,
  ({ isAddressAdding }) => isAddressAdding
);
