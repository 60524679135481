import React, { useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import { useMediaQuery } from 'react-responsive';
import PageWrapper from 'components/PageWrapper';
import Title from 'components/Title';
import ConfirmationModal from 'components/ConfirmationModal';
import {
  ordersSelector,
  ordersPaginationSelector,
} from 'models/orders/selectors';
import { actions as commonActions } from 'models/common/slice';
import { actions as ordersActions } from 'models/orders/slice';
import OrdersTable from '../../components/OrdersTable';
import MobileBlocks from './MobileBlocks';

const PurchaseHistory = ({ title }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const orders = useSelector(ordersSelector);
  const ordersPagination = useSelector(ordersPaginationSelector);
  const fetchOrders = useAction(ordersActions.fetchOrders);

  const [orderIdForSkip, setOrderIdForSkip] = useState(null);
  const [modalDescription, setModalDescription] = useState({});
  const [page, setPage] = useState(ordersPagination?.current_page ?? 1);

  const skipOrder = useAction(ordersActions.skipOrder);
  const setIsOpenConfirmationModal = useAction(
    commonActions.setIsOpenConfirmationModal
  );

  const handleSkipOrder = useCallback(
    id => {
      setIsOpenConfirmationModal();
      setModalDescription({
        title: 'Are you sure you want to skip this  order?',
        submitButtonTitle: 'Skip',
      });
      setOrderIdForSkip(id);
    },
    [setIsOpenConfirmationModal]
  );

  const handleConfirmationModalSubmit = () => {
    if (orderIdForSkip) {
      skipOrder(orderIdForSkip);
      setOrderIdForSkip(null);
    }
  };

  useEffect(() => {
    fetchOrders({
      page,
      sort_field: 'created_at',
    });
  }, [fetchOrders, page]);

  const renderTable = useMemo(() => {
    return (
      <OrdersTable
        orders={orders}
        onSkipOrder={handleSkipOrder}
        pagination={ordersPagination}
        onPageChange={setPage}
      />
    );
  }, [orders, handleSkipOrder, ordersPagination]);

  const renderBlocks = useMemo(
    () => (
      <MobileBlocks
        orders={orders}
        handleSkipOrder={handleSkipOrder}
        pagination={ordersPagination}
        onPageChange={setPage}
      />
    ),
    [orders, handleSkipOrder, ordersPagination]
  );

  const renderedItems = useMemo(() => (isMobile ? renderBlocks : renderTable), [
    isMobile,
    renderBlocks,
    renderTable,
  ]);

  return (
    <PageWrapper title={title}>
      <Title className="mb-5 mt-4" title="Purchase history" />
      <Row className="mt-2">
        <Col md={12}>{renderedItems}</Col>
      </Row>
      <ConfirmationModal
        title={modalDescription?.title}
        submitButtonTitle={modalDescription?.submitButtonTitle}
        onSaveClick={handleConfirmationModalSubmit}
      />
    </PageWrapper>
  );
};

PurchaseHistory.propTypes = {
  title: PropTypes.string,
};

export default PurchaseHistory;
