import React from 'react';

export default props => (
  <svg {...props} width="17px" height="16px" viewBox="0 0 17 16" version="1.1">
    <title>Group 3</title>
    <defs>
      <polygon id="path-1" points="0 0 1920 0 1920 1080 0 1080" />
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Master-App-404" transform="translate(-1014.000000, -674.000000)">
        <g
          id="Group-2"
          transform="translate(566.000000, 382.102942)"
          stroke="#FFFFFF"
          strokeWidth="1.44954128"
        >
          <g id="Group" transform="translate(325.747833, 283.875496)">
            <g id="Group-3" transform="translate(123.211009, 9.095693)">
              <polyline
                id="Rectangle"
                points="0 0 14.4954128 0 14.4954128 14.2337318"
              />
              <line
                x1="13.9777195"
                y1="0.508347565"
                x2="0.517693316"
                y2="13.7253843"
                id="Line-2"
                strokeLinecap="square"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
