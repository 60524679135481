import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import PropTypes from 'prop-types';

const PaginationComponent = ({ pagination, onClick, className }) => {
  const { next_page, prev_page, current_page, total_pages } = pagination;
  return (
    <Pagination className={className} aria-label="Page navigation example">
      <PaginationItem>
        <PaginationLink first onClick={() => onClick?.(1)} />
      </PaginationItem>
      {prev_page > 1 && (
        <PaginationItem disabled>
          <PaginationLink>...</PaginationLink>
        </PaginationItem>
      )}

      {prev_page && (
        <PaginationItem>
          <PaginationLink onClick={() => onClick?.(prev_page)}>
            {prev_page}
          </PaginationLink>
        </PaginationItem>
      )}

      {current_page && (
        <PaginationItem active>
          <PaginationLink onClick={() => onClick?.(current_page)}>
            {current_page}
          </PaginationLink>
        </PaginationItem>
      )}
      {next_page && (
        <PaginationItem>
          <PaginationLink onClick={() => onClick?.(next_page)}>
            {next_page}
          </PaginationLink>
        </PaginationItem>
      )}
      {next_page && total_pages > next_page && (
        <PaginationItem disabled>
          <PaginationLink>...</PaginationLink>
        </PaginationItem>
      )}
      <PaginationItem>
        <PaginationLink last onClick={() => onClick?.(total_pages)} />
      </PaginationItem>
    </Pagination>
  );
};

PaginationComponent.propTypes = {
  pagination: PropTypes.object,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default PaginationComponent;
