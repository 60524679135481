import React from 'react';
import { Button, FormGroup, Input } from 'reactstrap';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import { actions as paymentsActions } from 'models/payments/slice';
import * as paymentsSelectors from 'models/payments/selectors';
import { showErrorMessage } from 'utils/notification';

import s from './Form.scss';

const CheckoutForm = () => {
  const sendPaymentMethod = useAction(paymentsActions.sendPaymentMethod);
  const stripe = useStripe();
  const elements = useElements();
  const intent = useSelector(paymentsSelectors.intentSelector);

  const handleSubmit = async event => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }

    const card = elements.getElement(CardElement);
    const formData = new FormData(event.target);
    const options = {
      name: formData.get('name'),
    };
    const result = await stripe.confirmCardSetup(intent.client_secret, {
      payment_method: {
        card,
        billing_details: {
          ...options,
        },
      },
    });

    if (result.error) {
      showErrorMessage('Error!', result.error.message);
    } else {
      // Send the token to server.
      sendPaymentMethod({ ...result, redirectUrl: '/my-wineclub' });
    }
  };

  return (
    <form className={s.root} onSubmit={handleSubmit}>
      <FormGroup>
        <Input id="name" type="text" name="name" placeholder="Name on Card" />
      </FormGroup>

      <CardElement
        options={{
          hidePostalCode: true,
          style: {
            base: {
              color: '#32325d',
              fontFamily: '"RiformaLL", sans-serif',
              fontSmoothing: 'antialiased',
              fontSize: '14px',
              '::placeholder': {
                color: '#505d69',
              },
            },
            invalid: {
              color: '#fa755a',
              iconColor: '#fa755a',
            },
          },
        }}
      />

      <Button
        className="btn-rounded waves-effect mt-5"
        type="submit"
        disabled={!stripe}
      >
        Add new card
      </Button>
    </form>
  );
};

export default CheckoutForm;
