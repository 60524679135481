import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styles from './Subscription.scss';
import { Col, Row } from 'reactstrap';
import classNames from 'classnames';
import { formatDate } from 'utils/formatDate';

const Subscription = ({ date, title, status, address }) => {
  const isCancelled = status === 'CANCELLED';
  return (
    <div className={classNames(styles.block, 'mt-4 mb-4')}>
      <Row xl="3" lg="1" md="1" sm="1" xs="1" className="mb-4">
        <Col xl="auto" className={styles.textBlock}>
          <p className={styles.text}>Status: {status}</p>
        </Col>
        <Col xl="auto" className={styles.textBlock}>
          <p className={styles.text}>
            Member Since: {formatDate(date, 'MMMM dd yyyy')}
          </p>
        </Col>
        <Col xl="auto" className={styles.textBlock}>
          <p className={styles.text}>Tier: {isCancelled ? 'None' : title}</p>
        </Col>
        <Col xl="auto" className={styles.textBlock}>
          <p className={styles.text}>
            Subscription address: {(!isCancelled && address) || '-'}
          </p>
        </Col>
      </Row>
    </div>
  );
};

Subscription.propTypes = {
  date: PropTypes.string,
  title: PropTypes.string,
  status: PropTypes.string,
  address: PropTypes.string,
};

export default memo(Subscription);
