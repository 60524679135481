import React from 'react';
import PropTypes from 'prop-types';
import styles from './GiftInfo.scss';
import { Col, Input, Label, Row } from 'reactstrap';

const GiftInfo = ({ isGift, setMessage, changeGift }) => {
  return (
    <Row className="mt-2">
      <Col md={12} className="mb-2">
        <Label className={styles.label}>
          <Input
            value={isGift}
            className={styles.input}
            onChange={changeGift}
            type="checkbox"
          />
          Is a gift
        </Label>
      </Col>
      {isGift && (
        <Col md={12} className="mb-4">
          <Label htmlFor="message">Gift message</Label>
          <Input
            id="message"
            type="textarea"
            placeholder="Enter Gift message"
            onChange={event => setMessage(event.target.value)}
          />
        </Col>
      )}
    </Row>
  );
};

GiftInfo.propTypes = {
  isGift: PropTypes.bool,
  changeGift: PropTypes.func,
  setMessage: PropTypes.string,
};

export default GiftInfo;
