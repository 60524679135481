/* eslint-disable react/no-danger */
import React, { useCallback, useEffect, useMemo } from 'react';
import cx from 'classnames';
import modsClasses from 'utils/modsClasses';

import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import { actions as tiersActions } from 'models/tiers/slice';

import { Button, Col, Row } from 'reactstrap';

import s from './Content.scss';
import {
  selectedTierIdSelector,
  tiersSelector,
  isPendingSelector,
  tiersPaginationSelector,
} from '../../../../models/tiers/selectors';
import TiersCard from '../../../../components/TiersCard';
import { currentUserSelector } from '../../../../models/user/selectors';

const Content = () => {
  const classes = modsClasses(s, { colorScheme: 'white' });

  const user = useSelector(currentUserSelector);
  const tiers = useSelector(tiersSelector);
  const isPending = useSelector(isPendingSelector);
  const pagination = useSelector(tiersPaginationSelector);
  const selectedTierId = useSelector(selectedTierIdSelector);
  const setCurrentTier = useAction(tiersActions.setCurrentTier);
  const fetchTiers = useAction(tiersActions.fetchTiers);

  const lastTier = useMemo(() => user?.last_selected_tier, [user]);

  const tiersList = useMemo(() => {
    return lastTier ? [lastTier] : tiers;
  }, [tiers, lastTier]);

  useEffect(() => {
    if (!user?.is_imported_customer) {
      fetchTiers({ not_subscripted: true });
    }
  }, [fetchTiers, user]);

  useEffect(() => {
    if (lastTier) {
      setCurrentTier(lastTier.id);
    }
  }, [lastTier]);

  const onLoadMore = useCallback(() => {
    if (pagination?.next_page) {
      fetchTiers({ page: pagination.next_page });
    }
  }, [pagination, fetchTiers]);

  const handleTierClick = id => {
    setCurrentTier(id);
  };

  return (
    <div className={cx(s.container, isPending && s.containerLoading, classes)}>
      <Row>
        {tiersList.map(item => (
          <Col key={item.id.toString()} md={6} xs={12}>
            <TiersCard
              {...item}
              onClick={() => handleTierClick(item.id)}
              isActive={selectedTierId === item.id}
            />
          </Col>
        ))}
      </Row>
      {pagination?.next_page && !user?.is_imported_customer && !lastTier && (
        <Row className="justify-content-center my-3">
          <Button onClick={onLoadMore} className={s.button}>
            Load More
          </Button>
        </Row>
      )}
    </div>
  );
};

export default Content;
