import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';

import Title from 'components/Title';
import Preloader from 'components/Preloader';

import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import { actions as tiersActions } from 'models/tiers/slice';
import * as tiersSelectors from 'models/tiers/selectors';
import { actions as obligationsActions } from 'models/obligations/slice';

import Content from './Content';
import Sidebar from './Sidebar';

const Tiers = ({ colorScheme }) => {
  const [tierID, setTierID] = useState(tier?.id);
  const createObligation = useAction(obligationsActions.createObligation);
  const tiersIsPending = useSelector(tiersSelectors.isPendingSelector);
  const tiers = useSelector(tiersSelectors.tiersSelector);
  const tier = useSelector(tiersSelectors.tierSelector);
  const fetchTiers = useAction(tiersActions.fetchTiers);
  // const setCurrentTier = useAction(tiersActions.setCurrentTier);

  useEffect(() => {
    fetchTiers();
  }, [fetchTiers]);

  useEffect(() => {
    setTierID(tier?.id);
  }, [tier]);

  const handleSubmit = useCallback(() => {
    createObligation({
      tier_id: tierID,
    });
  }, [createObligation, tierID]);

  if (tiersIsPending) return <Preloader />;
  return (
    <>
      <Title className="mb-5 mt-4" title="Select your subscription" />
      <Row>
        <Col xl={3} lg={12} sm={12}>
          <Sidebar
            tier={tier}
            handleSubmit={handleSubmit}
            disabled={tier?.id === tierID}
          />
        </Col>

        <Col xl={9} lg={12} sm={12}>
          <Content
            colorScheme={colorScheme}
            tiers={tiers}
            currentTierID={tierID}
            setCurrentTier={setTierID}
          />
        </Col>
      </Row>
    </>
  );
};

Tiers.propTypes = {
  colorScheme: PropTypes.string,
};

Tiers.defaultProps = {
  colorScheme: 'white',
};

export default Tiers;
