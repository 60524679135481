import { createSelector } from 'reselect';

export const rootSelector = state => state.tiers;

export const tiersSelector = createSelector(rootSelector, ({ tiers }) => tiers);
export const tierSelector = createSelector(
  rootSelector,
  ({ currentTier }) => currentTier
);

export const isPendingSelector = createSelector(
  rootSelector,
  ({ isPending }) => isPending
);

export const tiersPaginationSelector = createSelector(
  rootSelector,
  ({ tiersPagination }) => tiersPagination
);

export const selectedTierIdSelector = createSelector(
  rootSelector,
  ({ selectedTierId }) => selectedTierId
);

export const lastTierSelector = createSelector(
  rootSelector,
  ({ lastTier }) => lastTier
);
