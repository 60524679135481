import React, { useMemo } from 'react';
import styles from './PaymentCard.scss';
import VisaIcon from '../Icon/icons/VisaIcon';
import MasterCardIcon from '../Icon/icons/MasterCardIcon';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const PaymentCard = ({
  brand,
  name,
  lastDigits,
  isActive,
  isSelected,
  onClick,
  month,
  year,
  className,
}) => {
  const brandImageTypes = {
    visa: <VisaIcon />,
    mastercard: <MasterCardIcon />,
  };

  const BrandIcon = () => useMemo(() => brandImageTypes[brand] || <div />, []);

  return (
    <button
      className={classNames(
        styles.container,
        // eslint-disable-next-line no-nested-ternary
        isActive
          ? styles.activeContainer
          : isSelected
          ? styles.selectedContainer
          : '',
        className
      )}
      onClick={onClick}
    >
      <p className={styles.title}>Payment method</p>
      <div className={styles.content}>
        <div className={styles.numbers}>
          <p className={styles.numberDot}>&bull;&bull;&bull;&bull;</p>
          <p className={styles.numberDot}>&bull;&bull;&bull;&bull;</p>
          <p className={styles.numberDot}>&bull;&bull;&bull;&bull;</p>
          <p className={styles.digits}>{lastDigits}</p>
        </div>
        <p className={styles.name}>{name}</p>
        <p className={styles.date}>
          {month}/{year}
        </p>
      </div>
      <div className={styles.brand}>
        <BrandIcon />
      </div>
    </button>
  );
};

PaymentCard.propTypes = {
  brand: PropTypes.oneOf(['visa', 'mastercard']).isRequired,
  name: PropTypes.string.isRequired,
  lastDigits: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
};
export default PaymentCard;
