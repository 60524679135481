import axios from 'axios';
import { put, select } from 'redux-saga/effects';
import { tokenSelector } from 'models/user/selectors';
import config from 'config';

import { showSuccessMessage, showErrorMessage } from 'utils/notification';
import { push } from 'connected-react-router';

const NETWORK_SYSTEM_ERROR = 'Network Error';
const NETWORK_SYSTEM_ERROR_TIMEOUT = 'timeout of 0ms exceeded';

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

function* axiosWrapper(params) {
  return yield axios(params).then(response => response.data);
}

// eslint-disable-next-line consistent-return
export default function*({
  action,
  method = 'get',
  url,
  data,
  params,
  successNavigateTo,
  failureNavigateTo,
  headers = {},
  token,
  successMessage,
  failureMessage,
  isRequestWithoutToken,
  withResult,
}) {
  const { payload } = action;
  try {
    const totalHeaders = {
      ...defaultHeaders,
      ...headers,
    };
    if (!isRequestWithoutToken) {
      const authToken = yield select(tokenSelector);
      const _token = token || authToken;
      if (_token) {
        totalHeaders.authorization = `Token ${_token}`;
      }
    }

    const json = yield axiosWrapper({
      baseURL: config.baseUrl,
      url,
      method,
      headers: totalHeaders,
      params,
      data,
    });

    if (successNavigateTo) {
      yield put(push(successNavigateTo));
    }

    if (successMessage) {
      showSuccessMessage('Success!', successMessage);
    }

    if (withResult) return json;

    yield put({
      type: `${action.type}Success`,
      payload: {
        data: json,
        inResponseTo: payload,
      },
    });
  } catch ({ message, response = {} }) {
    const isNetworkError =
      message === NETWORK_SYSTEM_ERROR ||
      message === NETWORK_SYSTEM_ERROR_TIMEOUT;

    if (failureMessage) {
      showErrorMessage('Error!', failureMessage);
    }

    if (isNetworkError) {
      showErrorMessage('Error!', NETWORK_SYSTEM_ERROR);
    } else if (response) {
      const { status } = response;
      if (status >= 500) {
        showErrorMessage(
          'Error!',
          `It was returned with status code ${response?.status}`
        );
      } else if (status === 401) {
        showErrorMessage('Unauthorized!', 'please sign in');
      } else if (status === 400) {
        showErrorMessage(
          'Error!',
          response.data?.city?.[0] ||
            response.data?.id?.[0] ||
            response.data?.country?.[0] ||
            response.data?.line1?.[0] ||
            response.data?.line2?.[0] ||
            response.data?.state?.[0] ||
            response.data?.postal_code?.[0] ||
            response.data?.token?.[0] ||
            response.data?.tier_id?.[0] ||
            response.data?.warehouse_id?.[0] ||
            response.data?.email?.[0] ||
            'Bad request'
        );
      } else if (status === 404) {
        showErrorMessage('Error!', 'Requested resource cannot be found');
      }
    }

    yield put({
      type: `${action.type}Failure`,
      payload: {
        err: message,
        isNetworkError,
        response: response.data,
        inResponseTo: payload,
      },
    });

    if (failureNavigateTo) {
      yield put(push(failureNavigateTo));
    }
  }
}
