import React from 'react';
import PropTypes from 'prop-types';

const AuthLayout = ({ children }) => <>{children}</>;

AuthLayout.propTypes = {
  children: PropTypes.any,
};

export default AuthLayout;
