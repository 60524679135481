import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';

import manageBodyClass from 'utils/manageBodyClass';
import Sidebar from './Sidebar';
import Header from './Header';
import useSelector from '../../../hooks/useSelector';
import { isPendingSelector } from '../../../models/user/selectors';
import Preloader from '../../Preloader';
import { useLocation } from 'react-router-dom';

const MainLayout = ({ children, leftSideBarTheme, isMobile }) => {
  const [isCondensedMenu, setIsCondensedMenu] = useState(false);
  const userLoading = useSelector(isPendingSelector);

  const toggleMenuVisibility = value => {
    manageBodyClass('sidebar-enable', value ? 'add' : 'remove');
    manageBodyClass('vertical-collpsed', value ? 'add' : 'remove');
    setIsCondensedMenu(value);
  };

  const toggleMenuCallback = useCallback(() => {
    toggleMenuVisibility(!isCondensedMenu);
  }, [isCondensedMenu]);

  const location = useLocation();

  useEffect(() => {
    toggleMenuVisibility(false);
  }, [location]);

  if (userLoading) {
    return <Preloader />;
  }

  return (
    <>
      <div id="layout-wrapper">
        <Header toggleMenuCallback={toggleMenuCallback} />
        <Sidebar theme={leftSideBarTheme} isMobile={isMobile} />
        <div className="main-content">
          <div className="page-content">
            <Container fluid>{children}</Container>
          </div>
        </div>
      </div>
    </>
  );
};

MainLayout.propTypes = {
  children: PropTypes.any,
  leftSideBarTheme: PropTypes.string,
  isMobile: PropTypes.bool,
};

export default MainLayout;
