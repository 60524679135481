import React, { useEffect } from 'react';

import manageBodyClass from 'utils/manageBodyClass';

const Preloader = () => {
  useEffect(() => {
    manageBodyClass('overflow-hidden', 'add');

    return () => manageBodyClass('overflow-hidden', 'remove');
  }, []);

  return (
    <div id="preloader">
      <div id="status">
        <div className="spinner">
          <i className="ri-loader-line spin-icon" />
        </div>
      </div>
    </div>
  );
};

export default Preloader;
