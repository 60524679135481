import { takeLatest, all } from 'redux-saga/effects';
import api from 'api';
import { actions } from './slice';

export function* fetchShipmentRatesSaga(action) {
  const { id } = action.payload;

  yield api({
    action,
    method: 'get',
    url: `/orders/${id}/get_shipment_rates`,
  });
}

export default function*() {
  yield all([takeLatest(actions.fetchShipmentRates, fetchShipmentRatesSaga)]);
}
