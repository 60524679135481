import PropTypes from 'prop-types';

export const ControlledFieldProps = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string,
  control: PropTypes.any.isRequired,
  errors: PropTypes.any,
  rules: PropTypes.object,
  onChange: PropTypes.func,
  className: PropTypes.string,
  defaultValue: PropTypes.string,
};
