import cx from 'classnames';
import React, { PureComponent } from 'react';
import { any, string, oneOf, func } from 'prop-types';

import modsClasses from 'utils/modsClasses';

import Logo from './icons/Logo';
import Icon404 from './icons/404';
import DiagonalUpArrow from './icons/DiagonalUpArrow';

import styles from './Icon.scss';

function getIcon(name) {
  switch (name) {
    case 'logo':
      return Logo;
    case 'Icon404':
      return Icon404;
    case 'DiagonalUpArrow':
      return DiagonalUpArrow;
    default:
      return null;
  }
}

class Icon extends PureComponent {
  handleClick = () => {
    const { onClick, data } = this.props;

    if (onClick) onClick(data);
  };

  render() {
    const {
      className,
      direction,
      fill,
      name,
      size,
      color,
      hover,
      width,
      height,
      onClick,
    } = this.props;

    const iconSize = size ? { width: size, height: size } : { width, height };
    const SVGIcon = getIcon(name);
    const classes = modsClasses(styles, { color, hover, direction });

    return (
      <SVGIcon
        className={cx(styles.root, className, classes, {
          [styles.interactive]: onClick,
        })}
        fill={fill}
        {...this.props}
        {...iconSize}
        onClick={this.handleClick}
      />
    );
  }
}

Icon.propTypes = {
  className: string,
  data: any,
  fill: string,
  size: string,
  width: string,
  height: string,
  direction: string,
  name: string.isRequired,
  hover: oneOf(['white']),
  color: oneOf(['black', 'white', 'none']),
  onClick: func,
};

Icon.defaultProps = {
  color: 'black',
};

export default Icon;
