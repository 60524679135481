import TiersNew from 'pages/TiersNew';
import Dashboard from 'pages/Dashboard';
import OrderShow from 'pages/OrderShow';
import CardsShow from 'pages/CardsShow';
import CardCreate from 'pages/CardCreate';
import AddressShow from 'pages/AddressShow';
import AddressEdit from 'pages/AddressEdit';
import PurchaseHistory from 'pages/PurchaseHistory';
import SubscriptionCreate from 'pages/SubscriptionCreate';
import EditWineSelection from 'pages/EditWineSelection';
import SubscriptionDetails from 'pages/SubscriptionDetails';
import SubscriptionCheckout from 'pages/SubscriptionCheckout';

import Login from 'pages/Auth/Login';
import Register from 'pages/Auth/Register';
import RegisterComplete from 'pages/Auth/RegisterComplete';
import ForgotPassword from 'pages/Auth/ForgotPassword';
import ResetPassword from 'pages/Auth/ResetPassword';
import MyWineClub from './pages/MyWineClub';
import OptionsWineSelection from './pages/OptionsWineSelection';
import WineSelection from './pages/WineSelection';
import CreateWineSelection from './pages/CreateWineSelection';
import JoinWineClub from './pages/JoinWineClub';

export default [
  {
    path: '/my-wineclub',
    exact: true,
    component: MyWineClub,
    title: 'My Wine Club',
    layout: 'main',
  },
  {
    path: '/cards',
    exact: true,
    component: CardsShow,
    title: 'Cards',
    layout: 'main',
  },
  {
    path: '/card/create',
    exact: true,
    component: CardCreate,
    title: 'Card Create',
    layout: 'main',
  },
  {
    path: '/address',
    exact: true,
    component: AddressShow,
    title: 'Address',
    layout: 'main',
  },
  {
    path: '/address/edit',
    exact: true,
    component: AddressEdit,
    title: 'Address Edit',
    layout: 'main',
  },
  {
    path: '/order_old/:orderID',
    exact: true,
    component: OrderShow,
    title: 'Order',
    layout: 'main',
  },
  {
    path: '/order/:orderID',
    exact: true,
    component: SubscriptionDetails,
    title: 'Order details',
    layout: 'main',
  },
  {
    path: '/purchase_history',
    exact: true,
    component: PurchaseHistory,
    title: 'Purchase History',
    layout: 'main',
  },
  {
    path: '/wine-selection',
    exact: true,
    component: WineSelection,
    title: 'Wine Selection',
    layout: 'main',
  },
  {
    path: '/wine-selection/retail-order',
    exact: true,
    component: CreateWineSelection,
    title: 'Wine Selection',
    layout: 'main',
  },
  {
    path: '/wine-selection/:orderID/edit',
    exact: true,
    component: EditWineSelection,
    title: 'Wine Selection',
    layout: 'main',
  },
  {
    path: '/wine-selection/edit',
    exact: true,
    component: EditWineSelection,
    title: 'Wine Selection',
    layout: 'main',
  },
  {
    path: '/wine-selection/select-option',
    exact: true,
    component: OptionsWineSelection,
    title: 'Wine Selection',
    layout: 'main',
  },
  {
    path: '/wine-selection/approve',
    exact: true,
    component: SubscriptionCheckout,
    title: 'Checkout',
    layout: 'main',
  },
  {
    path: '/wine-selection/:orderID/approve',
    exact: true,
    component: SubscriptionCheckout,
    title: 'Checkout',
    layout: 'main',
  },
  {
    path: `/orders/:orderID/actions/:action`,
    exact: true,
    component: OrderShow,
    title: 'Order',
    layout: 'main',
  },
  // {
  //   path: '/obligations',
  //   exact: true,
  //   component: Subscriptions,
  //   title: 'Subscriptions',
  //   layout: 'main',
  // },
  {
    path: '/subscription-create',
    exact: true,
    component: SubscriptionCreate,
    title: 'Create Subscription',
    layout: 'main',
  },

  {
    path: '/dashboard',
    exact: true,
    component: Dashboard,
    title: 'Dashboard',
    layout: 'main',
  },
  {
    path: '/tiers/',
    exact: true,
    component: TiersNew,
    title: 'Tiers',
    layout: 'main',
  },
  {
    path: '/login',
    exact: true,
    component: Login,
    title: 'Login',
    layout: 'auth',
  },
  {
    // path: '/wineclubs/:wineclubID/email_confirmation/:confirmationToken',
    path: '/register',
    exact: true,
    component: Register,
    title: 'Register',
    layout: 'auth',
  },
  {
    path: '/wineclubs/:wineclubID/:registerType/:confirmationToken',
    exact: true,
    component: RegisterComplete,
    title: 'Complete Registration',
    layout: 'auth',
  },
  {
    path: '/forgot-password',
    exact: true,
    component: ForgotPassword,
    title: 'Forgot password',
    layout: 'auth',
  },
  {
    path: '/reset-password/:token',
    exact: true,
    component: ResetPassword,
    title: 'Reset password',
    layout: 'auth',
  },
  {
    path: '/join-wineclub',
    exact: true,
    component: JoinWineClub,
    title: 'Join the Wine Club',
    layout: 'auth',
  },
];
