import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import PageWrapper from 'components/PageWrapper';
import Preloader from 'components/Preloader';
import {
  orderSelector,
  orderRefundItemsSelector,
  isPendingSelector as ordersIsPendingSelector,
  isPendingRefundOrderSelector as orderRefundIsPendingSelector,
} from 'models/orders/selectors';
import { actions as ordersActions } from 'models/orders/slice';
import PaidDetails from './PaidDetails';
import OrderRefundTable from './OrderRefundTable';
import { Col, Row } from 'reactstrap';

import styles from './SubscriptionDetails.scss';

const SubscriptionDetails = ({ title }) => {
  const { orderID } = useParams();

  const fetchOrder = useAction(ordersActions.fetchOrder);
  const fetchOrderRefund = useAction(ordersActions.orderRefund);
  const refundItems = useSelector(orderRefundItemsSelector);
  const order = useSelector(orderSelector);
  const orderIsPending = useSelector(ordersIsPendingSelector);
  const orderRefundIsPending = useSelector(orderRefundIsPendingSelector);

  useEffect(() => {
    fetchOrder(orderID);
    fetchOrderRefund({ orderID });
  }, [fetchOrder, fetchOrderRefund, orderID]);

  if (orderIsPending || orderRefundIsPending) return <Preloader />;

  if (isEmpty(order))
    return (
      <PageWrapper>
        <h1>Order №{orderID} not found</h1>
      </PageWrapper>
    );

  if (order?.status === 'Draft') {
    return (
      <PageWrapper>
        <h1>Order №{orderID} has Draft status</h1>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper title={title}>
      <PaidDetails />
      {!!refundItems.length && (
        <div className="mt-4">
          <Row>
            <Col md={12}>
              <h3 className={styles.title}>Refunded Order Items</h3>
            </Col>
          </Row>
          {refundItems.map(item => (
            <OrderRefundTable
              key={item.id}
              className={styles.refundTable}
              data={item}
            />
          ))}
        </div>
      )}
    </PageWrapper>
  );
};

SubscriptionDetails.propTypes = { title: PropTypes.string };

export default SubscriptionDetails;
