import { takeLatest, all, put } from 'redux-saga/effects';
import * as queryString from 'query-string';

import api from 'api';

import { actions as paymentsActions } from '../payments/slice';
import { actions } from './slice';

export function* fetchWineclubsSaga(action) {
  const stringified = queryString.stringify(action.payload);
  const url = stringified ? `/wineclubs?${stringified}` : '/wineclubs';

  yield api({
    action,
    method: 'get',
    url,
  });
}

export function* fetchWineclubSaga(action) {
  yield api({
    action,
    method: 'get',
    url: `/wineclubs/${action.payload}`,
  });
}

export function* fetchPaymentInfoSaga(action) {
  yield api({
    action,
    method: 'get',
    url: `wineclubs/current/payment_info`,
  });
}

function* fetchPaymentInfoSuccessSaga() {
  yield put({ type: paymentsActions.setupIntent });
}

function* fetchCurrentWineclubSaga(action) {
  yield api({
    action,
    url: '/wineclubs/current',
    method: 'GET',
  });
}

function* joinWineClubSaga(action) {
  yield api({
    action,
    url: '/wineclubs/join',
    method: 'POST',
    data: action.payload,
    successMessage: 'Email successfully sent!',
    successNavigateTo: '/login',
  });
}

export default function*() {
  yield all([
    takeLatest(actions.fetchWineclub, fetchWineclubSaga),
    takeLatest(actions.fetchWineclubs, fetchWineclubsSaga),
    takeLatest(actions.fetchPaymentInfo, fetchPaymentInfoSaga),
    takeLatest(actions.fetchPaymentInfoSuccess, fetchPaymentInfoSuccessSaga),
    takeLatest(actions.fetchCurrentWineclub, fetchCurrentWineclubSaga),
    takeLatest(actions.joinWineClub, joinWineClubSaga),
  ]);
}
