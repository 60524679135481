import React from 'react';
import PropTypes from 'prop-types';
import useApiRequest from 'hooks/useApiRequest';
import { selectStyles } from 'constants/selectStyles';
import { AsyncPaginate } from 'react-select-async-paginate';
import { getPreparedAddress } from 'utils/getPreparedAddress';

const getNormalValue = profile => {
  return {
    ...profile,
    value: profile.id,
    label: `${getPreparedAddress(profile)} (phone: ${profile.phone})`,
  };
};

const AddressBookPaginatedField = ({
  onChange,
  parameters,
  defaultValue,
  value,
  ...props
}) => {
  const apiRequest = useApiRequest();

  const fetchAddresses = async ({ page = 1 }) => {
    return apiRequest({
      url: `/customers/profiles?page=${page}`,
    });
  };

  const loadAddresses = async (search, loadedOptions, { page }) => {
    try {
      const response = await fetchAddresses({ page });
      const options = response?.results.map(item => ({
        value: item.id,
        label: `${getPreparedAddress(item)} (phone: ${item.phone})`,
        ...item,
      }));
      return {
        options,
        hasMore: response?.pagination?.next_page,
        additional: { page: page + 1 },
      };
    } catch (error) {
      return {
        options: [],
        hasMore: true,
        additional: { page },
      };
    }
  };

  return (
    <AsyncPaginate
      {...props}
      name="address-book"
      isSearchable={false}
      placeholder="Select address..."
      additional={{
        page: 1,
      }}
      defaultValue={value ? getNormalValue(value) : null}
      loadOptions={loadAddresses}
      onChange={onChange}
      styles={selectStyles}
    />
  );
};

AddressBookPaginatedField.propTypes = {
  onChange: PropTypes.func,
  isMulti: PropTypes.bool,
  defaultValue: PropTypes.object,
  value: PropTypes.any,
  parameters: PropTypes.object,
};

export default AddressBookPaginatedField;
