export const getPreparedOrderItems = orderItems => {
  return orderItems.reduce(
    (previous, current) => ({
      ...previous,
      [current.product.id]: {
        ...current,
        fees: getPreparedProductFees(current?.applied_fees),
        id: current.product.id,
      },
    }),
    {}
  );
};

const getPreparedProductFees = fees => {
  return Object.entries(fees).map(([key, value]) => ({
    title: key,
    ...value,
  }));
};
