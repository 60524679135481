export const getPreparedAddress = value => {
  const line1Value = value?.line1 ? ` ${value?.line1}` : '';
  const postalCodeValue =
    value?.postalCode || value?.postal_code
      ? ` ${value?.postalCode || value?.postal_code}`
      : '';
  const cityValue = value?.city ? ` ${value?.city}` : '';
  const stateValue = value?.state ? `, ${value?.state}` : '';
  const countryValue = value?.country ? `, ${value?.country}` : '';
  return `${line1Value}${postalCodeValue}${cityValue}${stateValue}${countryValue}`;
};
