import React from 'react';
import classNames from 'classnames';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { orderSelector } from 'models/orders/selectors';
import useSelector from 'hooks/useSelector';
import Title from 'components/Title';
import styles from './PaidDetails.scss';
import PropTypes from 'prop-types';
import { currentUserSelector } from '../../../models/user/selectors';
import OrderInfo from '../../../components/OrderInfo';
import { HUMAN_PAYMENT_TYPES } from 'constants';

export const SHIPPING_STATUSES = Object.freeze({
  UNKNOWN: 'Unknown',
  PRE_TRANSIT: 'Pre-Transit',
  IN_TRANSIT: 'In Transit',
  OUT_FOR_DELIVERY: 'Out For Delivery',
  DELIVERED: 'Delivered',
  AVAILABLE_FOR_PICKUP: 'Available For Pickup',
  RETURN_TO_SENDER: 'Return To Sender',
  FAILURE: 'Failure',
  CANCELLED: 'Cancelled',
  ERROR: 'Error',
  RETURNED: 'Returned',
});

const InfoItem = ({ title, value }) => (
  <div className={classNames(styles.blockNoBorder, 'mb-3')}>
    <h3 className={styles.blockTitle}>{title}</h3>
    <p className={styles.blockContent}>{value}</p>
  </div>
);

const PaidDetails = () => {
  const order = useSelector(orderSelector);
  const user = useSelector(currentUserSelector);
  const createDate = order.created_at
    ? format(Date.parse(order.created_at), 'yyyy-MM-dd')
    : null;

  const orderShipment = [...(order?.shipping_info?.shipments ?? [])].sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  )[0];

  return (
    <>
      <Row className="mb-2 mt-4">
        <Col md={9}>
          <Title title={`Order ${order.id}`} />

          {createDate && (
            <p className={styles.caption}>
              Thank you for your order, placed on {createDate}
            </p>
          )}
        </Col>
        <Col md={3} className={styles.backToOrders}>
          <Link to="/purchase_history">Back to orders</Link>
        </Col>
      </Row>

      <Row>
        <OrderInfo className={styles.orderInfo} order={order} />
        <Col className="ml-5" md={5}>
          <div className="mt-4">
            <h1>Order info</h1>
            <div className={classNames(styles.blockNoBorder, 'mb-3')}>
              <InfoItem
                title="Payment type"
                value={HUMAN_PAYMENT_TYPES[order?.last_payment?.type]}
              />
            </div>
          </div>
          {order.shipping_info && (
            <div className="mt-4">
              <h1>Shipment info</h1>
              {orderShipment && (
                <>
                  <div className={classNames(styles.blockNoBorder, 'mb-3')}>
                    <p className={styles.blockTitle}>Shipping address</p>
                    <p className={styles.blockContent}>
                      {order.shipping_info.line2} {order.shipping_info.line1}{' '}
                      {order.shipping_info.country}
                    </p>
                    <p className={styles.blockContent}>
                      {order.shipping_info.state}, {order.shipping_info.city}
                    </p>
                    <p className={styles.blockContent}>
                      {order.shipping_info.postal_code}
                    </p>
                  </div>

                  {user?.phone && (
                    <div className="mt-2">
                      <p className={styles.blockTitle}>Phone</p>
                      <a
                        href={`tel:${user.phone}`}
                        className={styles.blockContent}
                      >
                        {user.phone}
                      </a>
                    </div>
                  )}
                  {!orderShipment.parcels?.length ? (
                    <div className="mt-2">
                      <p className={styles.blockTitle}>Parcel</p>
                      <div className={styles.blockContent}>
                        <div>
                          Status: {SHIPPING_STATUSES[orderShipment.status]}{' '}
                        </div>
                        <div>
                          {' '}
                          Track number:{' '}
                          <a
                            href={orderShipment.public_url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {orderShipment.tracking_number}
                          </a>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="mt-2">
                        <p className={styles.blockTitle}>Shipment status</p>
                        <p className={styles.blockContent}>
                          {SHIPPING_STATUSES[orderShipment.status]}
                        </p>
                      </div>
                      {orderShipment.parcels.map((item, index) => (
                        <div className="mt-2">
                          <p className={styles.blockTitle}>
                            Parcel #{index + 1}
                          </p>
                          <div className={styles.blockContent}>
                            <div>Status: {SHIPPING_STATUSES[item.status]} </div>
                            <div>
                              {' '}
                              Track number:{' '}
                              <a
                                href={item.public_url}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {item.tracking_number}
                              </a>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </>
              )}
              {!orderShipment && (
                <div className="mt-2">
                  <p className={styles.blockTitle}>Pickup</p>
                </div>
              )}
            </div>
          )}
          {order?.obligation && order?.resourcetype === 'SubscriptionOrder' && (
            <div className="mt-4">
              <h1>Subscription info</h1>
              <div className={classNames(styles.blockNoBorder, 'mb-3')}>
                <InfoItem title="Title" value={order.obligation.tier.title} />
                <InfoItem
                  title="Period"
                  value={order.obligation.tier.period_in_months}
                />
                <InfoItem
                  title="Capacity"
                  value={order.obligation.tier.capacity}
                />
              </div>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

InfoItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default PaidDetails;
