import { takeLatest, all } from 'redux-saga/effects';
import { stringify } from 'query-string';

import api from 'api';

import { actions } from './slice';

export function* fetchTiersSaga(action) {
  const parameters = stringify(action.payload);
  yield api({
    action,
    method: 'get',
    url: `/tiers?${parameters}`,
  });
}

export default function*() {
  yield all([takeLatest(actions.fetchTiers, fetchTiersSaga)]);
}
