import React from 'react';

import useSelector from 'hooks/useSelector';

import Title from 'components/Title';

import * as userSelectors from 'models/user/selectors';

import styles from './AddressShow.scss';
import InfoCard from '../../components/InfoCard';
import { getPreparedAddress } from '../../utils/getPreparedAddress';
import { Link } from 'react-router-dom';

const prepareValue = value =>
  value || <span className={styles.errorText}>Missing</span>;

const AddressShow = () => {
  const currentUser = useSelector(userSelectors.currentUserSelector);
  return (
    <div>
      <Title
        title="Manage your Account"
        subtitle={
          <Link to="/address/edit">
            Update your Therapy Wine Subscription account info.
          </Link>
        }
        className="mb-5 mt-4"
      />

      <div className={styles.wrapper}>
        <InfoCard
          className={styles.card}
          title="User"
          value={`${currentUser?.first_name} ${currentUser.last_name}`}
        />
        <InfoCard
          className={styles.card}
          title="Email"
          value={prepareValue(currentUser?.email)}
        />
        <InfoCard
          className={styles.card}
          title="Phone"
          value={prepareValue(currentUser?.phone)}
        />
        <InfoCard
          className={styles.card}
          title="Address"
          value={
            currentUser.address ? (
              <div className={styles.address}>
                <span>{currentUser.address.line2 ?? ''}</span>
                <span>{currentUser.address.line1 ?? ''}</span>
                <span>
                  {getPreparedAddress({ ...currentUser.address, line1: null })}
                </span>
              </div>
            ) : (
              <span className={styles.errorText}>Missing</span>
            )
          }
        />
      </div>
    </div>
  );
};

// AddressShow.propTypes = {};

export default AddressShow;
