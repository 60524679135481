import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { Row, Col, NavLink } from 'reactstrap';

import { currentUserSelector } from '../../models/user/selectors';
import { actions as orderShipmentRatesActions } from 'models/orderShipmentRates/slice';
import { actions as obligationsActions } from 'models/obligations/slice';
import {
  shipmentRatesSelector,
  isPendingSelector,
} from 'models/orderShipmentRates/selectors';
import {
  isPendingSelector as ordersIsPendingSelector,
  orderSelector,
} from 'models/orders/selectors';
import { actions as ordersActions } from 'models/orders/slice';

import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import Title from 'components/Title';
import Preloader from 'components/Preloader';
import PageWrapper from 'components/PageWrapper';
import ShipmentRatesForm from 'components/ShipmentRatesForm';
import styles from './SubscriptionCheckout.scss';
import OrderInfo from '../../components/OrderInfo';

const getDataForApi = rate => {
  if (rate.price && rate.brand_shipping_company_id && rate.id) {
    return {
      shipping: true,
      shipping_company_id: rate.brand_shipping_company_id,
      shipment_ext_id: rate.shipment_ext_id,
      shipment_rate_ext_id: rate.id,
      shipment_price: rate.price,
      service: rate.service,
      carrier: rate.carrier,
    };
  }

  return {
    shipping: rate.shipping,
  };
};

const notShippingData = {
  id: 'noShipping',
  shipping: false,
};

const SubscriptionEdit = ({ title }) => {
  const { orderID } = useParams();
  const user = useSelector(currentUserSelector);
  const order = useSelector(orderSelector);
  const shipmentRates = useSelector(shipmentRatesSelector);
  const orderPending = useSelector(ordersIsPendingSelector);
  const shipmentRatesPending = useSelector(isPendingSelector);
  const approveOrder = useAction(ordersActions.approveOrder);
  const fetchShipmentRates = useAction(
    orderShipmentRatesActions.fetchShipmentRates
  );
  const fetchOrder = useAction(ordersActions.fetchOrder);

  const confirmObligation = useAction(obligationsActions.confirmObligation);
  const createObligation = useAction(obligationsActions.createObligation);

  const [shipmentRate, setShipmentRate] = useState(null);
  const orderId = useMemo(
    () => user?.current_obligation?.last_unpaid_order_id || order.id || orderID,
    [order.id, orderID, user]
  );

  const handleShipmentRateSelection = () => {
    if (!shipmentRate) return;
    approveOrder({
      id: orderId,
      data: getDataForApi(shipmentRate),
      email: order?.shipping_info?.email || user?.email,
      paymentMethodId: user?.active_payment_method?.id,
      isRetail: order.resourcetype === 'RetailOrder',
    });
    if (
      (user?.subscripted_tier_ids ?? []).length === 1 &&
      user?.current_obligation?.status === 'NEW'
    ) {
      confirmObligation({
        id: user?.current_obligation.id,
        address: order?.shipping_info,
      });
    } else if (
      !(user?.subscripted_tier_ids ?? []).length &&
      user?.last_selected_tier
    ) {
      createObligation({
        ...(order?.shipping_info ?? {}),
        tier_id: user.last_selected_tier.id,
        withoutSuccessNavigation: true,
      });
    }
  };

  useEffect(() => {
    if (user?.active_payment_method) {
      fetchShipmentRates({ id: orderId });
    }
  }, [orderId, fetchShipmentRates]);

  useEffect(() => {
    if (orderId) {
      fetchOrder(orderId);
    }
  }, [fetchOrder, orderId]);

  useEffect(() => {
    if (order?.shipping_info?.shipments?.length > 0) {
      const orderShipment = [...(order?.shipping_info?.shipments ?? [])].sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      )[0];
      if (orderShipment?.status === 'PRE_TRANSIT') {
        setShipmentRate(orderShipment);
      }
    }
  }, [order]);

  const rates = useMemo(() => {
    const initialRates = [...(shipmentRates.rates ?? []), notShippingData];
    const orderShipment = [...(order?.shipping_info?.shipments ?? [])].sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    )[0];
    return orderShipment?.status === 'PRE_TRANSIT'
      ? [orderShipment, ...initialRates]
      : initialRates;
  }, [order, shipmentRates]);

  if (shipmentRatesPending || orderPending) return <Preloader />;

  return (
    <PageWrapper title={title}>
      <Row className="mb-5 mt-4">
        <Col md={9}>
          <Title title={`Checkout for order ${orderId}`} />
        </Col>
        <Col md={3} className="text-right">
          <Link to="/purchase_history">Back to orders</Link>
        </Col>
      </Row>

      <Row>
        <OrderInfo
          order={order}
          shipment={shipmentRate}
          total={order?.total_price + (shipmentRate?.price || 0)}
          className="mb-4"
        />
        <Col md={7} className={styles.shipments}>
          {order && user?.active_payment_method ? (
            <ShipmentRatesForm
              shipmentRate={shipmentRate}
              shipmentRates={rates}
              setShipmentRate={setShipmentRate}
              onSubmit={handleShipmentRateSelection}
              isPending={orderPending}
            />
          ) : (
            <h5>
              There is no active payment method. Please add it on your{' '}
              <NavLink href="/my-wineclub" className={styles.link}>
                Wine Club page.
              </NavLink>
            </h5>
          )}
        </Col>
      </Row>
    </PageWrapper>
  );
};

SubscriptionEdit.propTypes = {
  title: PropTypes.string,
};

export default SubscriptionEdit;
