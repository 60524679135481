import React from 'react';
import { Row, Col } from 'reactstrap';

import Sidebar from './Sidebar';
import Content from './Content';

const Tiers = () => {
  return (
    <>
      <Row>
        <Col xl={3} lg={12} sm={12}>
          <Sidebar />
        </Col>

        <Col xl={9} lg={12} sm={12}>
          <Content />
        </Col>
      </Row>
    </>
  );
};

export default Tiers;
