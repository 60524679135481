import React from 'react';
import PropTypes from 'prop-types';
import { Table, Button, CustomInput } from 'reactstrap';
import PaginationTable from 'components/PaginationTable';
import LocationSearchInput from 'components/LocationSearchInput';
import { AvForm } from 'availity-reactstrap-validation';
import PhoneField from 'components/Fields/PhoneField/PhoneField';
import cx from 'classnames';

import AddressBookRow from './AddressBookRow';

import styles from './DesktopTable.scss';

const DesktopTable = ({
  addresses,
  addressesPagination,
  onAddressesPageChange,
  onDeleteAddress,
  onChangeAddress,
  changeAddressEditMode,
  isAddressAdding,
  onAddClick,
  isAddressesPending,
  handleCancelAdding,
  phone,
  setPhone,
  isDefault,
  setIsDefault,
  country,
  state,
  postalCode,
  city,
  line1,
  line2,
  setCountry,
  setState,
  setPostalCode,
  setCity,
  setLine1,
  setLine2,
}) => {
  return (
    <PaginationTable
      setCurrentPage={onAddressesPageChange}
      pagination={addressesPagination}
    >
      <Table
        className={cx('table-grey', {
          [styles.disabled]: isAddressesPending,
        })}
      >
        <thead>
          <tr>
            <th data-priority="1">Is Default</th>
            <th data-priority="1">Address</th>
            <th data-priority="1">Phone</th>
            <th data-priority="1">Action</th>
          </tr>
        </thead>
        <tbody>
          {isAddressAdding && (
            <>
              <td className={styles.isDefault}>
                <div
                  className={cx(
                    styles.checkbox,
                    'custom-control custom-checkbox'
                  )}
                >
                  <CustomInput
                    type="checkbox"
                    onChange={event => setIsDefault(event.target.checked)}
                    checked={isDefault}
                    id="new-address"
                    name="new-address"
                  />
                </div>
              </td>
              <td className={cx(styles.address, styles.addressEditMode)}>
                <LocationSearchInput
                  model={{}}
                  isRequired
                  country={country}
                  state={state}
                  postalCode={postalCode}
                  city={city}
                  line1={line1}
                  line2={line2}
                  setCountry={setCountry}
                  setState={setState}
                  setPostalCode={setPostalCode}
                  setCity={setCity}
                  setLine1={setLine1}
                  setLine2={setLine2}
                  withoutLabel
                />
              </td>
              <td className={styles.phone}>
                <AvForm>
                  <PhoneField
                    isRequired
                    name="phone"
                    placeholder="Phone"
                    errorMessage="Enter Phone"
                    value={phone}
                    onChange={setPhone}
                  />
                </AvForm>
              </td>
              <td className={styles.action}>
                <Button
                  className={cx('mb-2 btn-rounded', styles.addActionButton)}
                  type="button"
                  onClick={onAddClick}
                >
                  Add
                </Button>
                <Button
                  className={cx(styles.addActionButton, 'btn-rounded')}
                  color="danger"
                  type="button"
                  onClick={handleCancelAdding}
                >
                  Cancel
                </Button>
              </td>
            </>
          )}
          {(addresses ?? []).map(item => (
            <AddressBookRow
              item={item}
              onDeleteAddress={onDeleteAddress}
              onChangeAddress={onChangeAddress}
              changeAddressEditMode={changeAddressEditMode}
              isDisabled={addresses?.length === 1}
            />
          ))}
        </tbody>
      </Table>
    </PaginationTable>
  );
};

DesktopTable.propTypes = {
  addresses: PropTypes.array,
  addressesPagination: PropTypes.object,
  onAddressesPageChange: PropTypes.func,
  onDeleteAddress: PropTypes.func,
  onChangeAddress: PropTypes.func,
  changeAddressEditMode: PropTypes.func,
  handleCancelAdding: PropTypes.func,
  isAddressAdding: PropTypes.bool,
  onAddClick: PropTypes.func,
  isAddressesPending: PropTypes.bool,
  phone: PropTypes.string,
  setPhone: PropTypes.func,
  isDefault: PropTypes.bool,
  setIsDefault: PropTypes.func,
  country: PropTypes.string,
  state: PropTypes.string,
  postalCode: PropTypes.string,
  city: PropTypes.string,
  line1: PropTypes.string,
  line2: PropTypes.string,
  setCountry: PropTypes.func,
  setState: PropTypes.func,
  setPostalCode: PropTypes.func,
  setCity: PropTypes.func,
  setLine1: PropTypes.func,
  setLine2: PropTypes.func,
};

export default DesktopTable;
