import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Table } from 'reactstrap';
import styles from './OrderRefundTable.scss';
import { format } from 'date-fns';
import formatPrice from '../../../utils/formatPrice';
import { ORDER_TYPES } from '../../../constants';
import classNames from 'classnames';

const OrderRefundTable = ({ data, className }) => {
  return (
    <Row className={classNames(styles.container, className)}>
      <Col md={3}>
        <p className={styles.text}>
          Payment type:
          <span className="ml-2">{data.return_type}</span>
        </p>
        <p className={styles.text}>
          Comment: <span className="ml-2">{data.comment || '-'}</span>
        </p>
        <p className={styles.text}>
          Refunded at:
          <span className="ml-2">
            {format(Date.parse(data.created_at), 'yyyy-MM-dd, h:mm aaa')}
          </span>
        </p>
      </Col>
      <Col md={9}>
        <Table bordered responsive>
          <thead>
            <tr>
              <th>Product Id</th>
              <th>Product Title</th>
              <th>Type</th>
              <th>Number</th>
            </tr>
          </thead>
          <tbody>
            {(data?.returned_order_items || []).map(item => (
              <tr key={item.product_id}>
                <td>{item.product_id || item.tasting_menu_id}</td>
                <td>{item.product_title || item.tasting_menu_title}</td>
                <td>{ORDER_TYPES[item.resourcetype]}</td>
                <td>{item.number}</td>
              </tr>
            ))}
            {!!data.shipment_return_amount && +data.shipment_return_amount > 0 && (
              <tr>
                <td />
                <td>Shipping</td>
                <td />
                <td>1</td>
              </tr>
            )}
            <tr>
              <td colSpan={3}>
                <p className="font-weight-bolder mb-0">Refund Amount</p>
              </td>
              <td>
                <p className="font-weight-bolder mb-0">
                  ${formatPrice(+data.return_amount)}
                </p>
              </td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};

OrderRefundTable.propTypes = {
  data: PropTypes.object,
  className: PropTypes.string,
};

export default OrderRefundTable;
