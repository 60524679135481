import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { UncontrolledTooltip, Button, CustomInput } from 'reactstrap';
import { getPreparedAddress } from 'utils/getPreparedAddress';
import LocationSearchInput from 'components/LocationSearchInput';
import PhoneField from 'components/Fields/PhoneField';
import { AvForm } from 'availity-reactstrap-validation';
import cx from 'classnames';

import styles from './DesktopTable.scss';

const AddressBookRow = ({
  item,
  onDeleteAddress,
  changeAddressEditMode,
  onChangeAddress,
  isDisabled,
}) => {
  const [id, setId] = useState(item?.id || -1);
  const [country, setCountry] = useState(item?.country || '');
  const [state, setState] = useState(item?.state || '');
  const [postalCode, setPostalCode] = useState(item?.postal_code || '');
  const [city, setCity] = useState(item?.city || '');
  const [line1, setLine1] = useState(item?.line1 || '');
  const [line2, setLine2] = useState(item?.line2 || '');
  const [phone, setPhone] = useState(item?.phone || '');
  const [isDefault, setIsDefault] = useState(item?.is_default || false);

  useEffect(() => {
    setCountry(item.country);
    setState(item.state);
    setPostalCode(item.postalCode);
    setCity(item.city);
    setLine1(item.line1);
    setLine2(item.line2);
    setPhone(item.phone);
    setIsDefault(item.is_default);
    setId(item.id);
  }, [item]);

  const onDeleteClick = () => {
    onDeleteAddress({ profile_id: item.id, is_default: item.is_default });
  };

  const onEditClick = () => {
    changeAddressEditMode({
      profile_id: item.id,
      isEditMode: !item.isEditMode,
    });
  };

  const onSaveClick = () => {
    onChangeAddress({
      profile_id: item.id,
      country,
      state,
      postal_code: postalCode,
      city,
      line1,
      line2,
      phone,
      is_default: isDefault,
    });
  };

  return (
    <tr key={item.id}>
      <td className={styles.isDefault}>
        <div className={cx(styles.checkbox, 'custom-control custom-checkbox')}>
          <CustomInput
            type="checkbox"
            onChange={event => setIsDefault(event.target.checked)}
            checked={isDefault}
            id={item.id}
            name={item.id}
            disabled={isDisabled || item.is_default}
          />
        </div>
      </td>
      <td
        className={cx(styles.address, {
          [styles.addressEditMode]: item.isEditMode,
        })}
      >
        {item.isEditMode ? (
          <LocationSearchInput
            model={item}
            isRequired
            country={country}
            state={state}
            postalCode={postalCode}
            city={city}
            line1={line1}
            line2={line2}
            setCountry={setCountry}
            setState={setState}
            setPostalCode={setPostalCode}
            setCity={setCity}
            setLine1={setLine1}
            setLine2={setLine2}
            withoutLabel
          />
        ) : (
          getPreparedAddress(item)
        )}
      </td>
      <td className={styles.phone}>
        {item.isEditMode ? (
          <AvForm>
            <PhoneField
              isRequired
              name="phone"
              placeholder="Phone"
              errorMessage="Enter Phone"
              value={phone}
              onChange={setPhone}
            />
          </AvForm>
        ) : (
          item.phone
        )}
      </td>
      <td className={styles.action}>
        {!item.isEditMode && isDefault !== item.is_default && item?.id === id && (
          <Button className="btn-rounded" type="button" onClick={onSaveClick}>
            Update
          </Button>
        )}
        {item.isEditMode ? (
          <Button className="btn-rounded" type="button" onClick={onSaveClick}>
            Save
          </Button>
        ) : (
          <>
            <button
              className={cx('mr-1 text-primary', styles.withoutStyle)}
              id={`edit-${item?.id}`}
              onClick={onEditClick}
            >
              <i className="mdi mdi-pencil font-size-18" />
            </button>
            <UncontrolledTooltip placement="top" target={`edit-${item?.id}`}>
              Edit
            </UncontrolledTooltip>
          </>
        )}

        <button
          className={cx('text-danger', styles.withoutStyle, {
            [styles.disabled]: isDisabled,
          })}
          id={`delete-${item?.id}`}
          onClick={onDeleteClick}
          disabled={isDisabled}
        >
          <i className="mdi mdi-trash-can font-size-18" />
        </button>
        <UncontrolledTooltip placement="top" target={`delete-${item?.id}`}>
          Delete
        </UncontrolledTooltip>
      </td>
    </tr>
  );
};

AddressBookRow.propTypes = {
  item: PropTypes.object,
  onDeleteAddress: PropTypes.func,
  onChangeAddress: PropTypes.func,
  changeAddressEditMode: PropTypes.func,
  isDisabled: PropTypes.bool,
};

export default AddressBookRow;
