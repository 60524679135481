import React from 'react';
import styles from './TiersCard.scss';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const TiersCard = ({
  title,
  capacity,
  period_in_months,
  description,
  onClick,
  isActive,
  className,
}) => {
  return (
    <button
      onClick={onClick}
      className={classNames(
        styles.container,
        isActive && styles.active,
        className
      )}
    >
      <div className={styles.left}>
        <h4 className={styles.title}>{title}</h4>
        <div className={styles.bottles}>
          <div className={styles.bottle}>{capacity}</div>
          <div className={styles.period}>{period_in_months}</div>
        </div>
      </div>
      <div className={styles.right}>
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: description || '' }}
        />
      </div>
    </button>
  );
};

TiersCard.propTypes = {
  title: PropTypes.string,
  capacity: PropTypes.number,
  period_in_months: PropTypes.string,
  description: PropTypes.string,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  className: PropTypes.string,
};
export default TiersCard;
