import React, { useState, useCallback } from 'react';

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import { actions as userActions } from 'models/user/slice';
import { emailSelector } from 'models/user/selectors';
import avatar from 'images/users/avatar.png';

const ProfileMenu = () => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const logout = useAction(userActions.logout);
  const handleLogout = useCallback(() => {
    logout();
  }, [logout]);

  const toggle = () => {
    setIsOpenMenu(!isOpenMenu);
  };

  const email = useSelector(emailSelector);
  const username = email || 'no user';

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isOpenMenu}
        toggle={toggle}
        className="d-inline-block user-dropdown"
      >
        <DropdownToggle
          tag="button"
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
        >
          <img
            className="rounded-circle header-profile-user mr-1"
            src={avatar}
            alt="Header Avatar"
          />
          <span className=" d-xl-inline-block ml-1 text-transform">
            {username}
          </span>
          <i className="mdi mdi-chevron-down ml-1 d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem className="text-danger">
            <span onClick={handleLogout}>
              <i className="ri-shut-down-line align-middle mr-1 text-danger" />{' '}
              Logout
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileMenu;
