import { createSlice } from 'redux-starter-kit';
import actionTypes from 'utils/actionTypes';

import { actions as userActions } from 'models/user/slice';
import { actions as obligationsActions } from 'models/obligations/slice';

const tiersSlice = createSlice({
  name: 'tiers',
  initialState: {
    isPending: false,
    tiers: [],
    tiersPagination: null,
    currentTier: {},
    lastTier: null,
    selectedTierId: null,
  },
  reducers: {
    fetchTiers: state => {
      state.isPending = true;
    },
    fetchTiersSuccess(state, { payload }) {
      const { results, pagination } = payload.data;
      state.isPending = false;
      state.tiers =
        pagination.current_page === 1 ? results : [...state.tiers, ...results];
      state.tiersPagination = pagination;
    },
    fetchTiersFailure: state => {
      state.isPending = false;
    },
    setCurrentTier(state, { payload }) {
      state.selectedTierId = payload;
    },
    setBottlesInCurrentTier(state, { payload }) {
      state.tiers = state.tiers.map(item => {
        if (item.id !== payload.id) {
          return item;
        }
        return {
          ...item,
          bottles: payload.bottles,
        };
      });
    },
  },
  extraReducers: {
    [userActions.getCurrentUserSuccess]: (state, { payload }) => {
      const { data } = payload;
      state.lastTier = data?.last_selected_tier;
      state.currentTier = data.current_obligation?.tier;
    },
    [userActions.loginSuccess]: (state, { payload }) => {
      const { data } = payload;
      state.lastTier = data?.last_selected_tier;
      state.currentTier = data.current_obligation?.tier;
    },
    [obligationsActions.confirmObligationSuccess]: (state, { payload }) => {
      state.currentTier = payload.data.tier;
    },
    [obligationsActions.cancelObligationSuccess]: state => {
      state.currentTier = {};
    },
  },
});

export const actions = actionTypes(tiersSlice.actions);

export default tiersSlice.reducer;
