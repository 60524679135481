import { createSlice } from 'redux-starter-kit';
import actionTypes from 'utils/actionTypes';
import { actions as paymentsActions } from 'models/payments/slice';
import { actions as obligationsActions } from 'models/obligations/slice';
import { actions as ordersActions } from 'models/orders/slice';

const setUser = (state, { payload }) => {
  const { data } = payload;
  state.token = data.auth_token;
  state.currentUser = data;
  state.isPending = false;
};

const resetUser = state => {
  state.token = null;
  state.currentUser = null;
  state.wineclub_id = null;
};

const updateAddress = (state, { payload }) => {
  const {
    city,
    country,
    line1,
    line2,
    postal_code,
    state: addressState,
    save_billing_data,
    save_profile_address,
  } = payload.inResponseTo;
  const address = {
    city,
    country,
    line1,
    line2,
    postal_code,
    state: addressState,
  };

  if (save_billing_data) {
    state.currentUser.billing_data = address;
  }

  if (save_profile_address) {
    state.currentUser.address = address;
  }
};

const changeCurrentObligation = (state, { payload }) => {
  const { data } = payload;
  state.currentUser.current_obligation = {
    ...data,
    last_unpaid_order_id: data?.last_unpaid_order_id,
  };
  if (state.currentUser.subscripted_tier_ids) {
    state.currentUser.subscripted_tier_ids.push(data?.tier.id);
  } else {
    state.currentUser.subscripted_tier_ids = [data?.tier.id];
  }

  updateAddress(state, { payload });
};

const userSlice = createSlice({
  name: 'user',
  initialState: {
    token: null,
    wineclub_id: null,
    isPending: false,
    confirmationToken: null,
    confirmationUser: {},
    isValidConfirmationToken: false,
    currentUser: null,
    recoverPasswordModal: false,
    addresses: [],
    addressesPagination: {},
    addressesPending: false,
    isAddressAdding: false,
  },
  reducers: {
    login: state => state,
    loginSuccess: setUser,
    register: state => {
      state.isPending = true;
    },
    registerSuccess: state => state,
    registerComplete: state => {
      state.isPending = true;
    },
    registerCompleteSuccess: setUser,
    logout: resetUser,
    logoutSuccess: state => state,
    logoutFailure: state => state,
    getCurrentUser: state => {
      state.isPending = true;
    },
    getCurrentUserSuccess: setUser,
    getCurrentUserFailure: resetUser,
    checkConfirmationToken: state => {
      state.isPending = true;
    },
    checkConfirmationTokenSuccess(state, { payload }) {
      state.isPending = false;
      state.isValidConfirmationToken = true;
      state.confirmationToken = payload.inResponseTo;
      state.confirmationUser = payload.data;
    },
    checkConfirmationTokenFailure: state => {
      state.isPending = false;
      state.isValidConfirmationToken = false;
    },
    updateUser: state => {
      state.isPending = true;
    },
    updateUserSuccess: (state, { payload }) => {
      setUser(state, { payload });
      state.isPending = false;
    },
    updateUserFailure: state => {
      state.isPending = false;
    },
    setRecoverPasswordModal: (state, { payload }) => {
      state.recoverPasswordModal = payload;
    },
    recoverPassword: state => {
      state.isPending = true;
      state.recoverPasswordModal = true;
    },
    recoverPasswordSuccess: state => {
      state.isPending = false;
      state.recoverPasswordModal = true;
    },
    recoverPasswordFailure: state => {
      state.isPending = false;
      state.recoverPasswordModal = false;
    },
    resetPassword: state => {
      state.isPending = true;
    },
    resetPasswordSuccess: setUser,
    resetPasswordFailure: state => {
      state.isPending = false;
    },
    fetchAddresses: state => {
      state.addressesPending = true;
    },
    fetchAddressesSuccess(state, { payload }) {
      state.addressesPending = false;
      state.addressesPagination = payload.data.pagination;
      state.addresses = payload.data.results;
    },
    fetchAddressesFailure: state => {
      state.addressesPending = false;
    },
    fetchDefaultAddress: state => {
      state.addressesPending = true;
    },
    fetchDefaultAddressSuccess(state, { payload }) {
      state.addressesPending = false;
      state.currentUser.address = payload.data.results?.[0];
      state.currentUser.phone = payload.data.results?.[0]?.phone;
    },
    fetchDefaultAddressFailure: state => {
      state.addressesPending = false;
    },
    setIsAddressAdding: (state, { payload }) => {
      state.isAddressAdding = payload;
    },
    addAddress: state => {
      state.addressesPending = true;
    },
    addAddressSuccess(state, { payload }) {
      state.addressesPending = true;
      state.isAddressAdding = false;
      if (payload.data.is_default) {
        state.currentUser.address = payload.data;
        state.currentUser.phone = payload.data.phone;
      }
    },
    addAddressFailure: state => {
      state.addressesPending = false;
    },
    deleteAddress: state => {
      state.addressesPending = true;
    },
    deleteAddressSuccess(state) {
      state.addressesPending = true;
    },
    deleteAddressFailure: state => {
      state.addressesPending = false;
    },
    changeAddressEditMode: (state, { payload }) => {
      const { profile_id, isEditMode } = payload;
      state.addresses = state.addresses.map(i =>
        i.id === profile_id ? { ...i, isEditMode } : i
      );
    },
    changeAddress: state => {
      state.addressesPending = true;
    },
    changeAddressSuccess(state, { payload }) {
      state.addressesPending = false;
      const { data } = payload;
      state.addresses = state.addresses.map(i => {
        if (i.id === data.id) {
          return { ...data, isEditMode: false };
        } else if (data.is_default && i.is_default) {
          return { ...i, is_default: false };
        }
        return i;
      });
      if (data.is_default) {
        state.currentUser.address = data;
        state.currentUser.phone = data.phone;
      }
    },
    changeAddressFailure: state => {
      state.addressesPending = false;
    },
  },
  extraReducers: {
    [ordersActions.updateOrderSuccess]: updateAddress,
    [paymentsActions.activatePaymentMethodSuccess]: (state, { payload }) => {
      const { data } = payload;
      const currentPaymentMethod = state.currentUser.active_payment_method;
      state.currentUser.active_payment_method = {
        ...currentPaymentMethod,
        ...data,
      };
    },
    [obligationsActions.createObligationSuccess]: changeCurrentObligation,
    [obligationsActions.cancelObligationSuccess]: changeCurrentObligation,
    [obligationsActions.confirmObligationSuccess]: changeCurrentObligation,
    [ordersActions.approveOrderSuccess]: (state, { payload }) => {
      if (payload.inResponseTo.isRetail) {
        state.currentUser.last_unpaid_retail_order_id = null;
      } else {
        state.currentUser.current_obligation.last_unpaid_order_id = null;
      }
    },
    [ordersActions.createRetailOrderSuccess]: (state, { payload }) => {
      state.currentUser.last_unpaid_retail_order_id = payload.data.id;
      updateAddress(state, { payload });
    },
    [ordersActions.skipOrderSuccess]: state => {
      state.currentUser.current_obligation.last_unpaid_order_id = null;
    },
  },
});

export const actions = actionTypes(userSlice.actions);

export default userSlice.reducer;
