import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import AppRouter from 'components/AppRouter';
import 'styles/theme.scss';
import useBrowser from 'hooks/useBrowser';
import useSelector from '../../hooks/useSelector';
import {
  currentUserSelector,
  tokenSelector,
} from '../../models/user/selectors';
import useAction from '../../hooks/useAction';
import { actions as userActions } from '../../models/user/slice';
import { actions as wineclubActions } from '../../models/wineclubs/slice';

const App = ({ routes }) => {
  const token = useSelector(tokenSelector);
  const fetchCurrentWineclub = useAction(wineclubActions.fetchCurrentWineclub);
  const currentUser = useSelector(currentUserSelector);
  const getCurrentUser = useAction(userActions.getCurrentUser);
  const browser = useBrowser();

  if (RUNTIME_ENV === 'client') {
    console.info('browser', browser);
  }

  useEffect(() => {
    if (token && !currentUser) {
      getCurrentUser();
    }
  }, [token, currentUser, getCurrentUser]);

  useEffect(() => {
    fetchCurrentWineclub();
  }, [fetchCurrentWineclub]);

  return (
    <>
      <ReactNotification />
      <AppRouter routes={routes} />
    </>
  );
};

App.propTypes = {
  routes: PropTypes.array.isRequired,
};

export default App;
