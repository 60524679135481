import { takeLatest, all, select, put } from 'redux-saga/effects';
import * as queryString from 'query-string';
import api from 'api';
import { actions } from './slice';

import { currentUserSelector } from '../user/selectors';
import { push } from 'connected-react-router';

export function* fetchOrdersSaga(action) {
  const stringified = queryString.stringify(action.payload);

  const url = `/orders?${stringified}`;
  yield api({
    action,
    method: 'get',
    url,
  });
}

function* createRetailOrderSaga(action) {
  const currentUser = yield select(currentUserSelector);
  const { email, phone } = currentUser;

  const payload = {
    ...action.payload,
    email,
    phone,
  };

  yield api({
    data: payload,
    action: { ...action, payload },
    method: 'post',
    failureMessage: 'An error occurred when creating an order',
    url: '/retail_orders/',
  });
}

function* createRetailOrderSuccessSaga(action) {
  if (action.payload.inResponseTo.withApproveRedirect) {
    yield put(push(`/wine-selection/${action.payload.data.id}/approve`));
  } else {
    yield put(push(`/wine-selection/${action.payload.data.id}/edit`));
  }
}

export function* fetchOrderSaga(action) {
  yield api({
    action,
    method: 'get',
    url: `/orders/${action.payload}`,
  });
}

export function* updateOrderSaga(action) {
  const { payload } = action;

  const url = payload.is_retail
    ? `/retail_orders/${payload.id}`
    : `/orders/${payload.id}`;

  yield api({
    data: payload,
    action,
    method: 'PATCH',
    url,
    successNavigateTo: payload?.redirectUrl,
  });
}

export function* changeDueDateOrderSaga(action) {
  const data = {
    id: action.payload,
  };

  yield api({
    action,
    method: 'post',
    data,
    url: `/orders/${action.payload}/change_due_date`,
    successMessage: 'Date was successfully changed',
  });
}

export function* approveOrderSaga(action) {
  const { id, data, isRetail, paymentMethodId } = action.payload;
  const url = isRetail
    ? `/retail_orders/${id}/capture`
    : `/orders/${id}/approve`;

  if (isRetail) {
    data.payment_method_id = paymentMethodId;
  }
  yield api({
    action,
    data,
    method: 'post',
    url,
    successMessage: 'Your order was successfully confirmed',
    successNavigateTo: `/order/${id}`,
    failureMessage:
      'Something went wrong with your payment, please try again later',
  });
}

export function* approveOrderSuccessSaga(action) {
  const info = action.payload.inResponseTo;
  const { id, email } = info;

  yield api({
    action,
    data: {
      email,
    },
    method: 'post',
    url: `/orders/${id}/bill`,
  });
}

export function* skipOrderSaga(action) {
  const data = {
    id: action.payload,
  };

  yield api({
    action,
    method: 'post',
    data,
    url: `/orders/${action.payload}/skip`,
    successMessage: 'Successfully cancelled current order',
    successNavigateTo: '/purchase_history',
  });
}

function* orderRefundSaga(action) {
  const { orderID } = action.payload;
  yield api({
    action,
    method: 'GET',
    url: `/orders/${orderID}/returns`,
  });
}

export default function*() {
  yield all([
    takeLatest(actions.fetchOrders, fetchOrdersSaga),
    takeLatest(actions.fetchOrder, fetchOrderSaga),
    takeLatest(actions.updateOrder, updateOrderSaga),
    takeLatest(actions.approveOrder, approveOrderSaga),
    takeLatest(actions.approveOrderSuccess, approveOrderSuccessSaga),
    takeLatest(actions.skipOrder, skipOrderSaga),
    takeLatest(actions.changeDueDateOrder, changeDueDateOrderSaga),
    takeLatest(actions.orderRefund, orderRefundSaga),
    takeLatest(actions.createRetailOrder, createRetailOrderSaga),
    takeLatest(actions.createRetailOrderSuccess, createRetailOrderSuccessSaga),
  ]);
}
