import React from 'react';
import styles from './InfoCard.scss';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const InfoCard = ({ title, value, className, type = 'small' }) => {
  return (
    <div className={classNames(className, styles.container)}>
      <h4
        className={classNames(
          type === 'small' ? styles.title : styles.titleBig
        )}
      >
        {title}
      </h4>
      <p
        className={classNames(type === 'small' ? styles.text : styles.textBig)}
      >
        {value || '-'}
      </p>
    </div>
  );
};

InfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.element.isRequired,
  className: PropTypes.string,
  type: PropTypes.string,
};

InfoCard.defaultProps = {
  type: 'small',
};

export default InfoCard;
