import React from 'react';
import PropTypes from 'prop-types';
import formatPrice from 'utils/formatPrice';
import styles from './OrderInfo.scss';
import classNames from 'classnames';

import { getOrderSubtotal } from 'utils/getOrderSubtotal';
import { getOrderAppliedFees } from 'utils/getOrderAppliedFees';
import { ORDER_ITEMS_RESOURCE_TYPES } from 'constants/orderItems';
import { Col } from 'reactstrap';

const getOrderItemTitle = orderItem => {
  const { title, resourcetype } = orderItem;
  const sampleText =
    resourcetype === ORDER_ITEMS_RESOURCE_TYPES.PRODUCT_SAMPLE_ORDER_ITEM
      ? ' (Sample)'
      : '';
  return `${title}${sampleText}`;
};

const getOrderItemPrice = orderItem => {
  if (
    orderItem?.resourcetype ===
    ORDER_ITEMS_RESOURCE_TYPES.TASTING_MENU_ORDER_ITEM
  ) {
    return orderItem.total_price;
  }
  const price = orderItem?.number * orderItem?.product_price;
  return price ?? 0;
};

const DetailItem = ({ title, value, number, small, discount }) => {
  return (
    <div className={classNames(styles.item, small && styles.small)}>
      <p className={styles.itemTitle}>{title}</p>
      <div className={styles.itemInfo}>
        {!!number && <p className={styles.itemCount}>{number}</p>}
        {!!discount && <del>${formatPrice(discount)}</del>}
        <p className={styles.itemPrice}>${formatPrice(value)}</p>
      </div>
    </div>
  );
};

const OrderInfo = ({ order, className, shipment, total }) => {
  const price = getOrderSubtotal(order?.order_items ?? []);
  const discountPrice = getOrderSubtotal(
    order?.order_items ?? [],
    order.percentage_discount
  );

  const isWithoutPST =
    order?.shipping_info?.state === 'Alberta' &&
    shipment &&
    shipment?.id !== 'noShipping';

  return (
    <Col md={4} className={className}>
      {order?.order_items?.length > 0 &&
        order.order_items.map(orderItem => (
          <DetailItem
            key={orderItem.id.toString()}
            {...orderItem}
            title={getOrderItemTitle(orderItem)}
            value={getOrderItemPrice(orderItem)}
          />
        ))}

      <div className={styles.block}>
        {order.tips_amount > 0 && (
          <DetailItem title="Tips" value={order.tips_amount} />
        )}
        {/* TODO: What this discount */}
        {order.discount_amount > 0 && (
          <DetailItem
            title={`Discount (${order?.percentage_discount}%)`}
            value={order.discount_amount}
          />
        )}

        {order.percentage_discount > 0 &&
        order.resourcetype === 'SubscriptionOrder' ? (
          <>
            <DetailItem
              title={`Discount(${order.percentage_discount}%)`}
              value={price - discountPrice}
            />
            <DetailItem
              title="Subtotal"
              value={discountPrice}
              discount={price}
            />
          </>
        ) : null}

        {order.percentage_discount &&
        order.resourcetype !== 'SubscriptionOrder' ? (
          <DetailItem title="Subtotal" value={price} />
        ) : null}

        {!order.percentage_discount &&
        order.resourcetype !== 'SubscriptionOrder' ? (
          <DetailItem title="Subtotal" value={price} />
        ) : null}

        {!order.percentage_discount &&
        order.resourcetype === 'SubscriptionOrder' ? (
          <DetailItem title="Subtotal" value={price} />
        ) : null}
        {getOrderAppliedFees(order, isWithoutPST).map(fee => (
          <DetailItem key={fee.title} {...fee} small />
        ))}
        <DetailItem
          title="Total tax"
          value={
            isWithoutPST
              ? order?.fee - (order?.applied_fees?.PST ?? 0)
              : order?.fee
          }
        />
        {(order?.shipment_price > 0 || shipment?.price > 0) && (
          <DetailItem
            title="Shipment price"
            value={order?.shipment_price || shipment?.price}
          />
        )}
      </div>

      <div className={styles.block}>
        <DetailItem
          title="Total"
          value={
            total - (isWithoutPST ? order?.applied_fees?.PST ?? 0 : 0) ||
            order?.total_price
          }
        />
      </div>
    </Col>
  );
};

OrderInfo.propTypes = {
  order: PropTypes.object,
  className: PropTypes.string,
  shipment: PropTypes.object,
  total: PropTypes.number,
};

DetailItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  number: PropTypes.number,
  small: PropTypes.bool,
  discount: PropTypes.number,
};

export default OrderInfo;
