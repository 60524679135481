import React from 'react';
import styles from './Popup.scss';
import PopupLayout from './PopupLayout';
import { PopupProps } from './PopupProps';

const PopupBase = ({ title, onClose, children }) => {
  return (
    <PopupLayout onClose={onClose}>
      <div className={styles.popupBase}>
        <button onClick={onClose} className={styles.closeButton}>
          <i className="ri-close-line" />
        </button>
        {title && <h2 className={styles.title}>{title}</h2>}
        {children}
      </div>
    </PopupLayout>
  );
};

PopupBase.propTypes = PopupProps;
export default PopupBase;
