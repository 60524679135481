import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import formatPrice from 'utils/formatPrice';

const getShippingLabel = rate => {
  if (rate.carrier) {
    return `${rate.carrier} ${rate.service ??
      ''} ${`${rate.delivery_days} days shipping` ?? ''}`;
  }
  if (rate?.brand_shipping_company_title) {
    return rate?.brand_shipping_company_title;
  }

  return 'Pickup';
};

const ShipmentRatesForm = ({
  shipmentRates,
  shipmentRate,
  setShipmentRate,
  onSubmit,
  isPending,
}) => {
  const noZeroShipmentRates = (shipmentRates ?? []).filter(
    rate => rate.price !== 0
  );

  return (
    <div>
      {noZeroShipmentRates.map(rate => (
        <label className="d-flex align-items-center mb-2" key={rate.id}>
          <input
            id={rate.id}
            name={rate.id}
            type="radio"
            value={rate.id}
            checked={shipmentRate?.id === rate.id}
            onChange={() => setShipmentRate(rate)}
          />
          {rate.price && (
            <span className="ml-2">${formatPrice(rate.price)}</span>
          )}
          <span className="ml-2">{getShippingLabel(rate)}</span>
        </label>
      ))}
      <Button
        className="waves-effect waves-light mt-3"
        type="button"
        color="success"
        disabled={!shipmentRate || isPending}
        onClick={onSubmit}
      >
        Submit
      </Button>
    </div>
  );
};

ShipmentRatesForm.propTypes = {
  shipmentRates: PropTypes.array,
  shipmentRate: PropTypes.object,
  setShipmentRate: PropTypes.func,
  onSubmit: PropTypes.func,
  isPending: PropTypes.bool,
};

export default ShipmentRatesForm;
