import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import useSelector from '../../hooks/useSelector';
import { currentUserSelector } from '../../models/user/selectors';
import { actions as ordersActions } from 'models/orders/slice';
import useAction from '../../hooks/useAction';
import {
  orderSelector,
  isPendingSelector,
} from '../../models/orders/selectors';
import Preloader from 'components/Preloader';

const WineSelection = () => {
  const fetchOrder = useAction(ordersActions.fetchOrder);
  const user = useSelector(currentUserSelector);
  const lastUnpaidOrderId = user?.current_obligation?.last_unpaid_order_id;
  const lastUnpaidRetailOrderId = user?.last_unpaid_retail_order_id;

  const order = useSelector(orderSelector);
  const isPending = useSelector(isPendingSelector);
  const isEdit = order?.order_items?.length > 0;
  const isLoading = !order?.id && lastUnpaidOrderId;

  useEffect(() => {
    if (lastUnpaidOrderId) {
      fetchOrder(lastUnpaidOrderId);
    }
  }, [fetchOrder, lastUnpaidOrderId]);

  if (isPending || isLoading) return <Preloader />;

  if (lastUnpaidOrderId && order) {
    if (isEdit) {
      return <Redirect to={`/wine-selection/${lastUnpaidOrderId}/edit`} />;
    }
    return <Redirect to="/wine-selection/select-option" />;
  }

  if (lastUnpaidRetailOrderId && !lastUnpaidOrderId) {
    return <Redirect to={`/wine-selection/${lastUnpaidRetailOrderId}/edit`} />;
  }

  if (!lastUnpaidOrderId && !lastUnpaidRetailOrderId) {
    return <Redirect to="/wine-selection/retail-order" />;
  }

  return <></>;
};

export default WineSelection;
