import React from 'react';
import PropTypes from 'prop-types';
import _flatten from 'lodash/flatten';
import { Switch, Route } from 'react-router-dom';

import NotFound from 'pages/NotFound';

import AuthLayout from 'components/Layouts/AuthLayout';
import MainLayout from 'components/Layouts/MainLayout';
import ProtectedRoute from '../ProtectedRoute';

const Router = ({ routes }) => {
  const mainAppRoutes = routes.filter(({ layout }) => layout === 'main');
  const authRoutes = routes.filter(({ layout }) => layout === 'auth');
  return (
    <Switch>
      <Route exact path={_flatten(mainAppRoutes.map(({ path }) => path))}>
        <MainLayout>
          <Switch>
            {mainAppRoutes.map(
              ({ path, exact, component: Component, title }) => (
                <ProtectedRoute
                  key={path.toString()}
                  exact={exact}
                  path={path}
                  render={props => <Component title={title} {...props} />}
                />
              )
            )}
          </Switch>
        </MainLayout>
      </Route>
      <Route exact path={_flatten(authRoutes.map(({ path }) => path))}>
        <AuthLayout>
          <Switch>
            {authRoutes.map(({ path, exact, component: Component, title }) => (
              <ProtectedRoute
                key={path.toString()}
                exact={exact}
                path={path}
                render={props => <Component title={title} {...props} />}
              />
            ))}
          </Switch>
        </AuthLayout>
      </Route>
      <ProtectedRoute exact path="/" />
      <Route path="*" exact component={NotFound} />
    </Switch>
  );
};

Router.propTypes = {
  routes: PropTypes.array.isRequired,
};

export default Router;
