import classNames from 'classnames';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styles from './Cards.scss';
import PaymentCard from '../../../components/PaymentCard';
import { Row, Col, Button } from 'reactstrap';
import { NavLink } from 'react-router-dom';

const Cards = ({ onActive, onSelect, selectedCard, cards, onRemove }) => {
  return (
    <div className={styles.block}>
      <h2 className={styles.title}>Credit cards</h2>
      <p className={styles.subtitle}>
        Choose a card to be used for your Wine Club subscription charges and
        purchases
      </p>
      <Row className={classNames(styles.cards, 'mt-4')}>
        {cards.map(method => (
          <Col
            xl="auto"
            lg="auto"
            md="auto"
            sm="auto"
            xs="auto"
            className="mb-3"
            key={method.id}
          >
            <PaymentCard
              className={styles.card}
              key={method.id}
              brand={method.card_brand}
              lastDigits={method.card_last_digits}
              name={method.holder_name}
              month={method.card_exp_month.toString().padStart(2, '0')}
              year={method.card_exp_year.toString()}
              isActive={method.is_active}
              isSelected={selectedCard === method.id}
              onClick={() => onSelect(method.id)}
            />
          </Col>
        ))}
      </Row>
      <Row className={styles.buttons}>
        {selectedCard && (
          <Col
            xl="auto"
            lg="auto"
            md="auto"
            sm="auto"
            xs="auto"
            className="mb-2"
          >
            <Button onClick={onRemove} className="btn-rounded-outline mr-3">
              Remove Selected Card
            </Button>
            <Button onClick={onActive} color="success" className="btn-rounded">
              Change Default Card
            </Button>
          </Col>
        )}
        <Col xl="auto" lg="auto" md="auto" sm="auto" xs="auto" className="mb-2">
          <NavLink to="card/create" className="text-dark font-weight-bold">
            <Button className="btn-rounded">Add New Card</Button>
          </NavLink>
        </Col>
      </Row>
    </div>
  );
};

Cards.propTypes = {
  onActive: PropTypes.func,
  onRemove: PropTypes.func,
  onSelect: PropTypes.func,
  selectedCard: PropTypes.number,
  cards: PropTypes.array,
};

export default memo(Cards);
