import { createSlice } from 'redux-starter-kit';
import actionTypes from 'utils/actionTypes';

const productsSlice = createSlice({
  name: 'products',
  initialState: {
    isPending: false,
    products: [],
    productsPagination: null,
    warehouseProductsOptions: [],
    warehouseProductsPagination: null,
  },
  reducers: {
    fetchProducts: state => {
      state.isPending = true;
    },
    fetchProductsSuccess(state, { payload }) {
      const { pagination, results } = payload.data;
      state.isPending = false;
      state.productsPagination = pagination;
      state.products = results;
    },
    fetchProductsFailure: state => {
      state.isPending = false;
    },
    fetchProductsWithWarehouse: state => {
      state.isPending = true;
    },
    fetchProductsWithWarehouseSuccess(state, { payload }) {
      const { pagination, results } = payload.data;
      state.isPending = false;
      state.warehouseProductsPagination = pagination;
      state.warehouseProductsOptions = results;
    },
    fetchProductsWithWarehouseFailure(state) {
      state.isPending = false;
    },
  },
});

export const actions = actionTypes(productsSlice.actions);

export default productsSlice.reducer;
