import React, { useEffect, useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import PageWrapper from 'components/PageWrapper';
import Subscription from './Subscription/Subscription';
import useSelector from 'hooks/useSelector';
import {
  currentUserSelector,
  addressesPaginationSelector,
  addressesSelector,
  isAddressesPendingSelector,
  isAddressAddingSelector,
} from 'models/user/selectors';
import CustomerInfo from './CustomerInfo/CustomerInfo';
import { paymentMethodsSelector } from '../../models/payments/selectors';
import useAction from 'hooks/useAction';
import { actions as paymentsActions } from 'models/payments/slice';
import { actions as commonActions } from 'models/common/slice';
import { actions as userActions } from 'models/user/slice';
import Cards from './Cards';
import OnboardingCard from '../../components/OnboardingCard/OnboardingCard';
import { Col, Row } from 'reactstrap';
import Title from '../../components/Title/Title';
import ConfirmationModal from 'components/ConfirmationModal';
import { getPreparedAddress } from '../../utils/getPreparedAddress';
import AddressBook from './AddressBook/AddressBook';

const MyWineClub = ({ title }) => {
  const fetchAddresses = useAction(userActions.fetchAddresses);
  const addAddress = useAction(userActions.addAddress);
  const deleteAddress = useAction(userActions.deleteAddress);
  const changeAddressEditMode = useAction(userActions.changeAddressEditMode);
  const setIsAddressAdding = useAction(userActions.setIsAddressAdding);
  const changeAddress = useAction(userActions.changeAddress);

  const fetchPaymentMethods = useAction(paymentsActions.fetchPaymentMethods);
  const activatePaymentMethod = useAction(
    paymentsActions.activatePaymentMethod
  );
  const setIsOpenConfirmationModal = useAction(
    commonActions.setIsOpenConfirmationModal
  );
  const removePaymentMethod = useAction(paymentsActions.removePaymentMethod);

  const user = useSelector(currentUserSelector);
  const paymentMethods = useSelector(paymentMethodsSelector);
  const addresses = useSelector(addressesSelector);
  const addressesPagination = useSelector(addressesPaginationSelector);
  const isAddressesPending = useSelector(isAddressesPendingSelector);
  const isAddressAdding = useSelector(isAddressAddingSelector);

  const [confirmationModalSettings, setConfirmationModalSettings] = useState(
    {}
  );
  const [selectedCard, setSelectedCard] = useState(null);

  const currentObligation = useMemo(() => user?.current_obligation, [user]);

  const onCardSelect = id => {
    setSelectedCard(id === selectedCard ? null : id);
  };

  const onActivateClick = () => {
    activatePaymentMethod({ id: selectedCard });
  };

  const onRemoveClick = () => {
    setConfirmationModalSettings({
      action: onConfirmedCardRemoval,
      description: (
        <p>
          Are you sure you want to remove this card? If this is the only card in
          the system you will need to add a new one for your subscription.
        </p>
      ),
    });
    setIsOpenConfirmationModal(true);
  };

  const onConfirmedCardRemoval = () => {
    const selected =
      selectedCard || paymentMethods.filter(method => method.is_active)[0].id;
    removePaymentMethod({ id: selected });
  };

  useEffect(() => {
    fetchPaymentMethods();
  }, [fetchPaymentMethods, activatePaymentMethod]);

  useEffect(() => {
    fetchAddresses();
  }, [fetchAddresses]);

  const onAddressesPageChange = useCallback(
    page => {
      fetchAddresses({ page });
    },
    [fetchAddresses]
  );

  const onDeleteClick = data => {
    setConfirmationModalSettings({
      action: () => {
        deleteAddress(data);
      },
      description: <p>Are you sure you want to remove this address?</p>,
    });
    setIsOpenConfirmationModal(true);
  };

  return (
    <PageWrapper title={title}>
      <Title title={title} />
      {currentObligation?.status === 'NEW' && (
        <Row>
          <Col xs="auto" md={6}>
            <OnboardingCard
              name={`${user?.first_name ?? ''} ${user?.last_name ?? ''}`}
              wineclubTitle={user?.wineclub_title}
              brandPhone={user?.brand?.contact_phone}
              brandEmail={user?.brand?.contact_email}
              brandTitle={user?.brand?.title}
              subscriptionTitle={currentObligation?.tier?.title}
              className="mt-3"
            />
          </Col>
        </Row>
      )}
      {currentObligation && (
        <Subscription
          title={currentObligation?.tier?.title}
          status={currentObligation?.status}
          date={user?.date_joined}
          address={getPreparedAddress(currentObligation.address_info ?? {})}
        />
      )}
      <CustomerInfo customer={user} />
      <AddressBook
        customer={user}
        addresses={addresses}
        defaultPhone={user?.phone}
        addressesPagination={addressesPagination}
        isAddressesPending={isAddressesPending}
        onAddressesPageChange={onAddressesPageChange}
        onDeleteAddress={onDeleteClick}
        onChangeAddress={changeAddress}
        changeAddressEditMode={changeAddressEditMode}
        setIsAddressAdding={setIsAddressAdding}
        isAddressAdding={isAddressAdding}
        onAddAddress={addAddress}
      />
      <Cards
        cards={paymentMethods}
        onActive={onActivateClick}
        onSelect={onCardSelect}
        onRemove={onRemoveClick}
        selectedCard={selectedCard}
      />
      <ConfirmationModal
        title="Confirmation"
        submitButtonTitle="Remove"
        onSaveClick={confirmationModalSettings.action}
      >
        {confirmationModalSettings.description}
      </ConfirmationModal>
    </PageWrapper>
  );
};

MyWineClub.propTypes = {
  title: PropTypes.string,
};

export default MyWineClub;
