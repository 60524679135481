import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import Preloader from 'components/Preloader';
import Title from 'components/Title';

import useComponentDidMount from 'hooks/useComponentDidMount';
import useSelector from 'hooks/useSelector';
import useAction from 'hooks/useAction';

import { actions as wineclubsActions } from 'models/wineclubs/slice';
import * as wineclubsSelectors from 'models/wineclubs/selectors';

import Form from './Form';

const CardCreate = () => {
  const fetchPaymentInfo = useAction(wineclubsActions.fetchPaymentInfo);
  const paymentInfo = useSelector(wineclubsSelectors.paymentInfoSelector);
  const isPendingPaymentInfo = useSelector(
    wineclubsSelectors.isPendingSelector
  );

  const stripePromise = paymentInfo.account_id
    ? loadStripe(paymentInfo.public_key, {
        stripeAccount: paymentInfo.account_id,
      })
    : loadStripe(paymentInfo.public_key);
  useComponentDidMount(() => {
    fetchPaymentInfo();
  });

  if (isPendingPaymentInfo) return <Preloader />;
  return (
    <>
      <Title
        className="mb-5 mt-4"
        title="Manage your credit cards"
        subtitle="Choose a card to be used for Therapy Wine Subscription charges and purchases"
      />
      <Elements stripe={stripePromise}>
        <Form />
      </Elements>
    </>
  );
};

export default CardCreate;
