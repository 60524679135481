import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import { actions as paymentsActions } from 'models/payments/slice';
import * as paymentsSelectors from 'models/payments/selectors';
import { showErrorMessage } from 'utils/notification';

import s from './Form.scss';

const CheckoutForm = () => {
  const sendPaymentMethod = useAction(paymentsActions.sendPaymentMethod);
  const stripe = useStripe();
  const elements = useElements();
  const intent = useSelector(paymentsSelectors.intentSelector);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const handleSubmit = async event => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }

    const card = elements.getElement(CardElement);
    const options = {
      name: `${firstName} ${lastName}`,
    };
    const result = await stripe.confirmCardSetup(intent.client_secret, {
      payment_method: {
        card,
        billing_details: {
          ...options,
        },
      },
    });

    if (result.error) {
      showErrorMessage('Error!', result.error.message);
    } else {
      // Send the token to server.
      sendPaymentMethod({ ...result, isActivate: true });
      card.clear();
      setFirstName('');
      setLastName('');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={s.group}>
        <label className={s.label}>
          <span>First name</span>
          <input
            id="firstname"
            name="firstname"
            required
            className={s.field}
            placeholder="Jane"
            value={firstName}
            onChange={({ target }) => setFirstName(target.value)}
          />
        </label>
        <label className={s.label}>
          <span>Last name</span>
          <input
            id="lastname"
            name="lastname"
            required
            className={s.field}
            placeholder="Doe"
            value={lastName}
            onChange={({ target }) => setLastName(target.value)}
          />
        </label>
      </div>
      <CardElement
        options={{
          hidePostalCode: true,
          style: {
            base: {
              color: '#32325d',
              fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
              fontSmoothing: 'antialiased',
              fontSize: '16px',
              '::placeholder': {
                color: '#aab7c4',
              },
            },
            invalid: {
              color: '#fa755a',
              iconColor: '#fa755a',
            },
          },
        }}
      />
      <Button
        type="submit"
        color="dark"
        className="waves-effect mt-4"
        disabled={!stripe}
      >
        Link Card To Your Account
      </Button>
    </form>
  );
};

export default CheckoutForm;
