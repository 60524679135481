import React, { memo } from 'react';
import styles from './OnboardingCard.scss';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const getContactText = (phone, email) => {
  const phoneText = phone && (
    <>
      Questions? Call us at <a href={`tel:${phone}`}>{phone}</a>
    </>
  );
  const emailText = email && (
    <>
      {' '}
      or email <a href={`mailto:${email}`}>{email}</a>
    </>
  );

  if (!email && !phone) return '';
  if (!phoneText && email) {
    return (
      <>
        Questions? Email us <a href={`mailto:${email}`}>{email}</a>.
      </>
    );
  }
  return (
    <>
      {phoneText}
      {emailText}.
    </>
  );
};

const OnboardingCard = ({
  name,
  wineclubTitle,
  subscriptionTitle,
  brandPhone,
  brandEmail,
  brandTitle,
  isSubscriptionEmpty,
  className,
}) => {
  return (
    <div className={classNames(styles.container, className)}>
      <p className={styles.text}>
        Welcome <span className={styles.boldText}>{name.trim()}</span>! Thank
        you for being part of our{' '}
        <span className={styles.boldText}>{wineclubTitle}</span>.{' '}
        {!isSubscriptionEmpty && subscriptionTitle
          ? `You’re in the ${subscriptionTitle}.`
          : ''}
      </p>
      <p className={styles.text}>
        Please select{' '}
        <span className={styles.boldText}>Activate My Account</span> to check
        that your address is accurate so we can ensure you receive your{' '}
        <span className={styles.boldText}>{brandTitle}</span> session! You will
        need to enter your payment method.{' '}
        {getContactText(brandPhone, brandEmail)}
      </p>
    </div>
  );
};

OnboardingCard.propTypes = {
  name: PropTypes.string.isRequired,
  wineclubTitle: PropTypes.string.isRequired,
  brandTitle: PropTypes.string.isRequired,
  subscriptionTitle: PropTypes.string,
  brandPhone: PropTypes.string,
  brandEmail: PropTypes.string,
  isSubscriptionEmpty: PropTypes.bool,
  className: PropTypes.string,
};
export default memo(OnboardingCard);
