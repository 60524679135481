import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';

import styles from './LocationSearchInput.scss';
import { Row, Col, Label, Button, Collapse } from 'reactstrap';
import { getParsedAddress } from '../../utils/getParsedAddress';

const LocationSearchInput = ({
  label,
  country,
  state,
  postalCode,
  city,
  line1,
  line2,
  setCountry,
  setState,
  setPostalCode,
  setCity,
  setLine1,
  setLine2,
  isRequired,
  withoutLabel,
}) => {
  const [isTouched, setIsTouched] = useState(false);
  const [isCustomFormOpened, setIsCustomFormOpened] = useState(false);
  const [customCity, setCustomCity] = useState(city);
  const [customState, setCustomState] = useState(state);
  const [customCountry, setCustomCountry] = useState(country);
  const [customPostalCode, setCustomPostalCode] = useState(postalCode);
  const [customLine1, setCustomLine1] = useState(line1);
  const [customLine2, setCustomLine2] = useState(line2);

  useEffect(() => {
    setCustomCity(city ?? '');
    setCustomCountry(country ?? '');
    setCustomState(state ?? '');
    setCustomPostalCode(postalCode ?? '');
    setCustomLine1(line1 ?? '');
    setCustomLine2(line2 ?? '');
  }, [city, state, country, postalCode, line1, line2]);

  const resetAddress = () => {
    setCountry(null);
    setState(null);
    setCity(null);
    setLine1(null);
    setLine2(null);
    setPostalCode(null);
  };

  const parseAddress = address => {
    const parsedAddress = getParsedAddress(address);
    setCountry(parsedAddress?.country ?? '');
    setState(parsedAddress?.state ?? '');
    setCity(parsedAddress?.city ?? '');
    setPostalCode(parsedAddress?.postalCode ?? '');
    setLine1(parsedAddress?.line1 ?? '');
  };

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: 'ca' },
    },
    debounce: 300,
  });

  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });

  const handleInput = e => {
    setIsTouched(true);
    setValue(e.target.value);
  };

  const handleSelect = ({ description }) => () => {
    resetAddress();
    setValue(description, false);
    clearSuggestions();

    getGeocode({ address: description })
      .then(results => {
        parseAddress(results[0].address_components);
        setLine2('');
        setIsTouched(false);

        setIsCustomFormOpened(true);
      })

      .catch(error => {
        console.log('😱 Error: ', error);
      });
  };

  const handleSaveCustomAdress = useCallback(() => {
    setCountry(customCountry);
    setState(customState);
    setCity(customCity);
    setPostalCode(customPostalCode);
    setLine1(customLine1);
    setLine2(customLine2);
    setIsTouched(false);
    setIsCustomFormOpened(false);
  }, [
    customCountry,
    customState,
    customCity,
    customPostalCode,
    customLine1,
    customLine2,
  ]);

  const toggleHandler = useCallback(() => {
    setIsCustomFormOpened(prevState => !prevState);
  }, []);

  const renderSuggestions = () =>
    data.map(suggestion => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <div
          className={styles.suggestionItem}
          key={place_id}
          onClick={handleSelect(suggestion)}
        >
          {main_text} {secondary_text}
        </div>
      );
    });

  return (
    <div className={styles.container}>
      <Button
        onClick={toggleHandler}
        className={classnames(
          styles.toggleButton,
          isCustomFormOpened && styles.toggleButtonOpened,
          withoutLabel && styles.toggleButtonWithoutLabel
        )}
      />
      <label className={styles.field} ref={ref}>
        {!withoutLabel && <Label>{label || 'Address'}</Label>}
        <input
          className="form-control"
          value={
            !isTouched
              ? [line2, line1, city, state, postalCode, country]
                  .filter(Boolean)
                  .join(', ')
              : value
          }
          onChange={handleInput}
          disabled={!ready}
          placeholder="Address(country, state, city, postal code)"
        />
        {status === 'OK' && (
          <div className={styles.suggestion}>{renderSuggestions()}</div>
        )}
      </label>
      <Collapse isOpen={isCustomFormOpened} className={styles.form}>
        <div className={styles.fields}>
          <Row className={styles.field}>
            <Col className="col-md-6">
              <label className={styles.field}>
                <Label required={isRequired}>Country</Label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Country"
                  onChange={event => setCustomCountry(event.target.value)}
                  value={customCountry}
                />
              </label>
            </Col>
            <Col className="col-md-6">
              <label className={styles.field}>
                <Label required={isRequired}>State</Label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="State"
                  onChange={event => setCustomState(event.target.value)}
                  value={customState}
                />
              </label>
            </Col>
          </Row>
          <Row className={styles.field}>
            <Col>
              <label className={styles.field}>
                <Label required={isRequired}>City</Label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="City"
                  onChange={event => setCustomCity(event.target.value)}
                  value={customCity}
                />
              </label>
            </Col>
            <Col>
              <label className={styles.field}>
                <Label required={isRequired}>Postal Code</Label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Postal Code"
                  onChange={event => setCustomPostalCode(event.target.value)}
                  value={customPostalCode}
                />
              </label>
            </Col>
          </Row>
          <Row className={styles.field}>
            <Col>
              <label className={styles.field}>
                <Label required={isRequired}>Street Number</Label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Street Number"
                  onChange={event => setCustomLine1(event.target.value)}
                  value={customLine1}
                />
              </label>
            </Col>
          </Row>
          <Row className={styles.field}>
            <Col>
              <label className={styles.field}>
                <Label>Unit</Label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Unit"
                  onChange={event => setLine2(event.target.value)}
                  value={line2}
                />
              </label>
            </Col>
          </Row>
        </div>
        <div className={styles.saveButtonContainer}>
          <Button
            className={classnames(styles.saveButton, 'btn-dark')}
            onClick={handleSaveCustomAdress}
          >
            Save Address
          </Button>
        </div>
      </Collapse>
    </div>
  );
};

LocationSearchInput.propTypes = {
  label: PropTypes.string,
  country: PropTypes.string,
  state: PropTypes.string,
  postalCode: PropTypes.string,
  city: PropTypes.string,
  line1: PropTypes.string,
  line2: PropTypes.string,
  setCountry: PropTypes.func,
  setState: PropTypes.func,
  setPostalCode: PropTypes.func,
  setCity: PropTypes.func,
  setLine1: PropTypes.func,
  setLine2: PropTypes.func,
  isRequired: PropTypes.bool,
  withoutLabel: PropTypes.bool,
};

export default LocationSearchInput;
