import { takeLatest, all } from 'redux-saga/effects';
import * as queryString from 'query-string';

import api from 'api';

import { actions } from './slice';

export function* fetchObligationsSaga(action) {
  const stringified = queryString.stringify(action.payload);

  const url = `/obligations?${stringified}`;
  yield api({
    action,
    method: 'get',
    url,
  });
}

export function* fetchObligationSaga(action) {
  yield api({
    action,
    method: 'get',
    url: `/wineclubs/${action.payload}`,
  });
}

export function* createObligationSaga(action) {
  const data = {
    ...action.payload,
  };
  const request = {
    action,
    method: 'post',
    url: `/obligations`,
    data,
    successMessage: 'Successfully created new subscription!',
  };
  if (!data.withoutSuccessNavigation) {
    request.successNavigateTo = '/wine-selection/select-option';
  }
  yield api(request);
}

export function* confirmObligationSaga(action) {
  const { id, address } = action.payload;
  yield api({
    action,
    method: 'POST',
    url: `/obligations/${id}/confirm`,
    data: address,
    successMessage: 'Successfully confirmd new subscription!',
    // successNavigateTo: '/wine-selection/select-option',
  });
}

export function* cancelObligationSaga(action) {
  const data = {
    id: action.payload,
  };
  yield api({
    action,
    method: 'post',
    url: `/obligations/${action.payload}/cancel`,
    data,
    successMessage: 'Successfully cancelled new subscription!',
  });
}

export default function*() {
  yield all([
    takeLatest(actions.confirmObligation, confirmObligationSaga),
    takeLatest(actions.cancelObligation, cancelObligationSaga),
    takeLatest(actions.createObligation, createObligationSaga),
    takeLatest(actions.fetchObligations, fetchObligationsSaga),
    takeLatest(actions.fetchObligation, fetchObligationSaga),
  ]);
}
