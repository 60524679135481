import React from 'react';

const MasterCardIcon = () => (
  <svg
    width="40px"
    height="40px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5037 17.5C18.2628 17.5 20.5 15.2617 20.5 12.5C20.5 9.73826 18.2628 7.5 15.5037 7.5C14.5006 7.5 13.5664 7.79591 12.7836 8.3053C13.8409 9.38714 14.4925 10.8675 14.4925 12.5C14.4925 14.3601 13.646 16.0229 12.3191 17.1227C12.134 17.2761 11.866 17.2761 11.6809 17.1227C10.354 16.0229 9.5075 14.3601 9.5075 12.5C9.5075 10.8675 10.1591 9.38714 11.2164 8.3053C10.4336 7.79591 9.49943 7.5 8.49625 7.5C5.73722 7.5 3.5 9.73825 3.5 12.5C3.5 15.2617 5.73722 17.5 8.49625 17.5C9.0855 17.5 9.64988 17.3981 10.1735 17.2114C10.4336 17.1187 10.7196 17.2544 10.8123 17.5145C10.9051 17.7746 10.7694 18.0606 10.5093 18.1534C9.87945 18.3779 9.20155 18.5 8.49625 18.5C5.18429 18.5 2.5 15.8134 2.5 12.5C2.5 9.18661 5.18429 6.5 8.49625 6.5C9.80431 6.5 11.0145 6.91907 12 7.63032C12.9855 6.91907 14.1957 6.5 15.5037 6.5C18.8157 6.5 21.5 9.18661 21.5 12.5C21.5 15.8134 18.8157 18.5 15.5037 18.5C14.7984 18.5 14.1205 18.3779 13.4907 18.1534C13.2306 18.0606 13.0949 17.7746 13.1876 17.5145C13.2804 17.2544 13.5664 17.1187 13.8265 17.2114C14.3501 17.3981 14.9145 17.5 15.5037 17.5ZM12 8.93555C11.0788 9.84252 10.5075 11.1044 10.5075 12.5C10.5075 13.8954 11.0782 15.157 12 16.0645C12.9218 15.157 13.4925 13.8954 13.4925 12.5C13.4925 11.1044 12.9212 9.84252 12 8.93555Z"
      fill="black"
    />
  </svg>
);

export default MasterCardIcon;
