import React from 'react';
import cx from 'classnames';
import { Col, Row } from 'reactstrap';
import modsClasses from 'utils/modsClasses';
import PropTypes from 'prop-types';
import s from './Content.scss';

const Content = ({ tiers, colorScheme, setCurrentTier, currentTierID }) => {
  const classes = modsClasses(s, { colorScheme });

  const handleTierClick = id => {
    setCurrentTier(id);
  };
  return (
    <Row className={cx(s.root, classes)}>
      {tiers.map(item => (
        <Col md={6} xs={12} key={item.id}>
          <div
            className={cx(s.card, { [s.active]: currentTierID === item.id })}
            onClick={() => handleTierClick(item.id)}
          >
            <div className={s.left}>
              <h4 className={s.title}>{item.title}</h4>
              <div className={s.bottles}>
                <div
                  className={cx(s.bottle, {
                    [s.activeBottles]: currentTierID === item.id,
                  })}
                >
                  {item.capacity}
                </div>
                <div className={s.period}> {item.period_in_months} </div>
              </div>
            </div>
            <div className={s.right}>
              <div className={s.description}>
                {item.description?.split('\n').map(descItem => (
                  <div key={descItem} className={s.descItem}>
                    {descItem}{' '}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Col>
      ))}
    </Row>
  );
};

Content.propTypes = {
  colorScheme: PropTypes.string,
  setCurrentTier: PropTypes.func,
  currentTierID: PropTypes.number,
  tiers: PropTypes.array,
};

Content.defaultProps = {
  colorScheme: 'white',
};

export default Content;
