import React, { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Label, FormGroup } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import PhoneField from 'components/Fields/PhoneField/PhoneField';

import Preloader from 'components/Preloader';

import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import { actions as userActions } from 'models/user/slice';
import * as userSelectors from 'models/user/selectors';
import * as wineclubsSelectors from 'models/wineclubs/selectors';

import styles from '../authentication.scss';
import AuthBlock from '../AuthBlock';

const checkPasswordsSame = (value, ctx) => {
  if (value !== ctx.password) {
    return 'Passwords must match';
  }
  return true;
};

const validatePassword = value => {
  const regex = /.*?(?:[a-z].*?[0-9]|[0-9].*?[a-z]).*?/;
  if (value.length < 8 || !regex.test(value)) {
    return 'Password must be at least 8 characters and includes numbers and letters';
  }
  return true;
};

const RegisterComplete = () => {
  const { wineclubID, confirmationToken, registerType } = useParams();
  const checkConfirmationToken = useAction(userActions.checkConfirmationToken);
  const isValidConfirmationToken = useSelector(
    userSelectors.isValidConfirmationTokenSelector
  );
  const confirmationUser = useSelector(userSelectors.confirmationUserSelector);
  const wineclub = useSelector(wineclubsSelectors.currentWineclubSelector);
  const wineclubIsPending = useSelector(wineclubsSelectors.isPendingSelector);
  const registerComplete = useAction(userActions.registerComplete);

  const handleSubmit = useCallback(
    (event, values) => {
      registerComplete({ ...values });
    },
    [registerComplete]
  );

  useEffect(() => {
    checkConfirmationToken(confirmationToken);
  }, [checkConfirmationToken, confirmationToken, wineclubID]);

  if (wineclubIsPending) return <Preloader />;
  return (
    <AuthBlock title="Register">
      <div className={styles.container}>
        <h4 className={styles.title}>
          {`Welcome to ${wineclub?.brand_title}`}
        </h4>
        <AvForm onValidSubmit={handleSubmit} className="form-horizontal">
          <FormGroup className="auth-form-group-custom mb-3">
            <i className="ri-user-line auto-custom-input-icon" />
            <Label htmlFor="first_name">First Name</Label>
            <AvField
              name="first_name"
              validate={{ required: true }}
              type="text"
              className="form-control"
              id="first_name"
              placeholder="Enter first name"
              value={confirmationUser?.first_name}
            />
          </FormGroup>

          <FormGroup className="auth-form-group-custom mb-3">
            <i className="ri-user-line auto-custom-input-icon" />
            <Label htmlFor="last_name">Last Name</Label>
            <AvField
              name="last_name"
              type="text"
              className="form-control"
              validate={{ required: true }}
              id="last_name"
              placeholder="Enter last name"
              value={confirmationUser?.last_name}
            />
          </FormGroup>
          <PhoneField
            formGroupClassName="auth-form-group-custom mb-3"
            name="phone"
            label="Phone"
            type="text"
            id="phone"
            placeholder="Enter phone"
            value={confirmationUser?.phone}
            icon={<i className="ri-phone-line auto-custom-input-icon" />}
          />
          {registerType === 'email_confirmation' && (
            <>
              <FormGroup className="auth-form-group-custom mb-3">
                <i className="ri-lock-line auto-custom-input-icon" />
                <Label htmlFor="password">Password</Label>
                <AvField
                  name="password"
                  type="password"
                  validate={{
                    customValidation: validatePassword,
                    required: true,
                  }}
                  className="form-control"
                  id="password"
                  placeholder="Enter password"
                />
              </FormGroup>

              <FormGroup className="auth-form-group-custom mb-3">
                <i className="ri-lock-line auto-custom-input-icon" />
                <Label htmlFor="password_confirm">Password Confirmation</Label>
                <AvField
                  name="password_confirm"
                  type="password"
                  validate={{
                    customValidation: checkPasswordsSame,
                    required: true,
                  }}
                  className="form-control"
                  id="password_confirm"
                  placeholder="Enter password confirmation"
                />
              </FormGroup>
            </>
          )}
          <div className="text-center">
            <Button
              color="primary"
              className="w-md waves-effect waves-light"
              type="submit"
              disabled={!isValidConfirmationToken}
            >
              Register
            </Button>
          </div>
        </AvForm>
      </div>
    </AuthBlock>
  );
};

export default RegisterComplete;
