import { takeLatest, all, select, put } from 'redux-saga/effects';

import api from 'api';
import { currentUserSelector } from 'models/user/selectors';

import { actions } from './slice';

export function* setupIntentSaga(action) {
  const currentUser = yield select(currentUserSelector);
  if (!currentUser) {
    return;
  }
  const { acquirer, acquirer_id } = currentUser;
  const data = {
    acquirer,
    acquirer_id,
  };
  yield api({
    action,
    method: 'post',
    url: `/payments/${acquirer}/${acquirer_id}/setup_intent`,
    data,
  });
}

export function* sendPaymentMethodSaga(action) {
  const { payload } = action;
  const currentUser = yield select(currentUserSelector);
  if (!currentUser) {
    return;
  }
  const { acquirer, acquirer_id } = currentUser;
  const data = {
    acquirer,
    acquirer_id,
    ...payload.setupIntent,
  };

  const response = yield api({
    action,
    method: 'post',
    url: `/payments/${acquirer}/${acquirer_id}/payment_method`,
    data,
    successMessage: 'Successfully save your card!',
    successNavigateTo: payload?.redirectUrl,
    withResult: true,
  });
  if (payload.isActivate) {
    yield put({
      type: actions.activatePaymentMethod,
      payload: response,
    });
  }
  yield put({
    type: actions.sendPaymentMethodSuccess,
    payload: response,
  });

  yield put({
    type: actions.setupIntent,
  });
}

function* fetchPaymentMethodsSaga(action) {
  yield api({
    action,
    method: 'GET',
    url: `/customers/current/payment_methods`,
  });
}

function* activatePaymentMethodSaga(action) {
  const { id } = action.payload;
  yield api({
    action,
    method: 'POST',
    url: `/customers/current/payment_methods/${id}/make_active`,
  });
}

function* removePaymentMethodSaga(action) {
  const { id } = action.payload;
  yield api({
    action,
    method: 'DELETE',
    url: `/customers/current/payment_methods/${id}`,
  });
}

export function* removePaymentMethodSuccessSaga() {
  yield put({
    type: actions.fetchPaymentMethods,
  });
}

export default function*() {
  yield all([
    takeLatest(actions.setupIntent, setupIntentSaga),
    takeLatest(actions.sendPaymentMethod, sendPaymentMethodSaga),
    takeLatest(actions.fetchPaymentMethods, fetchPaymentMethodsSaga),
    takeLatest(actions.activatePaymentMethod, activatePaymentMethodSaga),
    takeLatest(actions.removePaymentMethod, removePaymentMethodSaga),
    takeLatest(
      actions.removePaymentMethodSuccess,
      removePaymentMethodSuccessSaga
    ),
  ]);
}
