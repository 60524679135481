import React, { useState } from 'react';
import PropTypes from 'prop-types';
import LocationSearchInput from 'components/LocationSearchInput';
import AddressBookPaginatedField from 'components/SelectFields/AddressBookPaginatedField';

import styles from './LocationSearchInputWithAddressBook.scss';
import { Button, Collapse } from 'reactstrap';

const LocationSearchInputWithAddressBook = ({
  label,
  country,
  state,
  postalCode,
  city,
  line1,
  line2,
  setCountry,
  setState,
  setPostalCode,
  setCity,
  setLine1,
  setLine2,
  isRequired,
  withoutLabel,
  setPhone,
}) => {
  const [isAddressBookOpened, setIsAddressBookOpened] = useState(false);
  const [bookAddress, setBookAddress] = useState(null);

  const selectAddress = withPhone => {
    setCountry(bookAddress?.country);
    setState(bookAddress?.state);
    setPostalCode(bookAddress?.postal_code);
    setCity(bookAddress?.city);
    setLine1(bookAddress?.line1);
    setLine2(bookAddress?.line2);
    if (withPhone) {
      setPhone(bookAddress?.phone);
    }
  };

  return (
    <div className={styles.container}>
      <LocationSearchInput
        isRequired={isRequired}
        label={label}
        withoutLabel={withoutLabel}
        country={country}
        state={state}
        postalCode={postalCode}
        city={city}
        line1={line1}
        line2={line2}
        setCountry={setCountry}
        setState={setState}
        setPostalCode={setPostalCode}
        setCity={setCity}
        setLine1={setLine1}
        setLine2={setLine2}
      />
      <>
        <button
          className={styles.toggleButton}
          type="button"
          onClick={() => setIsAddressBookOpened(!isAddressBookOpened)}
        >
          {isAddressBookOpened ? 'Close' : 'Open'} the customer&apos;s address
          book
        </button>
        <Collapse isOpen={isAddressBookOpened} className={styles.form}>
          <AddressBookPaginatedField
            className={styles.book}
            value={bookAddress}
            onChange={setBookAddress}
          />
          <div className={styles.buttons}>
            <Button
              type="button"
              className="mr-3 mb-2 btn-rounded"
              disabled={!bookAddress}
              onClick={() => selectAddress(false)}
            >
              Select only address
            </Button>
            {!!setPhone && (
              <Button
                type="button"
                className="mb-2 btn-rounded"
                disabled={!bookAddress}
                onClick={() => selectAddress(true)}
              >
                Select address with phone
              </Button>
            )}
          </div>
        </Collapse>
      </>
    </div>
  );
};

LocationSearchInputWithAddressBook.propTypes = {
  label: PropTypes.string,
  country: PropTypes.string,
  state: PropTypes.string,
  postalCode: PropTypes.string,
  city: PropTypes.string,
  line1: PropTypes.string,
  line2: PropTypes.string,
  setCountry: PropTypes.func,
  setState: PropTypes.func,
  setPostalCode: PropTypes.func,
  setCity: PropTypes.func,
  setLine1: PropTypes.func,
  setLine2: PropTypes.func,
  isRequired: PropTypes.bool,
  withoutLabel: PropTypes.bool,
  setPhone: PropTypes.func,
};

export default LocationSearchInputWithAddressBook;
