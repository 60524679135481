import React, { useCallback, useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import styles from './OrderCheckoutCard.scss';
import PropTypes from 'prop-types';
import formatPrice from '../../utils/formatPrice';
import { Button, Input, UncontrolledTooltip } from 'reactstrap';
import { getOrderSubtotal } from '../../utils/getOrderSubtotal';
import { showErrorMessage } from 'utils/notification';
import OrderItem from '../OrderItem';

const PriceItem = ({ classname, title, value, price }) => (
  <div className={styles.price}>
    <p className={classNames(styles.priceText, classname)}>{title}</p>
    <div className={styles.priceBlock}>
      {!!price && <del className={styles.priceLine}>${formatPrice(price)}</del>}
      <p className={styles.priceText}>${formatPrice(value)}</p>
    </div>
  </div>
);

const OrderCheckoutCard = ({
  order,
  orderItems,
  total,
  fee,
  subtotal,
  onDelete,
  selectedItems,
  onCheckoutClick,
  onApplyClick,
  isPending,
  isRetailOrder,
  error,
}) => {
  const [coupon, setCoupon] = useState(order?.discount_code?.coupon ?? '');
  const buttonRef = useRef();
  const price = getOrderSubtotal(orderItems);

  useEffect(() => {
    setCoupon(order?.discount_code?.coupon ?? '');
  }, [order]);

  const handleCouponClick = useCallback(() => {
    onApplyClick(orderItems, {}, {}, coupon, false);
    buttonRef?.current?.blur();
  }, [coupon, buttonRef, orderItems, onApplyClick]);

  const handleRemoveCoupon = useCallback(() => {
    setCoupon('');
    onApplyClick(orderItems, {}, {}, '', false);
  }, [coupon, orderItems, onApplyClick]);

  useEffect(() => {
    if (error?.discount_code?.length) {
      showErrorMessage('Error!', error.discount_code[0]);
    }
  }, [error]);

  return (
    <div className={styles.container}>
      {isRetailOrder && <h2>Your cart</h2>}
      {!!order?.obligation?.tier?.capacity && (
        <p className={styles.remaining}>
          The order should include at least {order?.obligation?.tier?.capacity}{' '}
          products.
        </p>
      )}
      <hr className={styles.divider} />
      {(orderItems ?? []).map(item => (
        <OrderItem
          key={item.id.toString()}
          {...item}
          onDelete={() => onDelete?.(item.id)}
        />
      ))}
      {(orderItems ?? []).length === 0 && isRetailOrder && (
        <h4>No products selected.</h4>
      )}
      <hr className={styles.divider} />
      <div className={styles.priceWrapper}>
        {order?.percentage_discount ? (
          <PriceItem
            title={`Discount(${order.percentage_discount}%)`}
            value={price - subtotal}
          />
        ) : null}
        {order?.percentage_discount ? (
          <PriceItem title="Subtotal" value={subtotal} price={price} />
        ) : (
          <PriceItem title="Subtotal" value={subtotal} />
        )}
        <PriceItem title="Total Tax" value={fee} />
        {/* {Object.keys(order.applied_fees).map(key => (
          <PriceItem
            classname={styles.tax}
            key={key}
            title={key}
            value={getTax(orderItems, order.percentage_discount, key)}
          />
        ))} */}
        {orderItems?.length && (
          <div className={styles.couponContainer}>
            {order?.discount_code && (
              <div className={styles.couponInformation}>
                <div className={styles.couponInformationMessage}>
                  Applied discount: {order.discount_code.coupon}
                </div>
                <i
                  className={classNames(
                    'ri-close-line',
                    styles.couponInformationRemoveButton
                  )}
                  onClick={handleRemoveCoupon}
                />
              </div>
            )}
            <div className={styles.couponForm}>
              <Input
                className={styles.coupon}
                placeholder="Coupon"
                value={coupon}
                onChange={e => setCoupon(e.target.value)}
              />
              <Button
                innerRef={buttonRef}
                className={styles.couponButton}
                onClick={handleCouponClick}
                disabled={!coupon || isPending}
              >
                Apply
              </Button>
              <>
                <i
                  id="couponNote"
                  className="ml-1 ri-question-line auto-custom-input-icon"
                />
                <UncontrolledTooltip placement="top" target="couponNote">
                  Please note that discounts can’t be combined.
                </UncontrolledTooltip>
              </>
            </div>
          </div>
        )}
        <PriceItem title="Total" value={total} />
      </div>
      <Button
        disabled={
          selectedItems < order?.obligation?.tier?.capacity ||
          isPending ||
          (selectedItems === 0 && isRetailOrder)
        }
        color="dark"
        className={styles.priceButton}
        onClick={onCheckoutClick}
      >
        Check out
      </Button>
    </div>
  );
};

PriceItem.propTypes = {
  title: PropTypes.string,
  value: PropTypes.number,
  price: PropTypes.number,
  classname: PropTypes.string,
};

OrderCheckoutCard.propTypes = {
  orderItems: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  fee: PropTypes.number.isRequired,
  subtotal: PropTypes.number.isRequired,
  selectedItems: PropTypes.number,
  onDelete: PropTypes.func,
  onCheckoutClick: PropTypes.func,
  onApplyClick: PropTypes.func,
  order: PropTypes.object,
  isPending: PropTypes.bool,
  isRetailOrder: PropTypes.bool,
  error: PropTypes.any,
};

export default OrderCheckoutCard;
