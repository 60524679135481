import React from 'react';

import Title from 'components/Title';
import { Link } from 'react-router-dom';

import Form from './Form';
import { useSelector } from 'react-redux';
import * as userSelectors from '../../models/user/selectors';
import useAction from '../../hooks/useAction';
import { actions } from '../../models/user/slice';

const AddressEdit = () => {
  const updateUser = useAction(actions.updateUser);
  const currentUser = useSelector(userSelectors.currentUserSelector);

  const onSubmit = values => {
    updateUser(values);
  };

  return (
    <>
      <Title
        className="mb-5 mt-4"
        title="My information"
        subtitle={
          <Link to="/my-wineclub">
            Back to Therapy Wine Subscription account info.
          </Link>
        }
      />
      <Form onSubmit={onSubmit} defaultValues={currentUser} />
    </>
  );
};

export default AddressEdit;
