import React from 'react';
import LocationSearchInputWithAddressBook from 'components/LocationSearchInputWithAddressBook';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';

import PhoneField from '../../../components/Fields/PhoneField/PhoneField';

const ShippingInfo = ({
  setCountry,
  setState,
  setPostalCode,
  setCity,
  setLine1,
  setLine2,
  country,
  state,
  postalCode,
  city,
  line1,
  line2,
  phone,
  setPhone,
}) => {
  return (
    <div>
      <h5 className="card-title mb-3">Shipping information</h5>
      <Row>
        <Col md={8}>
          <LocationSearchInputWithAddressBook
            setCountry={setCountry}
            setState={setState}
            setPostalCode={setPostalCode}
            setCity={setCity}
            setLine1={setLine1}
            setLine2={setLine2}
            setPhone={setPhone}
            country={country}
            state={state}
            postalCode={postalCode}
            city={city}
            line1={line1}
            line2={line2}
          />
        </Col>
        <Col md={4}>
          <AvForm>
            <PhoneField
              label="Phone"
              name="phone"
              type="text"
              className="form-control"
              formGroupClassName="w-100"
              id="phone"
              placeholder="Enter Phone"
              value={phone}
              onChange={setPhone}
            />
          </AvForm>
        </Col>
      </Row>
    </div>
  );
};

ShippingInfo.propTypes = {
  setCountry: PropTypes.func,
  setState: PropTypes.func,
  setPostalCode: PropTypes.func,
  setCity: PropTypes.func,
  setLine1: PropTypes.func,
  setLine2: PropTypes.func,
  country: PropTypes.string,
  state: PropTypes.string,
  postalCode: PropTypes.string,
  city: PropTypes.string,
  line1: PropTypes.string,
  line2: PropTypes.string,
  phone: PropTypes.string,
  setPhone: PropTypes.func,
};

export default ShippingInfo;
