import React from 'react';
import TextField from './TextField';
import { ControlledFieldProps } from './ControlledFieldProps.d';

const PasswordMinLengthMessage = 'Password must be 6 or more characters';

const PasswordField = ({ rules, ...props }) => {
  return (
    <TextField
      name="password"
      label="Password"
      placeholder="Password"
      rules={{
        minLength: value => value.length >= 6 || PasswordMinLengthMessage,
        ...rules,
      }}
      required
      type="password"
      {...props}
    />
  );
};

PasswordField.propTypes = ControlledFieldProps;
export default PasswordField;
