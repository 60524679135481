import { createSlice } from 'redux-starter-kit';
import actionTypes from 'utils/actionTypes';

const orderShipmentRates = createSlice({
  name: 'orderShipmentRates',
  initialState: {
    isPending: false,
    rates: [],
    modalOpen: false,
  },
  reducers: {
    fetchShipmentRates(state) {
      state.isPending = true;
    },
    fetchShipmentRatesSuccess(state, { payload }) {
      state.isPending = false;
      state.rates = payload.data;
    },
    fetchShipmentRatesFailure(state) {
      state.isPending = false;
    },

    setShipmentRatesModalOpen(state, { payload }) {
      state.modalOpen = payload;
    },
  },
});

export const actions = actionTypes(orderShipmentRates.actions);

export default orderShipmentRates.reducer;
