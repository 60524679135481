import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Container } from 'reactstrap';
import cx from 'classnames';
import PageWrapper from 'components/PageWrapper';

import styles from './AuthBlock.scss';
import useSelector from '../../../hooks/useSelector';
import { currentWineclubSelector } from '../../../models/wineclubs/selectors';

const AuthBlock = ({ title, subtitle, paragraph, children }) => {
  const currentWineclub = useSelector(currentWineclubSelector);

  return (
    <PageWrapper title={title}>
      <div className={styles.root}>
        <div>
          <Container fluid className="p-0">
            <Row className="no-gutters">
              <Col
                className="authentication-page-content d-flex justify-content-center flex-column h-100"
                lg={4}
              >
                <div
                  className={cx(
                    styles.header,
                    `d-flex align-items-center justify-content-center`
                  )}
                >
                  <div className="h-100 text-center">
                    {currentWineclub && (
                      <img
                        className={styles.logo}
                        src={currentWineclub.brand_dashboard_logo_url}
                        alt={currentWineclub.title}
                      />
                    )}
                  </div>
                </div>
                <div
                  className={cx(`${styles.content} d-flex align-items-center`)}
                >
                  <div className="w-100">
                    <Row className={styles.block}>
                      <Col lg={9}>
                        <div>
                          <div className="text-left">
                            <h4 className="font-size-18 mt-4">{subtitle}</h4>
                            {!!paragraph && (
                              <p className="text-muted">{paragraph}</p>
                            )}
                          </div>
                          <div className="mt-4">{children}</div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col lg={8}>
                <div className={styles.background} />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </PageWrapper>
  );
};

AuthBlock.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  paragraph: PropTypes.string,
  children: PropTypes.element,
};

export default AuthBlock;
