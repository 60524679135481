export const getOrderSubtotal = (orderItems, discount) => {
  let discountValue = 0;
  let subtotal = 0;

  orderItems.forEach(item => {
    const currentTotal = (item?.product_price ?? +item?.price) * item.number;
    subtotal += currentTotal;

    if (discount) {
      const currentDiscountValue = (currentTotal * discount) / 100;
      discountValue += +currentDiscountValue.toFixed(2);
    }
  });

  if (discountValue) return subtotal - discountValue;

  return subtotal;
};
