import { takeLatest, all, select, put } from 'redux-saga/effects';

import { history } from 'src/client';
import api from 'api';
import {
  confirmationTokenSelector,
  addressesPaginationSelector,
} from 'models/user/selectors';
import * as queryString from 'query-string';

import { actions } from './slice';

export function* loginSaga(action) {
  const data = {
    ...action.payload,
  };
  yield api({
    action,
    method: 'post',
    url: '/customers/login',
    data,
    successMessage: 'Successfully login!',
    failureMessage: 'Wrong email or password',
    isRequestWithoutToken: true,
  });
}
export function* sendRegistrationEmailSaga(action) {
  yield api({
    action,
    method: 'post',
    url: `/customers/register`,
    data: action.payload,
    successMessage:
      'Email sent. Check your email to complete the registration process.',
    isRequestWithoutToken: true,
  });
}

export function* registerSaga(action) {
  const confirmationToken = yield select(confirmationTokenSelector);
  yield api({
    action,
    method: 'post',
    url: `/customers/confirmation/${confirmationToken}`,
    data: action.payload,
    successMessage: 'Successfully register!',
    isRequestWithoutToken: true,
  });
}

export function* checkConfirmationTokenSaga(action) {
  yield api({
    action,
    method: 'get',
    url: `/customers/confirmation/${action.payload}/check`,
    data: action.payload,
    successMessage: 'Token valid, please fill in fields below!',
    isRequestWithoutToken: true,
  });
}

function* checkConfirmationTokenFailureSaga(action) {
  const { response, inResponseTo } = action.payload;

  if (response?.token?.[0] === 'Token is expired') {
    yield api({
      action,
      method: 'post',
      url: `/customers/confirmation/${inResponseTo}/send_email`,
      data: action.payload,
      successMessage: 'New email has been sent',
      isRequestWithoutToken: true,
    });
  }
}

export function* logoutSaga() {
  yield history.push('/login');
}

export function* getCurrentUserSaga(action) {
  yield api({
    action,
    method: 'get',
    url: `/customers/current`,
    data: action.payload,
    failureNavigateTo: '/login',
  });
}

function* recoverPasswordSaga(action) {
  const { email } = action.payload;

  yield api({
    action,
    method: 'GET',
    url: `/customers/recover_password?email=${encodeURIComponent(email)}`,
    isRequestWithoutToken: true,
  });
}

export function* resetPasswordSaga(action) {
  const { payload } = action;

  yield api({
    action,
    data: payload,
    method: 'patch',
    url: `/customers/reset_password`,
    isRequestWithoutToken: true,
    successMessage: 'Password successfully reset',
  });
}

function* updateUserSaga(action) {
  yield api({
    action,
    method: 'PATCH',
    url: `/customers/current/update`,
    data: action.payload,
    successNavigateTo: '/my-wineclub',
  });
}

function* fetchAddressesSaga(action) {
  const stringified = queryString.stringify({
    per_page: 5,
    ...(action.payload ?? {}),
  });
  const url = stringified
    ? `/customers/profiles?${stringified}`
    : `/customers/profiles`;

  yield api({
    action,
    method: 'GET',
    url,
  });
}

function* addAddressSaga(action) {
  yield api({
    action,
    method: 'POST',
    url: `/customers/profiles`,
    data: action.payload,
  });
}

function* addAddressSuccessSaga() {
  yield put({ type: actions.fetchAddresses, payload: { page: 1 } });
}

function* deleteAddressSaga(action) {
  const { profile_id } = action.payload;
  yield api({
    action,
    method: 'DELETE',
    url: `/customers/profiles/${profile_id}`,
  });
}

function* deleteAddressSuccessSaga(action) {
  const { is_default } = action.payload.inResponseTo;
  const pagination = yield select(addressesPaginationSelector);
  const { total_pages, current_page, per_page, total_count } = pagination;
  const reqPayload = {};
  if (
    total_pages === current_page &&
    total_count - per_page * current_page === 9
  ) {
    reqPayload.page = current_page - 1;
  } else {
    reqPayload.page = current_page;
  }
  yield put({ type: actions.fetchAddresses, payload: reqPayload });
  if (is_default) {
    yield put({
      type: actions.fetchDefaultAddress,
      payload: { ...reqPayload, is_default: true },
    });
  }
}

function* changeAddressSaga(action) {
  const { profile_id, ...data } = action.payload;
  yield api({
    action,
    method: 'PATCH',
    url: `/customers/profiles/${profile_id}`,
    data,
  });
}

export default function*() {
  yield all([
    takeLatest(actions.login, loginSaga),
    takeLatest(actions.register, sendRegistrationEmailSaga),
    takeLatest(actions.registerComplete, registerSaga),
    takeLatest(actions.logout, logoutSaga),
    takeLatest(actions.getCurrentUser, getCurrentUserSaga),
    takeLatest(actions.checkConfirmationToken, checkConfirmationTokenSaga),
    takeLatest(
      actions.checkConfirmationTokenFailure,
      checkConfirmationTokenFailureSaga
    ),
    takeLatest(actions.recoverPassword, recoverPasswordSaga),
    takeLatest(actions.resetPassword, resetPasswordSaga),
    takeLatest(actions.updateUser, updateUserSaga),
    takeLatest(actions.fetchAddresses, fetchAddressesSaga),
    takeLatest(actions.deleteAddress, deleteAddressSaga),
    takeLatest(actions.deleteAddressSuccess, deleteAddressSuccessSaga),
    takeLatest(actions.changeAddress, changeAddressSaga),
    takeLatest(actions.addAddress, addAddressSaga),
    takeLatest(actions.addAddressSuccess, addAddressSuccessSaga),
    takeLatest(actions.fetchDefaultAddress, fetchAddressesSaga),
  ]);
}
