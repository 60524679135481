import React from 'react';
import styles from './ProductPagination.scss';
import PropTypes from 'prop-types';
import CircleButton from '../CircleButton';
import classNames from 'classnames';

const ProductPagination = ({ page, totalPages, onPageChange }) => {
  const pages = Array.from({ length: totalPages }, (_, index) => index + 1);

  return (
    <div className={styles.container}>
      <CircleButton
        onClick={() => onPageChange?.(page - 1)}
        className={styles.button}
        disabled={page === 1}
      >
        <i className={classNames(styles.icon, 'ri-arrow-left-s-line')} />
      </CircleButton>
      {pages.map(pageItem => (
        <CircleButton
          onClick={() => pageItem !== page && onPageChange?.(pageItem)}
          dark={pageItem === page}
          key={pageItem.toString()}
          className={styles.button}
        >
          {pageItem}
        </CircleButton>
      ))}
      <CircleButton
        onClick={() => onPageChange?.(page + 1)}
        className={styles.button}
        disabled={page === totalPages}
      >
        <i className={classNames(styles.icon, 'ri-arrow-right-s-line')} />
      </CircleButton>
    </div>
  );
};

ProductPagination.propTypes = {
  page: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func,
};
export default ProductPagination;
