import React from 'react';
import styles from './PaymentInformation.scss';
import PropTypes from 'prop-types';
import { Elements } from '@stripe/react-stripe-js';

import { loadStripe } from '@stripe/stripe-js';
import Form from './Form';
import 'react-credit-cards/es/styles-compiled.css';
import PaymentCard from '../../../components/PaymentCard';

const PaymentInformation = ({ currentUser, paymentInfo }) => {
  const stripePromise = paymentInfo.account_id
    ? loadStripe(paymentInfo.public_key, {
        stripeAccount: paymentInfo.account_id,
      })
    : loadStripe(paymentInfo.public_key);
  const { active_payment_method: paymentMethod } = currentUser;

  return (
    <>
      {paymentMethod ? (
        <>
          <div className="d-flex justify-content-center my-4">
            <PaymentCard
              className={styles.card}
              brand={paymentMethod?.card_brand}
              lastDigits={paymentMethod.card_last_digits}
              name={paymentMethod.holder_name}
              month={paymentMethod.card_exp_month.toString().padStart(2, '0')}
              year={paymentMethod.card_exp_year.toString()}
              isActive
            />
          </div>
          <h5 className="card-title mb-3">Add new payment method</h5>
          <div>
            <Elements stripe={stripePromise}>
              <Form />
            </Elements>
          </div>
        </>
      ) : (
        <div className="mt-5">
          <Elements stripe={stripePromise}>
            <Form />
          </Elements>
        </div>
      )}
    </>
  );
};

PaymentInformation.propTypes = {
  currentUser: PropTypes.object,
  paymentInfo: PropTypes.object,
};

export default PaymentInformation;
