import { createSelector } from 'reselect';

export const rootSelector = state => state.orderShipmentRates;

export const isPendingSelector = createSelector(
  rootSelector,
  ({ isPending }) => isPending
);

export const shipmentRatesSelector = createSelector(
  rootSelector,
  ({ rates }) => rates
);

export const shipmentRatesModalOpenSelector = createSelector(
  rootSelector,
  ({ modalOpen }) => modalOpen
);
