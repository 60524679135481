import React, { memo, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Input, Label, Row } from 'reactstrap';
import LocationSearchInputWithAddressBook from 'components/LocationSearchInputWithAddressBook';
import { checkAddressEmpty } from 'utils/checkAddressEmpty';
import PopupBase from '../../Popup/PopupBase';
import { AvForm } from 'availity-reactstrap-validation';
import PhoneField from 'components/Fields/PhoneField/PhoneField';

import styles from './RetailOrderAddressPopup.scss';

const RetailOrderAddressPopup = ({
  onSubmit,
  onClose,
  withSaving,
  defaultAddress,
}) => {
  const [country, setCountry] = useState(defaultAddress?.country || null);
  const [state, setState] = useState(defaultAddress?.state || null);
  const [postalCode, setPostalCode] = useState(
    defaultAddress?.postal_code || null
  );
  const [city, setCity] = useState(defaultAddress?.city || null);
  const [line1, setLine1] = useState(defaultAddress?.line1 || null);
  const [line2, setLine2] = useState(defaultAddress?.line2 || null);
  const [phone, setPhone] = useState(defaultAddress?.phone || '');
  const [saveProfileAddress, setSaveProfileAddress] = useState(false);
  const [saveBillingAddress, setSaveBillingAddress] = useState(false);

  const address = useMemo(
    () => ({
      country,
      state,
      postal_code: postalCode,
      city,
      line1,
      line2,
      phone,
    }),
    [country, state, postalCode, city, line1, line2, phone]
  );

  const onClickHandler = useCallback(() => {
    onSubmit?.(address, { saveBillingAddress, saveProfileAddress });
    onClose();
  }, [address, onSubmit, onClose, saveBillingAddress, saveProfileAddress]);

  return (
    <PopupBase title="Enter shipping address" onClose={onClose}>
      <div className={styles.container}>
        <Row>
          <Col md={12}>
            <LocationSearchInputWithAddressBook
              setCountry={setCountry}
              setState={setState}
              setPostalCode={setPostalCode}
              setCity={setCity}
              setLine1={setLine1}
              postalCode={postalCode}
              setLine2={setLine2}
              setPhone={setPhone}
              isRequired
              {...address}
            />
          </Col>
          <Col md={12}>
            <AvForm>
              <PhoneField
                label="Phone"
                name="phone"
                type="text"
                className="form-control"
                formGroupClassName="w-100"
                id="phone"
                placeholder="Enter Phone"
                value={phone}
                onChange={setPhone}
              />
            </AvForm>
          </Col>
          {withSaving && (
            <Col md={12}>
              <Label className={styles.label}>
                <Input
                  className={styles.input}
                  checked={saveProfileAddress}
                  onChange={e => setSaveProfileAddress(e.target.checked)}
                  type="checkbox"
                />
                Save as shipping address
              </Label>
              <Label className={styles.label}>
                <Input
                  checked={saveBillingAddress}
                  className={styles.input}
                  onChange={e => setSaveBillingAddress(e.target.checked)}
                  type="checkbox"
                />
                Save as billing address
              </Label>
            </Col>
          )}
        </Row>

        <Row className="mt-3">
          <Col md={12}>
            <Button
              disabled={checkAddressEmpty(address)}
              className={styles.button}
              color="success"
              onClick={onClickHandler}
            >
              Continue
            </Button>
          </Col>
        </Row>
      </div>
    </PopupBase>
  );
};

RetailOrderAddressPopup.propTypes = {
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  withSaving: PropTypes.bool,
  defaultAddress: PropTypes.object,
};

export default memo(RetailOrderAddressPopup);
