import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import useSelector from '../../hooks/useSelector';
import {
  currentUserSelector,
  tokenSelector,
} from '../../models/user/selectors';
import {
  loadFromLocalStorageByKey,
  saveToLocalStorage,
} from '../../utils/localStorage';

const ProtectedRoute = ({ path, ...props }) => {
  const token = useSelector(tokenSelector);
  const user = useSelector(currentUserSelector);
  const isAuthenticated = !!token;

  const isObligationCreationPage = useMemo(() => {
    const tiersLength = user?.subscripted_tier_ids?.length;
    return (
      !tiersLength ||
      (tiersLength === 1 && user?.current_obligation?.status === 'NEW')
    );
  }, [user]);

  const isAuthRoute = [
    '/login',
    '/forgot-password',
    '/register',
    '/wineclubs/:wineclubID/:registerType/:confirmationToken',
    '/customers/reset_password/:token',
    '/reset-password/:token',
    '/join-wineclub',
  ].includes(path);

  if (isAuthenticated && isAuthRoute) {
    return <Redirect from={path} to="/" {...props} />;
  }

  if (!isAuthenticated && !isAuthRoute) {
    saveToLocalStorage('redirectPath', path);
    return <Redirect from={path} to="/login" {...props} />;
  }

  if (path === '/' && user) {
    let updatedPath = loadFromLocalStorageByKey('redirectPath');
    if (updatedPath) {
      saveToLocalStorage('redirectPath', null);
    } else {
      updatedPath = isObligationCreationPage
        ? '/subscription-create'
        : '/my-wineclub';
    }
    return <Redirect from={path} to={updatedPath} {...props} />;
  }

  if (path === '/my-wineclub' && isObligationCreationPage) {
    return <Redirect from={path} to="/subscription-create" {...props} />;
  }

  return <Route path={path} {...props} />;
};

ProtectedRoute.propTypes = {
  path: PropTypes.string.isRequired,
  permissions: PropTypes.array,
};
export default ProtectedRoute;
