import React from 'react';
import PropTypes from 'prop-types';
import MobileItemBlock from 'components/MobileItemBlock';
import { Button, Card, CardBody, CustomInput } from 'reactstrap';
import LocationSearchInput from 'components/LocationSearchInput';
import PhoneField from 'components/Fields/PhoneField';
import { AvForm } from 'availity-reactstrap-validation';
import cx from 'classnames';
import Pagination from 'components/Pagination';
import MobileBlock from './MobileBlock';
import styles from './MobileBlocks.scss';

const MobileBlocks = ({
  addresses,
  addressesPagination,
  onAddressesPageChange,
  onDeleteAddress,
  onChangeAddress,
  changeAddressEditMode,
  isAddressAdding,
  onAddClick,
  isAddressesPending,
  handleCancelAdding,
  phone,
  setPhone,
  isDefault,
  setIsDefault,
  country,
  state,
  postalCode,
  city,
  line1,
  line2,
  setCountry,
  setState,
  setPostalCode,
  setCity,
  setLine1,
  setLine2,
}) => {
  return (
    <div className={cx({ [styles.disabled]: isAddressesPending })}>
      {isAddressAdding && (
        <Card>
          <CardBody>
            <MobileItemBlock
              title="Is Default"
              value={
                <div
                  className={cx(
                    styles.checkbox,
                    'custom-control custom-checkbox'
                  )}
                >
                  <CustomInput
                    type="checkbox"
                    onChange={event => setIsDefault(event.target.checked)}
                    checked={isDefault}
                    id="new-address"
                    name="new-address"
                  />
                </div>
              }
            />
            <MobileItemBlock
              title="Address"
              value={
                <LocationSearchInput
                  model={{}}
                  isRequired
                  country={country}
                  state={state}
                  postalCode={postalCode}
                  city={city}
                  line1={line1}
                  line2={line2}
                  setCountry={setCountry}
                  setState={setState}
                  setPostalCode={setPostalCode}
                  setCity={setCity}
                  setLine1={setLine1}
                  setLine2={setLine2}
                  withoutLabel
                />
              }
            />
            <MobileItemBlock
              title="Phone"
              value={
                <AvForm>
                  <PhoneField
                    isRequired
                    name="phone"
                    placeholder="Phone"
                    errorMessage="Enter Phone"
                    value={phone}
                    onChange={setPhone}
                  />
                </AvForm>
              }
            />
            <MobileItemBlock
              title="Actions"
              value={
                <div className={styles.actions}>
                  <Button
                    className={cx('btn-rounded', styles.addActionButton)}
                    type="button"
                    onClick={onAddClick}
                  >
                    Add
                  </Button>
                  <Button
                    className={cx(styles.addActionButton, 'btn-rounded')}
                    color="danger"
                    type="button"
                    onClick={handleCancelAdding}
                  >
                    Cancel
                  </Button>
                </div>
              }
            />
          </CardBody>
        </Card>
      )}
      {(addresses ?? []).map(item => {
        return (
          <MobileBlock
            item={item}
            onDeleteAddress={onDeleteAddress}
            onChangeAddress={onChangeAddress}
            changeAddressEditMode={changeAddressEditMode}
            isDisabled={addresses?.length === 1}
          />
        );
      })}
      {addressesPagination && addressesPagination.total_pages > 1 && (
        <Pagination
          pagination={addressesPagination}
          onClick={onAddressesPageChange}
        />
      )}
    </div>
  );
};

MobileBlocks.propTypes = {
  addresses: PropTypes.array,
  addressesPagination: PropTypes.object,
  onAddressesPageChange: PropTypes.func,
  onDeleteAddress: PropTypes.func,
  onChangeAddress: PropTypes.func,
  changeAddressEditMode: PropTypes.func,
  handleCancelAdding: PropTypes.func,
  isAddressAdding: PropTypes.bool,
  onAddClick: PropTypes.func,
  isAddressesPending: PropTypes.bool,
  phone: PropTypes.string,
  setPhone: PropTypes.func,
  isDefault: PropTypes.bool,
  setIsDefault: PropTypes.func,
  country: PropTypes.string,
  state: PropTypes.string,
  postalCode: PropTypes.string,
  city: PropTypes.string,
  line1: PropTypes.string,
  line2: PropTypes.string,
  setCountry: PropTypes.func,
  setState: PropTypes.func,
  setPostalCode: PropTypes.func,
  setCity: PropTypes.func,
  setLine1: PropTypes.func,
  setLine2: PropTypes.func,
};

export default MobileBlocks;
