const NOT_EDITABLE_STATUSES = new Set([
  'SKIPPED',
  'SHIPPED',
  'CANCELLED',
  'PAID_BY_CARD',
  'PAYMENT_ERROR',
  'PARTIALLY_RETURNED',
  'RETURNED',
  'PAID',
]);

const NOT_APPROVABLE_STATUSES = new Set([
  'DRAFT',
  'SKIPPED',
  'SHIPPED',
  'CANCELLED',
  'PAID_BY_CARD',
  'PARTIALLY_RETURNED',
  'RETURNED',
  'PAID',
]);

const NOT_VIEWABLE_STATUSES = new Set(['SKIPPED']);

const SKIPPABLE_STATUSES = new Set([
  'DRAFT',
  'FULFILLED',
  'PAYMENT_ERROR',
  'WAITING_FOR_APPROVAL',
]);

export default order => {
  if (order === null || !Object.getOwnPropertyDescriptor(order, 'status'))
    throw new Error('order object has no status field or undefined ');

  const editable = !NOT_EDITABLE_STATUSES.has(order.status);
  const approvable = !NOT_APPROVABLE_STATUSES.has(order.status);
  const skippable = SKIPPABLE_STATUSES.has(order.status);
  const viewable = !NOT_VIEWABLE_STATUSES.has(order.status);

  return { viewable, editable, approvable, skippable };
};
