/* eslint-disable react/no-danger */
import React from 'react';
import useSelector from 'hooks/useSelector';
import * as tiersSelectors from 'models/tiers/selectors';
import s from './Sidebar.scss';
import OnboardingCard from '../../../../components/OnboardingCard';
import { currentUserSelector } from '../../../../models/user/selectors';

const Sidebar = () => {
  const currentUser = useSelector(currentUserSelector);
  const tier = useSelector(tiersSelectors.tierSelector);

  return (
    <div className={s.root}>
      {tier?.id && currentUser?.current_obligation?.status !== 'NEW' ? (
        <>
          <div className={s.title}>
            <span>Current Subscription</span>
          </div>

          <div className={s.items}>
            <h2 className={s.tierTitle}>{tier.title}</h2>
            <div className={s.tierSubtitle}>
              <span className={s.capacity}> {tier.capacity}</span>
              {tier.period_in_months}
            </div>
            <div
              className={s.description}
              dangerouslySetInnerHTML={{ __html: tier?.description || '' }}
            />
          </div>
        </>
      ) : (
        <OnboardingCard
          name={`${currentUser?.first_name ?? ''} ${currentUser?.last_name ??
            ''}`}
          wineclubTitle={currentUser?.wineclub_title}
          brandPhone={currentUser?.brand?.contact_phone}
          brandEmail={currentUser?.brand?.contact_email}
          brandTitle={currentUser?.brand?.title}
          isSubscriptionEmpty={!currentUser?.current_obligation}
          subscriptionTitle={currentUser?.current_obligation?.tier?.title}
          className="mb-4"
        />
      )}
    </div>
  );
};

export default Sidebar;
