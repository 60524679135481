/* eslint-disable dot-notation */
import React from 'react';
import PropTypes from 'prop-types';

import {
  Table,
  Card,
  // Badge,
} from 'reactstrap';

const Main = ({ orderItems }) => {
  return orderItems?.length === 0 ? (
    <h3>The list of order items is empty </h3>
  ) : (
    <Card>
      <div className="table-rep-plugin">
        <div className="table-responsive mb-0" data-pattern="priority-columns">
          <Table id="tech-companies-1" bordered responsive>
            <thead>
              <tr>
                <th data-priority="1">Title</th>
                <th data-priority="1">Amount</th>
                <th data-priority="1">Product Price</th>
                <th data-priority="3">Total Price</th>
                <th data-priority="3">Product</th>
              </tr>
            </thead>
            <tbody>
              {orderItems?.map(item => (
                <tr key={item.id}>
                  <td>{item.title} </td>
                  <td>{item.number || '-'} </td>
                  <td>
                    {item.product_price
                      ? `${item.product_price} ${item.product_price_currency}`
                      : '-'}{' '}
                  </td>
                  <td>
                    {item.total_price}&nbsp;{item.total_price_currency}{' '}
                  </td>
                  <td>
                    {item.product ? (
                      <>
                        <img
                          src={item.product.label_v_300x300_url}
                          alt=""
                          height="60"
                        />
                        &nbsp;
                        {item.product.title}{' '}
                      </>
                    ) : (
                      '-'
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </Card>
  );
};

Main.propTypes = {
  orderItems: PropTypes.array,
};

export default Main;
