import React from 'react';
import styles from './CircleButton.scss';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const CircleButton = ({
  onClick,
  children,
  dark,
  notClickable,
  disabled,
  className,
}) => {
  return (
    <button
      onClick={() => !notClickable && onClick?.()}
      disabled={disabled}
      className={classNames(
        styles.container,
        dark && styles.darkContainer,
        notClickable && styles.notClickableContainer,
        className
      )}
    >
      <span className={styles.content}>{children}</span>
    </button>
  );
};

CircleButton.propTypes = {
  children: PropTypes.any,
  disabled: PropTypes.bool,
  dark: PropTypes.bool,
  notClickable: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
};
export default CircleButton;
