export const selectStyles = {
  menu: baseStyles => ({
    ...baseStyles,
    zIndex: 30,
    backgroundColor: '#f1f5f7',
    border: '1px solid rgba(0, 0, 0, 0.5)',
    borderRadius: '7px',
  }),
  control: baseStyles => ({
    ...baseStyles,
    backgroundColor: '#f1f5f7',
    border: '1px solid rgba(0, 0, 0, 0.5)',
    borderRadius: '7px',
  }),
};
