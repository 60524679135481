import React from 'react';
import PropTypes from 'prop-types';
import styles from './Section.scss';
import OrderItem from '../../../components/OrderItem';
import { Button } from 'reactstrap';

const Section = ({ title, items, onClick }) => {
  return (
    <div className={styles.block}>
      <Button className={styles.button} onClick={onClick} color="info">
        Select this option
      </Button>
      <h5 className={styles.title}>{title}</h5>
      <ul className={styles.items}>
        {items.map(item => (
          <li className={styles.item} key={item.title}>
            <OrderItem title={item.product_title} number={item.quantity} />
          </li>
        ))}
      </ul>
    </div>
  );
};

Section.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
  onClick: PropTypes.func,
};

export default Section;
