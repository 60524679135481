import React from 'react';
import styles from './OrdersTable.scss';
import PaginationTable from '../PaginationTable';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { Table } from 'reactstrap';
import PropTypes from 'prop-types';
import formatPrice from '../../utils/formatPrice';
import getOrderActionsPermissions from '../../utils/getOrderActionsPermissions';
import { HUMAN_ORDER_STATUSES, ORDER_TYPES } from 'constants';

import subscriptionBottleImg from 'images/wines/subscription.svg';
import tastingBottleImg from 'images/wines/tastingRoom.svg';
import retailBottleImg from 'images/wines/retail.svg';
import ecommerceBottleImg from 'images/wines/ecommerceOrder.svg';
import classNames from 'classnames';

const OrdersTable = ({ orders, onSkipOrder, onPageChange, pagination }) => {
  const images = {
    SubscriptionOrder: subscriptionBottleImg,
    TastingRoomOrder: tastingBottleImg,
    RetailOrder: retailBottleImg,
    EcommerceOrder: ecommerceBottleImg,
  };
  return (
    <PaginationTable setCurrentPage={onPageChange} pagination={pagination}>
      <Table className="table-centered table-grey">
        <thead>
          <tr>
            <th />
            <th>ID</th>
            <th>Type</th>
            <th>Status</th>
            <th>Created at</th>
            <th>Price</th>
            <th>Options</th>
          </tr>
        </thead>
        <tbody>
          {orders.map(order => {
            const {
              viewable,
              editable,
              skippable,
              approvable,
            } = getOrderActionsPermissions(order);
            const orderImage = images[order.resourcetype];

            return (
              <tr key={order.id}>
                <td>
                  <img src={orderImage} alt="order" height="50" />
                </td>
                <td>{order.id}</td>
                <td>{ORDER_TYPES[order.resourcetype]}</td>
                <td>
                  {order.status === 'DRAFT' || order.status === 'FULFILLED'
                    ? 'Processed by Manager'
                    : HUMAN_ORDER_STATUSES[order.status]}
                </td>
                <td>{format(Date.parse(order.created_at), 'yyyy-MM-dd')}</td>
                <td>${formatPrice(order.total_price)}</td>
                <td>
                  <div>
                    {(order.resourcetype !== 'SubscriptionOrder' ||
                      viewable) && (
                      <Link to={`/order/${order.id}`}>Details</Link>
                    )}
                    {order.resourcetype === 'RetailOrder' &&
                      !order.is_created_by_manager &&
                      editable && (
                        <Link
                          to={`/wine-selection/${order.id}/edit`}
                          className="ml-2"
                        >
                          Edit
                        </Link>
                      )}
                    {order?.resourcetype === 'SubscriptionOrder' && (
                      <>
                        {editable && (
                          <Link to="/wine-selection" className="ml-2">
                            Edit
                          </Link>
                        )}
                        {skippable && (
                          <button
                            className={classNames(styles.button, 'ml-2 p-0')}
                            onClick={() => onSkipOrder(order.id)}
                          >
                            Skip
                          </button>
                        )}
                        {approvable && (
                          <Link to="/wine-selection/approve" className="ml-2">
                            Approve
                          </Link>
                        )}
                      </>
                    )}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </PaginationTable>
  );
};

OrdersTable.propTypes = {
  orders: PropTypes.array.isRequired,
  pagination: PropTypes.object,
  onPageChange: PropTypes.func,
  onSkipOrder: PropTypes.func,
};

export default OrdersTable;
