import { takeLatest, all } from 'redux-saga/effects';
import api from 'api';
import { actions } from './slice';

export function* fetchProductsSaga(action) {
  const { page } = action.payload;
  yield api({
    action,
    method: 'get',
    url: `/products?page=${page}`,
  });
}

export function* fetchProductsWithWarehouseSaga(action) {
  const { page } = action.payload;
  yield api({
    action,
    method: 'GET',
    url: `/products?page=${page}`,
  });
}

export default function*() {
  yield all([takeLatest(actions.fetchProducts, fetchProductsSaga)]);
  yield all([
    takeLatest(
      actions.fetchProductsWithWarehouse,
      fetchProductsWithWarehouseSaga
    ),
  ]);
}
