import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

import Title from 'components/Title';

import useSelector from 'hooks/useSelector';

import styles from './CardsShow.scss';
import useAction from '../../hooks/useAction';
import { actions as paymentsActions } from 'models/payments/slice';
import { paymentMethodsSelector } from '../../models/payments/selectors';
import PaymentCard from '../../components/PaymentCard';
import classNames from 'classnames';

const AddressShow = () => {
  const fetchPaymentMethods = useAction(paymentsActions.fetchPaymentMethods);
  const activatePaymentMethod = useAction(
    paymentsActions.activatePaymentMethod
  );
  const paymentMethods = useSelector(paymentMethodsSelector);
  const [selectedCard, setSelectedCard] = useState(null);

  useEffect(() => {
    fetchPaymentMethods();
  }, [fetchPaymentMethods]);

  useEffect(() => {
    setSelectedCard(null);
  }, [paymentMethods]);

  const onCardSelect = id => {
    setSelectedCard(id === selectedCard ? null : id);
  };

  const onActivateClick = () => {
    activatePaymentMethod({ id: selectedCard });
  };

  return (
    <div>
      <Title
        className="mb-5 mt-4"
        title="Manage your credit cards"
        subtitle="Choose a card to be used for Therapy Wine Subscription charges and purchases"
      />
      <div className={styles.wrapper}>
        {paymentMethods.map(method => (
          <PaymentCard
            className={styles.card}
            key={method.id}
            brand={method.card_brand}
            lastDigits={method.card_last_digits}
            name={method.holder_name}
            month={method.card_exp_month.toString().padStart(2, '0')}
            year={method.card_exp_year.toString()}
            isActive={method.is_active}
            isSelected={selectedCard === method.id}
            onClick={() => onCardSelect(method.id)}
          />
        ))}
      </div>
      <Row className="mt-5">
        <Col xs="auto" className="mb-2">
          <Link to="card/create" className="text-dark font-weight-bold">
            <Button className="btn-rounded">Add New Card</Button>
          </Link>
        </Col>
        {selectedCard != null && (
          <Col xs="auto">
            <Button
              onClick={onActivateClick}
              color="success"
              className={classNames(styles.changeBtn, 'btn-rounded')}
            >
              Change Default Card
            </Button>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default AddressShow;
