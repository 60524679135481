import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from './AddressBook.scss';
import { Row, Col, Button } from 'reactstrap';
import { useMediaQuery } from 'react-responsive';

import DesktopTable from './DesktopTable';
import MobileBlocks from './MobileBlocks';

const AddressBook = ({
  addresses,
  defaultPhone,
  addressesPagination,
  onAddressesPageChange,
  onDeleteAddress,
  onChangeAddress,
  changeAddressEditMode,
  setIsAddressAdding,
  isAddressAdding,
  onAddAddress,
  isAddressesPending,
}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [city, setCity] = useState('');
  const [line1, setLine1] = useState('');
  const [line2, setLine2] = useState('');
  const [phone, setPhone] = useState(defaultPhone || '');
  const [isDefault, setIsDefault] = useState(true);

  useEffect(() => {
    setPhone(defaultPhone);
  }, [defaultPhone]);

  const resetAddForm = () => {
    setCountry('');
    setState('');
    setPostalCode('');
    setCity('');
    setLine1('');
    setLine2('');
    setPhone(defaultPhone);
    setIsDefault(true);
  };

  const handleOpenAdding = () => {
    resetAddForm();
    setIsAddressAdding(true);
  };

  const handleCancelAdding = () => {
    setIsAddressAdding(false);
    resetAddForm();
  };

  const onAddClick = () => {
    onAddAddress({
      country,
      state,
      postal_code: postalCode,
      city,
      line1,
      line2,
      phone,
      is_default: isDefault,
    });
  };

  const props = {
    addresses,
    addressesPagination,
    onAddressesPageChange,
    onDeleteAddress,
    onChangeAddress,
    changeAddressEditMode,
    isAddressAdding,
    onAddClick,
    isAddressesPending,
    handleCancelAdding,
    phone,
    setPhone,
    isDefault,
    setIsDefault,
    country,
    state,
    postalCode,
    city,
    line1,
    line2,
    setCountry,
    setState,
    setPostalCode,
    setCity,
    setLine1,
    setLine2,
  };

  const renderTable = useMemo(() => {
    return <DesktopTable {...props} />;
  }, [props]);

  const renderBlocks = useMemo(() => <MobileBlocks {...props} />, [props]);

  const renderedItems = useMemo(() => (isMobile ? renderBlocks : renderTable), [
    isMobile,
    renderBlocks,
    renderTable,
  ]);

  return (
    <div className="mb-4">
      <Row>
        <Col className={styles.titleContainer}>
          <h2 className={styles.title}>Address Book</h2>
          <Button
            type="button"
            className="btn-rounded"
            onClick={handleOpenAdding}
            disabled={isAddressAdding}
          >
            Add new address
          </Button>
        </Col>
      </Row>
      {renderedItems}
    </div>
  );
};

AddressBook.propTypes = {
  addresses: PropTypes.array,
  defaultPhone: PropTypes.string,
  addressesPagination: PropTypes.object,
  onAddressesPageChange: PropTypes.func,
  onDeleteAddress: PropTypes.func,
  onChangeAddress: PropTypes.func,
  changeAddressEditMode: PropTypes.func,
  setIsAddressAdding: PropTypes.func,
  isAddressAdding: PropTypes.bool,
  onAddAddress: PropTypes.func,
  isAddressesPending: PropTypes.bool,
};

export default AddressBook;
