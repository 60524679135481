import { createSelector } from 'reselect';

export const rootSelector = state => state.wineclubs;

export const wineclubsSelector = createSelector(
  rootSelector,
  ({ wineclubs }) => wineclubs
);

export const currentWineclubSelector = createSelector(
  rootSelector,
  ({ currentWineclub }) => currentWineclub
);

export const currentWineclubPendingSelector = createSelector(
  rootSelector,
  ({ currentWineclubPending }) => currentWineclubPending
);

export const wineclubSelector = createSelector(
  rootSelector,
  ({ wineclub }) => wineclub
);

export const isPendingSelector = createSelector(
  rootSelector,
  ({ isPending }) => isPending
);

export const paymentInfoSelector = createSelector(
  rootSelector,
  ({ paymentInfo }) => paymentInfo
);
