import React from 'react';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  // Input,
  Button,
  Container,
  Label,
  FormGroup,
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';

import Icon from 'components/Icon';
import useAction from 'hooks/useAction';
import s from '../authentication.scss';
import { actions as userActions } from 'models/user/slice';

const checkPasswordsSame = (value, ctx) => {
  if (value !== ctx.user_password) {
    return 'Passwords must match';
  }
  return true;
};

const validatePassword = value => {
  const regex = /.*?(?:[a-z].*?[0-9]|[0-9].*?[a-z]).*?/;
  if (value.length < 8 || !regex.test(value)) {
    return 'Password must be at least 8 characters and includes numbers and letters';
  }
  return true;
};

const Register = () => {
  const register = useAction(userActions.register);

  const handleSubmit = (event, values) => {
    register({
      email: values.user_email,
      password: values.user_password,
      password_confirmation: values.user_password_confirm,
    });
  };
  return (
    <>
      <div>
        <Container fluid className="p-0">
          <Row className="no-gutters">
            <Col lg={4}>
              <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                <div className="w-100">
                  <Row className="justify-content-center">
                    <Col lg={9}>
                      <div>
                        <div className="text-center">
                          <div>
                            <Link to="/" className="logo">
                              <Icon color="black" name="logo" width="100px" />
                            </Link>
                          </div>

                          <h4 className="font-size-18 mt-4">Register</h4>
                        </div>

                        <div className="p-2 mt-5">
                          <AvForm
                            className="form-horizontal"
                            onValidSubmit={handleSubmit}
                          >
                            <FormGroup className="auth-form-group-custom mb-4">
                              <i className="ri-mail-line auto-custom-input-icon" />
                              <Label htmlFor="useremail">Email</Label>
                              <AvField
                                name="user_email"
                                type="email"
                                validate={{ email: true, required: true }}
                                className="form-control"
                                id="user_email"
                                placeholder="Enter email"
                              />
                            </FormGroup>

                            <FormGroup className="auth-form-group-custom mb-4">
                              <i className="ri-lock-line auto-custom-input-icon" />
                              <Label htmlFor="user_password">Password</Label>
                              <AvField
                                name="user_password"
                                type="password"
                                validate={{
                                  customValidation: validatePassword,
                                  required: true,
                                }}
                                className="form-control"
                                id="user_password"
                                placeholder="Enter password"
                              />
                            </FormGroup>

                            <FormGroup className="auth-form-group-custom mb-4">
                              <i className="ri-lock-line auto-custom-input-icon" />
                              <Label htmlFor="user_password_confirm">
                                Password Confirmation
                              </Label>
                              <AvField
                                name="user_password_confirm"
                                type="password"
                                validate={{
                                  customValidation: checkPasswordsSame,
                                  required: true,
                                }}
                                className="form-control"
                                id="user_password_confirm"
                                placeholder="Enter password confirmation"
                              />
                            </FormGroup>

                            <div className="mt-4 text-center">
                              <Button
                                color="primary"
                                className="w-md waves-effect waves-light"
                                type="submit"
                              >
                                Register
                              </Button>
                            </div>
                          </AvForm>
                        </div>

                        <div className="mt-5 text-center">
                          <p>
                            Have an account?
                            <Link
                              to="/login"
                              className="font-weight-medium text-primary"
                            >
                              Log in
                            </Link>
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={8}>
              <div className={s.authenticationBg}>
                <div className={s.bgOverlay} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Register;
