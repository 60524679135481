import React from 'react';
import PropTypes from 'prop-types';
import styles from './MobileItemBlock.scss';

const MobileItemBlock = ({ title, value }) => {
  return (
    <div className={styles.block}>
      <h4 className={styles.title}>{title}</h4>
      <p className={styles.value}>{value || '-'}</p>
    </div>
  );
};

MobileItemBlock.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default MobileItemBlock;
