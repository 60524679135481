import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import useAction from 'hooks/useAction';
import { actions as commonActions } from 'models/common/slice';
import useSelector from 'hooks/useSelector';
import { isOpenConfirmationModalSelector } from 'models/common/selectors';

const ConfirmationModal = ({
  title,
  onSaveClick,
  children,
  submitButtonColor,
  submitButtonTitle,
  handleCloseClick,
}) => {
  const setIsOpenConfirmationModal = useAction(
    commonActions.setIsOpenConfirmationModal
  );
  const isOpenConfirmationModal = useSelector(isOpenConfirmationModalSelector);

  const toggleModal = useCallback(() => {
    setIsOpenConfirmationModal(!isOpenConfirmationModal);
    if (handleCloseClick) {
      handleCloseClick();
    }
  }, [setIsOpenConfirmationModal, isOpenConfirmationModal, handleCloseClick]);

  const handleSaveClick = useCallback(() => {
    onSaveClick();
    toggleModal();
  }, [onSaveClick, toggleModal]);

  return (
    <Modal isOpen={isOpenConfirmationModal} toggle={setIsOpenConfirmationModal}>
      <ModalHeader toggle={toggleModal}>{title}</ModalHeader>
      <ModalBody>
        {children || <h5>This action can&apos;t be undone</h5>}
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          onClick={toggleModal}
          color="light"
          className="waves-effect"
        >
          Close
        </Button>
        <Button
          className="waves-effect waves-light"
          type="button"
          color={submitButtonColor}
          onClick={handleSaveClick}
        >
          {submitButtonTitle}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  onSaveClick: PropTypes.func,
  children: PropTypes.any,
  title: PropTypes.string,
  submitButtonTitle: PropTypes.string,
  submitButtonColor: PropTypes.string,
  handleCloseClick: PropTypes.func,
};

ConfirmationModal.defaultProps = {
  title: 'Are you sure you want to delete this item?',
  submitButtonTitle: 'Delete',
  submitButtonColor: 'danger',
};

export default ConfirmationModal;
