import React from 'react';
import PropTypes from 'prop-types';
import AuthBlock from '../AuthBlock';
import { Button } from 'reactstrap';
import styles from './ResetPassword.scss';
import { AvForm } from 'availity-reactstrap-validation';
import PasswordField from '../../../components/Fields/PasswordField';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { actions } from '../../../models/user/slice';
import useAction from '../../../hooks/useAction';

const ResetPassword = ({ title }) => {
  const { token } = useParams();
  const resetPassword = useAction(actions.resetPassword);

  const onSubmit = (event, values) => {
    resetPassword({
      reset_password_token: token,
      ...values,
    });
  };

  return (
    <AuthBlock title={title} subtitle="Reset password">
      <AvForm className={styles.container} onValidSubmit={onSubmit}>
        <PasswordField
          className={styles.field}
          name="password"
          placeholder="Password"
        />
        <PasswordField
          className={styles.field}
          name="password_confirmation"
          label="Confirm Password"
          placeholder="Confirm Password"
          rules={{
            match: { value: 'password', errorMessage: "Passwords don't match" },
          }}
        />

        <Button
          className={classNames(
            styles.button,
            'btn-dark w-md waves-effect waves-light'
          )}
          type="submit"
        >
          Reset password
        </Button>
      </AvForm>
    </AuthBlock>
  );
};

ResetPassword.propTypes = {
  title: PropTypes.string,
};

export default ResetPassword;
