import { createSlice } from 'redux-starter-kit';
import actionTypes from 'utils/actionTypes';

const paymentsSlice = createSlice({
  name: 'payments',
  initialState: {
    isPending: false,
    paymentMethods: [],
    payment: {},
    intent: {},
  },
  reducers: {
    setupIntent: state => {
      state.isPending = true;
    },
    setupIntentSuccess(state, { payload }) {
      state.intent = payload.data;
      state.isPending = false;
    },
    setupIntentFailure: state => {
      state.isPending = false;
    },
    sendPaymentMethod: state => {
      state.isPending = true;
    },
    sendPaymentMethodSuccess(state) {
      state.isPending = false;
    },
    sendPaymentMethodFailure: state => {
      state.isPending = false;
    },
    fetchPaymentMethods: state => {
      state.isPending = true;
    },
    fetchPaymentMethodsSuccess: (state, { payload }) => {
      const { results } = payload.data;
      state.paymentMethods = results;
      state.isPending = false;
    },
    fetchPaymentMethodsFailure: state => {
      state.isPending = false;
    },
    activatePaymentMethod: state => {
      state.isPending = true;
    },
    activatePaymentMethodSuccess: (state, { payload }) => {
      const { id } = payload.data;
      state.paymentMethods = state.paymentMethods.map(method => ({
        ...method,
        is_active: method.id === id,
      }));
      state.isPending = false;
    },
    activatePaymentMethodFailure: state => {
      state.isPending = false;
    },
    removePaymentMethod: state => {
      state.isPending = true;
    },
    removePaymentMethodSuccess: state => {
      state.isPending = false;
    },
    removePaymentMethodFailure: state => {
      state.isPending = false;
    },
  },
});

export const actions = actionTypes(paymentsSlice.actions);

export default paymentsSlice.reducer;
