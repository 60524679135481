import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import AuthBlock from '../AuthBlock';
import { AvForm } from 'availity-reactstrap-validation';
import { Button, Spinner } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import styles from './ForgotPassword.scss';
import EmailField from '../../../components/Fields/EmailField';
import classNames from 'classnames';
import useQuery from '../../../hooks/useQuery';
import useAction from '../../../hooks/useAction';
import { actions } from '../../../models/user/slice';
import PopupBase from '../../../components/Popup/PopupBase';
import useSelector from '../../../hooks/useSelector';
import {
  isPendingSelector,
  recoverPasswordModalSelector,
} from '../../../models/user/selectors';

const ForgotPassword = ({ title }) => {
  const query = useQuery();
  const recoverPassword = useAction(actions.recoverPassword);
  const setRecoverPasswordModal = useAction(actions.setRecoverPasswordModal);
  const loading = useSelector(isPendingSelector);
  const recoverPasswordModal = useSelector(recoverPasswordModalSelector);

  useEffect(() => {
    setRecoverPasswordModal(false);
  }, [setRecoverPasswordModal]);

  const onSubmit = (event, values) => {
    recoverPassword(values);
  };

  return (
    <AuthBlock title={title}>
      {recoverPasswordModal && (
        <PopupBase>
          <div className={styles.popup}>
            {loading ? (
              <Spinner className={styles.spinner} variant="primary" />
            ) : (
              <>
                <h3 className={styles.text}>
                  Password recovery link is sent to your email
                </h3>
                <NavLink
                  to="/login"
                  className="font-weight-medium text-primary"
                >
                  Back to Login page
                </NavLink>
              </>
            )}
          </div>
        </PopupBase>
      )}
      <AvForm
        className={styles.container}
        onValidSubmit={onSubmit}
        model={{
          email: query.get('email'),
        }}
      >
        <EmailField name="email" placeholder="Email" />
        <Button
          className={classNames(
            styles.button,
            'btn-dark w-md waves-effect waves-light'
          )}
          type="submit"
        >
          Reset Password
        </Button>
        <p>
          Have an account?{' '}
          <NavLink to="/login" className="font-weight-medium text-primary">
            Login
          </NavLink>
        </p>
      </AvForm>
    </AuthBlock>
  );
};

ForgotPassword.propTypes = {
  title: PropTypes.string,
};

export default ForgotPassword;
