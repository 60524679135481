import React from 'react';

export default props => (
  <svg width="73px" height="72px" viewBox="0 0 73 72" version="1.1" {...props}>
    <title>Group 7 Copy</title>
    <defs>
      <polygon id="path-1" points="0 0 1920 0 1920 1080 0 1080" />
    </defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Master-App-404" transform="translate(-1102.000000, -420.000000)">
        <g id="Group-2" transform="translate(566.000000, 382.102942)">
          <g id="Group-7-Copy" transform="translate(537.380860, 39.089118)">
            <ellipse
              id="Oval-Copy-2"
              stroke="#FFFFFF"
              strokeWidth="2.11036158"
              cx="34.7889908"
              cy="34.8373859"
              rx="34.7889908"
              ry="34.8373859"
            />
            <path
              d="M54.8190158,54.8878221 C54.8190158,43.7999743 45.8512682,34.8114977 34.7889908,34.8114977 C23.7267135,34.8114977 14.7589658,43.7999743 14.7589658,54.8878221"
              id="Oval-Copy-2"
              stroke="#FFFFFF"
              strokeWidth="2.11036158"
              transform="translate(34.788991, 44.849660) rotate(-360.000000) translate(-34.788991, -44.849660) "
            />
            <ellipse
              id="Oval"
              fill="#FFFFFF"
              cx="46.3853211"
              cy="23.1883625"
              rx="4.21684737"
              ry="4.22659461"
            />
            <ellipse
              id="Oval-Copy-3"
              fill="#FFFFFF"
              cx="23.1926606"
              cy="23.1883625"
              rx="4.21684737"
              ry="4.22659461"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
